import { combineReducers } from "redux";
import authReducer from "./authReducer";
import utilityReducer from "./utilityReducer";
import searchReducer from "./searchReducer";
import playerReducer from "./playerReducer";
import investigationReducer from "./investigationReducer";
import investigationsReducer from "./investigationsReducer";
import exportsReducer from "./exportsReducer";
import thumbnailReducer from "./thumbnailReducer";
import viewsReducer from "./viewsReducer";
import viewReducer from "./viewReducer";
import cameraSearchReducer from "./cameraSearchReducer";
import customViewReducer from "./customViewReducer";
import customViewsReducer from "./customViewsReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  utility: utilityReducer,
  search: searchReducer,
  player: playerReducer,
  investigations: investigationsReducer,
  investigation: investigationReducer,
  thumbnails: thumbnailReducer,
  cameraSearch: cameraSearchReducer,
  views: viewsReducer,
  view: viewReducer,
  exports: exportsReducer,
  customView: customViewReducer,
  customViews: customViewsReducer
});

export default rootReducer;
