import {
  TOGGLE_MODAL,
  SET_OPEN_PANEL,
  TOGGLE_WEB_SOCKET,
  TOGGLE_DIRECT_STREAMING,
  GET_PROMOTIONS,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_ERROR,
  GET_PROMOTIONS_ITEM,
  GET_PROMOTIONS_ITEM_SUCCESS,
  GET_PROMOTIONS_ITEM_ERROR
} from "../constants/actionTypes";
import { replace } from "connected-react-router";

export const toggleModal = (modal = "") => ({
  type: TOGGLE_MODAL,
  modal
});

export const setOpenPanel = (openPanel = "") => ({
  type: SET_OPEN_PANEL,
  openPanel
});

export const toggleWebSocket = () => {
  return (dispatch, getState, XPMobileSDK) => {
    // look up current state from connection object
    // instead of  polling local storage
    const enabled = !XPMobileSDK.library.Connection.webSocketBrowser;
    XPMobileSDK.library.Connection.toggleWebSocket(enabled);
    return dispatch({ type: TOGGLE_WEB_SOCKET, enabled });
  };
};

export const toggleDirectStreaming = () => {
  return (dispatch, getState, XPMobileSDK) => {
    const enabled = !XPMobileSDK.library.Connection.directStreamingClient;
    XPMobileSDK.library.Connection.toggleDirectStreaming(enabled);
    return dispatch({ type: TOGGLE_DIRECT_STREAMING, enabled });
  };
};

export const replaceLocation = url => replace(url);

export const getPromotions = () => {
  return (dispatch, getState) => {
    const { auth, utility } = getState();
    if (auth.loginError && utility.content.promotions) {
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.open("GET", "/promotions/content.json", true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          dispatch(getPromotionsSuccess(JSON.parse(xhr.response)));
        } else {
          dispatch(getPromotionsError(xhr.response));
        }
      }
    };
    xhr.onerror = e => {
      dispatch(getPromotionsError(e));
    };
    xhr.send();
    return dispatch({ type: GET_PROMOTIONS });
  };
};

export const getPromotionsSuccess = content => ({
  type: GET_PROMOTIONS_SUCCESS,
  ...content
});

export const getPromotionsError = error => ({
  type: GET_PROMOTIONS_ERROR,
  error
});

export const getPromotionsItem = filename => {
  return dispatch => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", filename, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          dispatch(getPromotionsItemSuccess(filename, xhr.response));
        } else {
          dispatch(getPromotionsItemError(filename, xhr.response));
        }
      }
    };
    xhr.onerror = e => {
      dispatch(getPromotionsItemError(filename, e));
    };
    xhr.send();
    return dispatch({ type: GET_PROMOTIONS_ITEM, item: filename });
  };
};

export const getPromotionsItemSuccess = (filename, content) => ({
  type: GET_PROMOTIONS_ITEM_SUCCESS,
  item: filename,
  content
});

export const getPromotionsItemError = (filename, error) => ({
  type: GET_PROMOTIONS_ITEM_ERROR,
  item: filename,
  error
});
