import {
  GET_THUMBNAILS,
  FETCH_THUMBNAIL_RESPONSE,
  SET_THUMBNAIL_PENDING,
  SET_THUMBNAIL_QUEUED
} from "../constants/actionTypes";
import Queue from "../utils/thumbnailFetchQueue";

export const getThumbnails = (ids = []) => ({
  type: GET_THUMBNAILS,
  ids: Array.isArray(ids) ? ids : [ids]
});

export const fetchThumbnail = (id, lazy) => {
  return (dispatch, getState, XPMobileSDK) => {
    if (!Queue.initialized) {
      Queue.initialize(dispatch, getState, XPMobileSDK);
    }
    Queue.enqueue(id, dispatch, getState, lazy);
  };
};

export const cancelFetchThumbnail = (id, lazy) => {
  return (dispatch, getState, XPMobileSDK) => {
    if (!Queue.initialized) {
      Queue.initialize(dispatch, getState, XPMobileSDK);
    }
    Queue.cancel(id, lazy);
  };
};

export const setThumbnailPending = (id, pending) => ({
  type: SET_THUMBNAIL_PENDING,
  thumbnail: {
    id,
    pending
  }
});

export const setThumbnailQueued = (id, queued) => ({
  type: SET_THUMBNAIL_QUEUED,
  thumbnail: {
    id,
    queued
  }
});

export const fetchThumbnailResponse = (
  id,
  image = null,
  error = null,
  attempts = 0
) => {
  return {
    type: FETCH_THUMBNAIL_RESPONSE,
    id,
    thumbnail: {
      id,
      image,
      error,
      attempts,
      timestamp: Date.now()
    }
  };
};
