import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { Button, Progress, Tooltip, List, Icon, Popconfirm } from "antd";
import prettyBytes from "pretty-bytes";
import * as actions from "../../../actions/exportActions";
import {
  EXPORT_TYPE_AVI,
  EXPORT_TYPE_DB,
  EXPORT_TYPE_MKV,
  EXPORT_STATUS_READY,
  EXPORT_STATUS_PROCESSING,
  EXPORT_STATUS_LOADING,
  EXPORT_STATUS_QUEUED,
  EXPORT_STATUS_DELETING,
  EXPORT_STATUS_REQUESTING
} from "../constants";
import messages from "./messages";
import CameraThumbnail from "../../Cameras/CameraThumbnail";
import { EXPORT_TYPES } from "../../../utils/schemas";
import XPMobileSDK from "../../../utils/api";
import Spinner from "../../Common/Spinner";

const EXPORT_PROGRESS_REFRESH_INTERVAL = 2000; //ms

class InvestigationExport extends React.Component {
  timer = null;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { camera, startTime, endTime, investigationId } = this.props;
    XPMobileSDK.getSequencesInInterval(
      camera.id,
      startTime,
      endTime,
      investigationId,
      res => {
        this.setState({
          hasSequences: res && Array.isArray(res) && res.length > 0
        });
      }
    );
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  maybeRefreshExports = () => {
    if (!this.timer) {
      const { exports = {}, actions, investigationId } = this.props;
      const processing = Object.values(exports).filter(
        e =>
          e &&
          !e.error &&
          !e.queueing &&
          !e.deleting &&
          (e.size === 0 || (e.state > 0 && e.state < 100))
      );
      if (processing.length > 0) {
        this.timer = setTimeout(() => {
          processing.forEach(e =>
            actions.fetchExport(e.exportId, e.cameraId, e.type, investigationId)
          );
          clearTimeout(this.timer);
          this.timer = null;
        }, EXPORT_PROGRESS_REFRESH_INTERVAL);
      }
    }
  };

  renderExportTypeLabel = type => {
    switch (type) {
      case EXPORT_TYPE_AVI:
        return <FormattedMessage {...messages.aviPackage} />;

      case EXPORT_TYPE_DB:
        return <FormattedMessage {...messages.dbPackage} />;

      case EXPORT_TYPE_MKV:
        return <FormattedMessage {...messages.mkvPackage} />;

      default:
        return "";
    }
  };

  renderPopoverMenu = (id, title, handleClick) => {
    return (
      <Popconfirm
        title={title}
        onConfirm={() => handleClick(id)}
        okText={<FormattedMessage {...messages.btnConfirmYes} />}
        cancelText={<FormattedMessage {...messages.btnConfirmNo} />}
        trigger="click"
        overlayClassName="export-confirm-delete"
      >
        <Button type="light" size="small" icon="close" className="btn-cancel" />
      </Popconfirm>
    );
  };

  getError = type => {
    const { exports = {} } = this.props;
    const data = exports[type];
    return !data ? false : data.error;
  };

  getStatus = type => {
    const { exports = {} } = this.props;
    const data = exports[type];
    return !data
      ? ""
      : data.deleting
      ? EXPORT_STATUS_DELETING
      : data.queueing
      ? EXPORT_STATUS_REQUESTING
      : data.type === "aggregated" && data.size > 0
      ? EXPORT_STATUS_READY
      : data.state === 0
      ? EXPORT_STATUS_QUEUED
      : data.state > 0 && data.state <= 100
      ? EXPORT_STATUS_PROCESSING
      : data.state < 0 || data.state > 100
      ? EXPORT_STATUS_READY
      : data.fetching
      ? EXPORT_STATUS_LOADING
      : "";
  };

  renderActions = type => {
    const { camera, startTime, endTime, investigationId } = this.props;
    const { startExport, cancelExport, deleteExport } = this.props.actions;

    const { exports = {} } = this.props;
    const data = exports[type];
    const status = this.getStatus(type);

    switch (status) {
      case EXPORT_STATUS_READY: {
        return (
          <Button.Group>
            {this.renderPopoverMenu(
              data.exportId,
              <FormattedMessage {...messages.deleteConfirm} />,
              () => deleteExport(data)
            )}
            <a
              className="ant-btn ant-btn-light ant-btn-sm"
              href={`${process.env.API_URL}/${data.link}`}
              download
              target="_blank"
              rel="noreferrer noopener"
            >
              <FormattedMessage {...messages.btnStatusReady} />
            </a>
          </Button.Group>
        );
      }

      case EXPORT_STATUS_PROCESSING:
        return (
          <Button.Group>
            {this.renderPopoverMenu(
              data.exportId,
              <FormattedMessage {...messages.cancelConfirm} />,
              () => cancelExport(data)
            )}
            <Progress
              percent={data.state}
              status="active"
              strokeWidth={24}
              strokeLinecap="square"
              format={() => (
                <FormattedMessage {...messages.btnStatusProcessing} />
              )}
            />
          </Button.Group>
        );

      case EXPORT_STATUS_QUEUED:
      case EXPORT_STATUS_REQUESTING:
        return (
          <Button.Group>
            {this.renderPopoverMenu(
              data.exportId,
              <FormattedMessage {...messages.cancelConfirm} />,
              () => cancelExport(data)
            )}
            <Button type="light" size="small">
              <FormattedMessage {...messages[`btnStatus${status}`]} />
            </Button>
          </Button.Group>
        );

      case EXPORT_STATUS_LOADING:
      case EXPORT_STATUS_DELETING:
        return (
          <Button type="light" size="small">
            <FormattedMessage {...messages[`btnStatus${status}`]} />
          </Button>
        );

      default:
        return (
          <Button
            type="light"
            size="small"
            onClick={() =>
              startExport(camera.id, startTime, endTime, type, investigationId)
            }
          >
            <FormattedMessage {...messages.btnStatusNone} />
          </Button>
        );
    }
  };

  renderError = type => {
    const error = this.getError(type);
    if (!error) {
      return "";
    }

    return (
      <div className="export-error">
        <Tooltip
          placement="top"
          title={
            <>
              <FormattedMessage {...messages.exportFailed} arrowPointAtCenter />
              <FormattedMessage
                {...messages.exportFailedError}
                values={{ code: error.code }}
                arrowPointAtCenter
              />
            </>
          }
        >
          <Icon type="exclamation-circle" theme="outlined" />
        </Tooltip>
      </div>
    );
  };

  render() {
    const { camera, exports = {} } = this.props;
    const { hasSequences } = this.state;
    const { Item } = List;
    const { Meta } = Item;
    if (!camera) {
      return null;
    }

    this.maybeRefreshExports();

    return (
      <div className="camera-frame">
        <CameraThumbnail id={camera.id} camera={camera}>
          {hasSequences === true ? (
            <List
              className="investigation-export-list"
              dataSource={Object.keys(EXPORT_TYPES).filter(
                t =>
                  (camera.capabilities &&
                    camera.capabilities[EXPORT_TYPES[t]]) ||
                  t === "mkv"
              )}
              renderItem={type => {
                const data = exports[type];
                const { exportId = null, size = 0 } = data || {};
                const className = `investigation-export investigation-export--type-${type} investigation-export--status-${status.toLowerCase()}`;
                return (
                  <Item
                    key={exportId || `${camera.id}-${type}`}
                    className={className}
                    data-playing={false}
                    actions={[this.renderActions(type)]}
                  >
                    {this.renderError(type)}
                    <Meta
                      title={this.renderExportTypeLabel(type)}
                      description={size ? prettyBytes(size) : "- Bytes"}
                    />
                  </Item>
                );
              }}
            />
          ) : hasSequences === false ? (
            <div className="investigation-export__no-content">
              This camera has no content during the specified time interval.
            </div>
          ) : (
            <div className="investigation-export__no-content">
              <Spinner />
            </div>
          )}
        </CameraThumbnail>
      </div>
    );
  }
}

InvestigationExport.propTypes = {
  actions: PropTypes.object,
  investigationId: PropTypes.string,
  camera: PropTypes.object,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  exports: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(
  () => ({}),
  mapDispatchToProps
)(InvestigationExport);
