import React, { Component } from "react";
import PropTypes from "prop-types";
import { Layout, Collapse } from "antd";
import { FormattedMessage } from "react-intl";
import ViewList from "../ViewList";
import CameraSearch from "../../Cameras/CameraSearch";
import messages from "../messages";

class ViewSidebar extends Component {
  handleCollapse = (key = "") => {
    this.props.setOpenPanel(key);
  };

  render() {
    const { Sider } = Layout;
    const { Panel } = Collapse;
    const {
      loading = true,
      views = [],
      openPanel,
      addCamera,
      removeCamera,
      selectedCameras,
      cameras,
      active
    } = this.props;
    const activeKey = openPanel ? openPanel : "help";

    return (
      <Sider width="375">
        <Collapse
          onChange={this.handleCollapse}
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          accordion
        >
          <Panel header="Add Cameras" key="addCameras" showArrow={false}>
            <CameraSearch
              addCamera={addCamera}
              removeCamera={removeCamera}
              selectedCameras={selectedCameras}
              cameras={cameras}
            />
          </Panel>
          <Panel
            className="views-list"
            header="List of Views"
            key="list"
            showArrow={false}
          >
            <ViewList loading={loading} views={views} active={active} />
          </Panel>
          <Panel
            header="Help"
            key="help"
            showArrow={false}
            className="ant-collapse-item--invisible-header"
          >
            <p>
              <FormattedMessage {...messages.helpTextAddCameras} />
            </p>
            <p>
              <FormattedMessage {...messages.helpTextListViews} />
            </p>
          </Panel>
        </Collapse>
      </Sider>
    );
  }
}

ViewSidebar.propTypes = {
  cameras: PropTypes.array,
  loading: PropTypes.bool,
  views: PropTypes.array,
  openPanel: PropTypes.string,
  addCamera: PropTypes.func,
  removeCamera: PropTypes.func,
  active: PropTypes.string
};

export default ViewSidebar;
