import { MOVE_CAMERA, SET_ACTIVE_CAMERA } from "../constants/actionTypes";
import { ptzActions, ptzMoveArgs } from "../utils/schemas";

export const setActiveCamera = id => ({
  type: SET_ACTIVE_CAMERA,
  id
});

export const ptzMove = (CameraId, connection, command) => (
  dispatch,
  getState,
  XPMobileSDK
) => {
  if (
    CameraId &&
    connection &&
    Object.values(ptzActions.move).includes(command)
  ) {
    return Promise.resolve(dispatch({ type: MOVE_CAMERA })).then(() => {
      return XPMobileSDK.ptzSwipe({ CameraId, ...ptzMoveArgs[command] }, 1);
    });
  }
};

export const ptzZoom = (id, connection, command) => (
  dispatch,
  getState,
  XPMobileSDK
) => {
  if (id && connection && Object.values(ptzActions.zoom).includes(command)) {
    return Promise.resolve(dispatch({ type: MOVE_CAMERA })).then(() => {
      return XPMobileSDK.ptzMove(connection, command);
    });
  }
};

export const ptzPreset = (id, connection) => (
  dispatch,
  getState,
  XPMobileSDK
) => {
  if (id && connection) {
    return Promise.resolve(dispatch({ type: MOVE_CAMERA })).then(() => {
      return XPMobileSDK.ptzMove(connection, ptzActions.home);
    });
  }
};

// send a noop upon opening ptz controls because the very first action sent usually doesn't take
export const ptzInit = (CameraId, connection) => (
  dispatch,
  getState,
  XPMobileSDK
) => {
  if (CameraId && connection) {
    return Promise.resolve(dispatch({ type: MOVE_CAMERA })).then(() => {
      return XPMobileSDK.ptzSwipe({ CameraId, ...ptzMoveArgs.noop }, 1);
    });
  }
};
