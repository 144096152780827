import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";

const ErrorModal = ({ visible, intl, ...rest }) => {
  const modalTitle = messages.error;
  return (
    <Modal
      title={intl.formatMessage(modalTitle)}
      visible={visible}
      modal="connectionError"
      footer={null}
      centered={true}
      closable={false}
      className="error-modal"
      {...rest}
    >
      <div className="error-modal__body">
        <FormattedMessage {...messages.message} />
      </div>
      <div className="form-actions">
        <Button onClick={() => window.location.reload()}>
          <FormattedMessage {...messages.refresh} />
        </Button>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  visible: PropTypes.bool
};

ErrorModal.defaultProps = {
  visible: false
};

export default injectIntl(ErrorModal);
