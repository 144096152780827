import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import messages from "./messages";

const Breadcrumbs = ({ items = [] }) => {
  if (!items.length) {
    return null;
  }

  const { Item } = Breadcrumb;

  const breadcrumbs = items.map((breadcrumb, i) => {
    const { path = "", text = "" } = breadcrumb;
    let breadcrumbText =
      !i && !text ? <FormattedMessage {...messages.home} /> : text;
    return (
      <Item key={i}>
        {path ? (
          <Link to={path}>{breadcrumbText}</Link>
        ) : (
          <span className="current">{breadcrumbText}</span>
        )}
      </Item>
    );
  });

  return (
    <Breadcrumb separator={<Icon type="arrow" />}>{breadcrumbs}</Breadcrumb>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.array
};

export default Breadcrumbs;
