import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Layout } from "antd";
import {
  getView,
  getCamera,
  getCameraOrViewNotFound
} from "../../../selectors";
import withData from "../../DataWrapper";
import { setActiveCamera } from "../../../actions/playerActions";
import Spinner from "../../Common/Spinner";
import PageHeader from "../../Common/PageHeader";
import EmptyState from "../../Common/EmptyState";
import CameraThumbnail from "../../Cameras/CameraThumbnail";
import messages from "./messages";
import { PLAYER_MODE_LIVE } from "../constants";
import SingleCameraPlayer from "../SingleCameraPlayer";
import CameraList from "../CameraList";
import NotFoundPage from "../../NotFoundPage";

class CameraPlayerPage extends Component {
  componentDidMount() {
    const {
      actions: { fetchView, setActiveCamera },
      viewId,
      cameraId
    } = this.props;

    if (viewId) {
      fetchView(viewId);
    }

    if (cameraId) {
      setActiveCamera(cameraId);
    }
  }

  componentDidUpdate(prevProps) {
    /*if (
      this.props.view &&
      (!prevProps.view || prevProps.viewId !== this.props.viewId)
    ) {
      this.props.actions.getThumbnails(this.props.view.items);
    }*/

    if (prevProps.cameraId !== this.props.cameraId) {
      this.props.actions.setActiveCamera(this.props.cameraId);
    }
  }

  renderCameraListItem = camera => (
    <CameraThumbnail
      camera={camera}
      active={this.props.cameraId === camera.id}
      link
      theme="dark"
      showSpinner={true}
    />
  );

  render() {
    const { Content, Sider } = Layout;
    const {
      view,
      viewId,
      activeCamera,
      cameraId,
      actions: { fetchThumbnailResponse },
      notFound,
      directStreamingEnabled,
      websocketsEnabled
    } = this.props;

    if (notFound) {
      return <NotFoundPage />;
    }

    if (!view || view.id !== viewId || !activeCamera) {
      return (
        <Content>
          <Helmet title={"Cameras"} />
          <Spinner size="large" />
        </Content>
      );
    }

    const { items = [] } = view;
    if (!items.length) {
      return (
        <Content>
          <Helmet title={"Cameras"} />
          <EmptyState>
            <p className="empty-message">
              <FormattedMessage {...messages.empty} />
            </p>
          </EmptyState>
        </Content>
      );
    }

    return (
      <Content className="container camera-player-page">
        <PageHeader
          title={<FormattedMessage {...messages.title} />}
          breadcrumbs={[
            { path: "/cameras" },
            { path: `/cameras/${activeCamera.viewId}`, text: view.name },
            { text: activeCamera.name }
          ]}
        />
        <Layout className="ant-layout-has-sider">
          <Content>
            {activeCamera.id !== cameraId ? (
              <Spinner size="large" />
            ) : (
              <SingleCameraPlayer
                ids={items}
                fetchThumbnailResponse={fetchThumbnailResponse}
                defaultMode={PLAYER_MODE_LIVE}
                id={activeCamera.id}
                camera={activeCamera}
                disableCapabilities={["sequences"]}
                directStreamingEnabled={directStreamingEnabled}
                websocketsEnabled={websocketsEnabled}
              />
            )}
          </Content>
          <Sider theme="light" width="225">
            {view.cameras && view.cameras.length > 0 && (
              <CameraList
                parentId="camera-player-page__camera-list"
                cameras={view.cameras || []}
                renderItem={this.renderCameraListItem}
              />
            )}
          </Sider>
        </Layout>
      </Content>
    );
  }
}

CameraPlayerPage.propTypes = {
  id: PropTypes.string,
  viewId: PropTypes.string,
  cameraId: PropTypes.string,
  actions: PropTypes.object,
  view: PropTypes.object,
  activeCamera: PropTypes.object,
  notFound: PropTypes.bool,
  directStreamingEnabled: PropTypes.bool,
  websocketsEnabled: PropTypes.bool
};

CameraPlayerPage.defaultProps = {
  viewId: null,
  cameraId: null,
  view: null,
  activeCamera: null
};

const mapStateToProps = state => {
  return {
    view: getView(state),
    activeCamera: getCamera(state),
    notFound: getCameraOrViewNotFound(state),
    directStreamingEnabled: state.utility.enableDirectStreaming,
    websocketsEnabled: state.utility.enableWebsocket
  };
};

export default withData(CameraPlayerPage, mapStateToProps, { setActiveCamera });
