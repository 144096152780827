import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CameraToolbar from "../CameraToolbar";
import CameraFrame from "../CameraFrame";
import {
  PLAYER_MODE_LIVE,
  PLAYER_MODE_PLAYBACK,
  SEEK_TYPE_PREV_FRAME,
  SEEK_TYPE_NEXT_FRAME
} from "../constants";
import CameraPlayer from "../CameraPlayer";
import CameraSequences from "../CameraSequences";
import PlaybackController, {
  sort_by_endtime
} from "../../../utils/playbackController";

import XPMobileSDK from "../../../utils/api";

const sort_by_timestamp = (a, b) =>
  a.startTime < b.startTime ? -1 : a.startTime === b.startTime ? 0 : 1;

class MultiCameraPlayer extends CameraPlayer {
  constructor(props) {
    super(props);
    this.state = this.initState(props);
    this.fullscreen_container = React.createRef();
  }
  mounted = false;
  selectorText =
    ".investigation-page .camera-player-single > .camera-player-content .camera-frame";

  initState = (props = this.props) => ({
    loading: false,
    error: null,
    frames: {},
    mode: PLAYER_MODE_PLAYBACK,
    showModal: null,
    showFullscreen: null,
    speed: 1,
    playing: false,
    timestamp: props.startTime || Date.now(),
    thumbnails: {},
    showPtz: false,
    sequences: { all: [] },
    reverseSequences: { all: [] },
    seekingFrame: false,
    seekType: null,
    cursorPos: null,
    useFallbackRender:
      !props.directStreamingEnabled || !props.websocketsEnabled,
    remountFrame: false
  });

  initPlayer = (state = {}) => {
    this.setState(
      prevState => ({
        ...prevState,
        ...state,
        playbackController: new PlaybackController({
          InvestigationId: this.props.id,
          cameras: this.props.cameras,
          mode: state.mode || PLAYER_MODE_PLAYBACK,
          handleReceiveFrame: this.handleReceiveFrame,
          handleError: this.handleRequestStreamError,
          startTime: this.props.startTime,
          onInit: this.onInitPlaybackController,
          useFallbackRender: state.useFallbackRender,
          onSwitchToFallback: this.onSwitchToFallback
        })
      }),
      () => {
        if (this.props.cameras.filter(c => !!c).length > 0) {
          this.handleGetSequences();
        }
      }
    );
  };

  componentDidMount() {
    this.mounted = true;
    this.getAspectRatioCssRule();
    this.initPlayer();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.mounted = false;
  }

  componentDidUpdate(prev, prevState) {
    if (this.props.id !== prev.id) {
      this.handleStopStream();
      this.initPlayer(this.initState());
      return;
    }

    if (
      prev.websocketsEnabled !== this.props.websocketsEnabled ||
      prev.directStreamingEnabled !== this.props.directStreamingEnabled
    ) {
      this.toggleFallbackRender(
        !this.props.websocketsEnabled || !this.props.directStreamingEnabled
      );
      return;
    }

    const cam_ids = this.props.cameras.map(c => c && c.id).filter(id => !!id);
    const cams_changed =
      prev.cameras.length !== this.props.cameras.length ||
      cam_ids
        .map(cid => !prev.cameras.find(a => a && a.id === cid))
        .includes(true);

    // if the cameras, startTime, or endTime changed, or if the mode was
    // changed to playback, reset the sequences
    if (
      cams_changed ||
      this.props.startTime !== prev.startTime ||
      this.props.endTime !== prev.endTime ||
      (prevState.mode !== this.state.mode &&
        this.state.mode === PLAYER_MODE_PLAYBACK)
    ) {
      // if the cameras changed, reinit the controller
      const newState = {};
      if (cams_changed) {
        this.handleStopStream();
        newState.playbackController = new PlaybackController({
          InvestigationId: this.props.id,
          cameras: this.props.cameras,
          mode: this.state.mode,
          handleReceiveFrame: this.handleReceiveFrame,
          handleError: this.handleRequestStreamError,
          startTime: this.props.startTime,
          useFallbackRender: this.state.useFallbackRender,
          onSwitchToFallback: this.onSwitchToFallback
        });
      }
      newState.sequences = { all: [] };
      newState.thumbnails = {};
      if (
        prevState.mode !== this.state.mode ||
        this.timestamp >= this.props.endTime ||
        this.timestamp < this.props.startTime
      ) {
        newState.timestamp = this.props.startTime;
      }
      this.setState(
        _prevState => ({ ..._prevState, ...newState }),
        this.handleGetSequences
      );
    }

    if (
      this.state.mode === PLAYER_MODE_PLAYBACK &&
      cam_ids.map(id => !prevState.sequences[id]).includes(true) &&
      !cam_ids.map(id => !this.state.sequences[id]).includes(true) &&
      this.state.playbackController &&
      this.state.playbackController.ready
    ) {
      this.play();
    }

    if (
      this.state.seekType &&
      !this.state.seekingFrame &&
      !this.state.playing
    ) {
      this.setState(
        _prevState => ({ ..._prevState, seekingFrame: true }),
        () =>
          this.state.playbackController.seekFrame(
            this.state.seekType,
            this.state.timestamp
          )
      );
    }

    if (
      this.props.cameras.length > 1 &&
      this.aspectRatio &&
      this.frame &&
      this.frame.current
    ) {
      this.aspectRatio = null;
      this.frame &&
        this.frame.current &&
        this.frame.current.removeAttribute("style");
    }
  }

  setStateIfMounted = (...args) => {
    this.mounted && this.setState(...args);
  };

  onInitPlaybackController = () => {
    this.forceUpdate(this.play);
  };

  handleRequestStream = ({ mode }) => {
    this.state.playbackController &&
      this.state.playbackController.togglePlaybackMode(mode, () =>
        this.setStateIfMounted({
          mode,
          loading: mode === PLAYER_MODE_LIVE || this.state.speed !== 0,
          playing: mode === PLAYER_MODE_LIVE
        })
      );
  };

  handleStopStream = callback => {
    if (this.state.playbackController) {
      this.state.playbackController.destroy();
      callback && callback();
    }
  };

  handleRequestStreamError = (id, error) => {
    this.setStateIfMounted({ error });
  };

  getFrame = id =>
    this.state.frames.hasOwnProperty(id) ? this.state.frames[id] : null;

  handleReceiveFrame = (id, newFrame = null) => {
    if (id && newFrame) {
      this.updateFrame(id, newFrame);
    }
  };

  updateFrame = (id, newFrame) => {
    const { speed, playing, mode, seekingFrame, seekType } = this.state;
    if (
      !newFrame.blob ||
      (mode !== PLAYER_MODE_LIVE &&
        ((!playing && !seekingFrame) ||
          !this.state.sequences[id] ||
          this.state.sequences[id].length === 0))
    ) {
      return null;
    }
    const timestamp = newFrame.timestamp.getTime();
    const reverse = seekType ? seekType === SEEK_TYPE_PREV_FRAME : speed < 0;
    const frame = this.getFrame(id);
    const { hasSizeInformation } = newFrame;

    let width, height;
    if (hasSizeInformation) {
      const {
        sizeInfo: { destinationSize }
      } = newFrame;
      width = destinationSize.width;
      height = destinationSize.height;
    }

    const image = new Image(width, height);
    if (
      this.state.mode === PLAYER_MODE_PLAYBACK &&
      (!reverse
        ? timestamp > this.props.endTime
        : timestamp < this.props.startTime)
    ) {
      if (
        !Object.values(this.state.frames).some(f =>
          reverse
            ? f.timestamp.getTime() >= this.props.startTime
            : f.timestamp.getTime() <= this.props.endTime
        )
      ) {
        this.pause();
      } else {
        this.setStateIfMounted(prevState => ({
          ...prevState,
          loading: false,
          frames: {
            ...prevState.frames,
            [id]: { ...frame, timestamp: newFrame.timestamp }
          }
        }));
      }
      return null;
    }

    this.setStateIfMounted(
      prevState => {
        const newTime =
          this.state.mode === PLAYER_MODE_LIVE
            ? Math.max(prevState.timestamp, timestamp)
            : prevState.playbackController.getNextTimestamp(
                timestamp,
                id,
                seekType
              );
        return {
          ...prevState,
          loading: false,
          frames: {
            ...prevState.frames,
            [id]: { ...newFrame, image }
          },
          timestamp: newTime
        };
      },
      () => this.state.playbackController.setFrameTime(id, timestamp)
    );
  };

  play = (speed = this.state.speed) =>
    this.setStateIfMounted(
      prevState => ({
        ...prevState,
        playing: speed !== 0,
        seekingFrame: false,
        seekType: null
      }),
      () =>
        this.state.playbackController &&
        this.state.playbackController.play(
          speed,
          this.state.timestamp,
          () => {
            this.setStateIfMounted(
              speed === 0 ? { playing: false } : { speed }
            );
          },
          () => this.setStateIfMounted({ playing: speed === 0 })
        )
    );
  pause = () => this.play(0);
  reverse = () => this.play(-1 * this.state.speed);
  nextFrame = () =>
    this.setState(prevState => ({
      ...prevState,
      seekType: SEEK_TYPE_NEXT_FRAME,
      seekingFrame: false,
      playing: false
    }));
  prevFrame = () =>
    this.setState(prevState => ({
      ...prevState,
      seekType: SEEK_TYPE_PREV_FRAME,
      seekingFrame: false,
      playing: false,
      loading: false
    }));
  seekTime = timestamp => {
    const {
      timestamp: oldTime,
      playing: oldPlaying,
      playbackController,
      speed
    } = this.state;
    if (!playbackController) {
      return;
    }
    if (!playbackController.getCurrentSequence(timestamp)) {
      const reverse = speed < 0;
      const nextSeq = playbackController.getNextSequence(
        timestamp,
        "all",
        reverse
      );
      if (nextSeq) {
        timestamp = reverse ? nextSeq.endTime : nextSeq.startTime;
      } else {
        timestamp = reverse
          ? this.state.sequences.all[0].startTime
          : _.last(this.state.reverseSequences.all).endTime;
      }
    }

    this.setState(
      prevState => ({
        ...prevState,
        timestamp,
        playing: false,
        seekingFrame: !oldPlaying,
        seekType: null
      }),
      () =>
        playbackController.seekTime(
          timestamp,
          oldPlaying,
          () => {
            oldPlaying && this.setStateIfMounted({ playing: oldPlaying });
          },
          error => {
            this.setStateIfMounted({ timestamp: oldTime, error });
          }
        )
    );
  };

  handleGetSequences = () => {
    const { cameras, startTime, endTime } = this.props;
    const sequences = {};
    const reverseSequences = {};
    const real_cams = cameras.filter(c => c && c.id);
    real_cams.forEach(cam =>
      XPMobileSDK.getSequencesInInterval(
        cam.id,
        startTime,
        endTime,
        undefined,
        res => {
          if (res && Array.isArray(res)) {
            sequences[cam.id] = res
              .map(({ StartTime, EndTime, timestamp }) => {
                const seq = {
                  startTime: new Date(StartTime).getTime(),
                  endTime: new Date(EndTime).getTime(),
                  timestamp
                };
                if (seq.startTime < this.props.startTime) {
                  seq.startTime = this.props.startTime;
                }
                if (seq.endTime > this.props.endTime) {
                  seq.endTime = this.props.endTime;
                }
                return seq;
              })
              .sort(sort_by_timestamp);
          } else {
            sequences[cam.id] = [];
          }
          reverseSequences[cam.id] = sequences[cam.id]
            .slice()
            .sort(sort_by_endtime);
          if (Object.keys(sequences).length === real_cams.length) {
            const all = Object.entries(sequences)
              .map(([key, val]) => val.map(item => ({ ...item, id: key })))
              .reduce((v1, v2) => (v1 || []).concat(v2 || []))
              .sort(sort_by_timestamp);
            this.setStateIfMounted(
              prevState => ({
                ...prevState,
                sequences: {
                  ...sequences,
                  all
                },
                reverseSequences: {
                  ...reverseSequences,
                  all: all.slice().sort(sort_by_endtime)
                }
              }),
              () =>
                this.state.playbackController &&
                this.state.playbackController.setSequences(
                  this.state.sequences,
                  this.state.reverseSequences
                )
            );
          }
        }
      )
    );
  };

  enableCapability = capability => {
    if (this.props.disableCapabilities.indexOf(capability) !== -1) {
      return false;
    }

    const { cameras } = this.props;
    const enabled = cameras.filter(
      camera => camera.capabilities && camera.capabilities[capability]
    );

    return cameras.length === enabled.length;
  };

  getThumbnailAtTime = (id, time, cb) => {
    id = id || this.state.cameras[0].id;
    this.setState(
      {
        thumbnails: {
          ...this.state.thumbnails,
          [id]: { id, pending: true }
        }
      },
      () =>
        XPMobileSDK.getThumbnailByTime(
          { cameraId: id, time: time || this.state.timestamp },
          thumbnail => {
            if (
              typeof thumbnail === "string" &&
              !thumbnail.startsWith("data:image/jpeg;base64,")
            ) {
              thumbnail = "data:image/jpeg;base64,".concat(thumbnail);
            }
            this.setStateIfMounted({
              thumbnails: {
                ...this.state.thumbnails,
                [id]: { id, image: thumbnail, timestamp: time, pending: false }
              }
            });
            cb && typeof cb === "function" && cb(thumbnail);
          }
        )
    );
  };

  handleSetTimestamp = key => timestamp => {
    if (!["timestamp", "endTime", "startTime"].includes(key)) {
      return;
    }
    if (key === "timestamp") {
      this.seekTime(timestamp);
    } else {
      this.props.setTimeBounds && this.props.setTimeBounds(key, timestamp);
    }
    this.toggleDatepickerModal(key)();
  };

  handleSetSpeed = (speed = 1) => {
    if (speed !== this.state.speed) {
      if (this.state.playing) {
        this.play(speed);
      } else {
        this.setState({ speed });
      }
    }
  };

  toggleDatepickerModal = id => (switchToLive = false) => {
    this.handleToggleModal(id);
    if (switchToLive) {
      this.handleTogglePlayerMode(PLAYER_MODE_LIVE);
    }
  };
  handleTogglePlayerMode = mode => {
    this.handleRequestStream({ mode });
  };

  setCursorPos = pos => {
    this.setState({ cursorPos: pos });
  };

  forceReconnect = () => {
    const { mode } = this.state;
    this.handleStopStream();
    this.setState({ loading: true }, () => {
      setTimeout(() => {
        this.setState({
          playbackController: new PlaybackController({
            InvestigationId: this.props.id,
            cameras: this.props.cameras,
            mode: this.state.mode,
            handleReceiveFrame: this.handleReceiveFrame,
            handleError: this.handleRequestStreamError,
            startTime: this.props.startTime,
            useFallbackRender: this.state.useFallbackRender,
            onSwitchToFallback: this.onSwitchToFallback
          })
        });
        this.handleRequestStream({ mode });
      }, 1500);
    });
  };

  onStreamLoad = () => {};

  onSwitchToFallback = () => {
    this.toggleFallbackRender(true);
  };

  toggleFallbackRender = enabled => {
    this.state.playbackController.toggleFallbackRender(enabled, () => {
      this.setState(
        prevState => ({ ...prevState, remountFrame: false }),
        () => {
          if (this.state.mode !== PLAYER_MODE_LIVE) {
            this.state.playbackController.play(0, this.state.timestamp, () => {
              this.prevFrame();
            });
          }
        }
      );
    });
    this.setState(prevState => ({
      ...prevState,
      useFallbackRender: enabled,
      loading: true,
      playing: false,
      remountFrame: true
    }));
  };

  // eslint-disable-next-line react/display-name
  renderFrame = (camera, i) => {
    const { directStreamingEnabled, websocketsEnabled } = this.props;
    const {
      showFullscreen,
      mode,
      playbackController,
      timestamp,
      thumbnails: { [camera.id]: thumbnail = null },
      playing,
      sequences,
      speed,
      remountFrame
    } = this.state;

    if (!thumbnail) {
      this.getThumbnailAtTime(camera.id, timestamp);
    }
    const frame = this.getFrame(camera.id);
    const { image = null, blob = null, frameNumber = null } = frame || {};
    const fullscreen = camera.id === showFullscreen;
    return (
      <CameraFrame
        key={camera.id}
        id={camera.id}
        camera={camera}
        thumbnail={thumbnail}
        image={image}
        mode={mode}
        speed={speed}
        isPlaying={playing}
        showPtz={false}
        handleToggleFullscreen={this.handleToggleFullscreen}
        showFullscreen={fullscreen}
        sequenceBar={
          fullscreen || mode === PLAYER_MODE_LIVE
            ? null
            : this.renderSequenceBar(camera.id)
        }
        forceReconnect={this.forceReconnect.bind(this, camera.id)}
        loading={
          !playbackController ||
          !playbackController.isConnected(camera.id) ||
          (mode === PLAYER_MODE_PLAYBACK && !sequences[camera.id])
        }
        blob={blob}
        frameNumber={frameNumber}
        videoConnection={
          !!playbackController &&
          !!playbackController.connections &&
          !!playbackController.connections[camera.id]
            ? playbackController.connections[camera.id].videoConnection
            : undefined
        }
        useFallbackRender={
          (!!playbackController &&
            !!playbackController.connections &&
            !!playbackController.connections[camera.id] &&
            playbackController.connections[camera.id].useFallbackRender) ||
          mode === PLAYER_MODE_PLAYBACK
        }
        onStreamLoad={this.onStreamLoad}
        onDirectStreamFail={this.onSwitchToFallback}
        directStreamingEnabled={directStreamingEnabled}
        websocketsEnabled={websocketsEnabled}
        isReconnecting={remountFrame}
        isBlank={
          mode === PLAYER_MODE_PLAYBACK &&
          this.state.playbackController &&
          !this.state.playbackController.getCurrentSequence(
            timestamp,
            camera.id
          )
        }
        {...(this.props.cameras.length === 1
          ? {
              setAspectRatio: this.setAspectRatio,
              getFrameRef: ref => {
                this.frame = { current: ref };
              }
            }
          : i === 0 && this.frame
          ? {
              getFrameRef: ref => {
                this.frame = { current: ref };
              }
            }
          : {})}
      />
    );
  };

  renderToolbar = (() => {
    const renderToolbar = () => {
      const { startTime, endTime, dbStart = 0 } = this.props;
      const {
        showModal,
        timestamp,
        thumbnails,
        speed,
        mode,
        playing,
        sequences = {},
        reverseSequences = {}
      } = this.state;
      const seqs = (speed < 0 ? reverseSequences.all : sequences.all) || [];
      const lastSeq = _.last(seqs);

      return (
        <CameraToolbar
          setTimestamp={this.handleSetTimestamp}
          toggleDatepickerModal={this.toggleDatepickerModal}
          datepickerModalVisible={showModal}
          {...this.props}
          isMultiple={true}
          mode={mode}
          speed={speed}
          startTime={startTime}
          endTime={endTime}
          firstFrameTime={seqs[0] && seqs[0].startTime}
          lastFrameTime={lastSeq && lastSeq.endTime}
          timestamp={new Date(timestamp).getTime()}
          enableCapability={this.enableCapability}
          handleTogglePlayerMode={this.handleTogglePlayerMode}
          showPtz={false}
          handleTogglePtz={this.handleTogglePtz}
          showSnapshot={false}
          thumbnail={Object.values(thumbnails)[0]}
          getThumbnail={this.getThumbnailAtTime.bind(this, undefined)}
          playing={playing}
          actions={{
            play: this.play,
            pause: this.pause,
            forward: this.nextFrame,
            back: this.prevFrame,
            setSpeed: this.handleToggleSpeed,
            reverse: this.reverse,
            togglePtz: () => {}
          }}
          dbStart={dbStart}
        />
      );
    };
    renderToolbar.displayName = "renderToolbar()";
    return renderToolbar;
  })();

  renderFullscreen = (() => {
    const renderFullscreen = cameras => {
      const { showFullscreen } = this.state;
      const camera = cameras.find(item => item.id === showFullscreen);
      if (!camera) {
        return null;
      }

      return (
        <div className="camera-player-fullscreen">
          {this.renderFrame(camera)}
          {this.renderSequenceBar(camera.id)}
          {this.renderToolbar()}
        </div>
      );
    };
    renderFullscreen.displayName = "renderFullscreen()";
    return renderFullscreen;
  })();

  renderSequenceBar = (() => {
    const renderSequenceBar = id =>
      !this.state.sequences[id] ? null : (
        <CameraSequences
          sequences={this.state.sequences[id]}
          startTime={this.props.startTime}
          endTime={this.props.endTime}
          timestamp={new Date(this.state.timestamp).getTime()}
          setTimestamp={this.handleSetTimestamp("timestamp")}
          cursorPos={this.state.cursorPos}
          setCursorPos={this.setCursorPos}
        />
      );

    renderSequenceBar.displayName = "renderSequenceBar()";
    return renderSequenceBar;
  })();

  render() {
    const { showFullscreen } = this.state;
    const { cameras } = this.props;
    const content = cameras.map((camera, i) => {
      if (typeof camera === "object" && camera.id) {
        return this.renderFrame(camera, i);
      }

      return (
        <div key={i} className="camera-frame camera-frame-empty">
          {camera}
        </div>
      );
    });

    return (
      <div
        className={`camera-player camera-player-${
          cameras.length > 1 ? "multiple" : "single"
        }`}
        data-camera-count={cameras.length}
        data-fullscreen={!!showFullscreen}
      >
        <Helmet
          htmlAttributes={{
            ["class"]: showFullscreen ? "camera-player-fullscreen" : ""
          }}
        />
        <div className="camera-player-content">
          <div className="camera-player-frames">{content}</div>
          {this.renderToolbar()}
        </div>
        <div ref={this.fullscreen_container} id="camera-player-fullscreen">
          {showFullscreen ? this.renderFullscreen(cameras) : null}
        </div>
      </div>
    );
  }
}

MultiCameraPlayer.propTypes = {
  ...CameraPlayer.propTypes,
  id: PropTypes.string.isRequired,
  cameras: PropTypes.array.isRequired,
  cameraItems: PropTypes.array.isRequired,
  setTimeBounds: PropTypes.func.isRequired,
  dbStart: PropTypes.number
};

MultiCameraPlayer.defaultProps = {
  ...CameraPlayer.defaultProps,
  cameras: [],
  cameraItems: []
};

export default MultiCameraPlayer;
