import { normalize } from "normalizr";
import {
  FETCH_ALL_INVESTIGATIONS_RESPONSE,
  FETCH_ALL_INVESTIGATIONS_ERROR,
  SORT_INVESTIGATIONS,
  FETCH_INVESTIGATION_RESPONSE,
  UPDATING_INVESTIGATION,
  UPDATE_INVESTIGATION_SUCCESS,
  UPDATE_INVESTIGATION_ERROR,
  CREATE_NEW_INVESTIGATION,
  SAVING_NEW_INVESTIGATION,
  CREATE_INVESTIGATION_SUCCESS,
  CREATE_INVESTIGATION_ERROR,
  SAVE_INVESTIGATION,
  DELETE_INVESTIGATION_SUCCESS,
  DELETE_INVESTIGATION_ERROR,
  ADD_INVESTIGATION_CAMERA,
  REMOVE_INVESTIGATION_CAMERA,
  SORT_INVESTIGATION_CAMERAS
} from "../constants/actionTypes";
import { toggleModal } from "./utilityActions";
import { push } from "connected-react-router";
import * as schema from "../utils/schemas";
import {
  FORCE_REFETCH_REASON_NEW,
  FORCE_REFETCH_REASON_UPDATED,
  INVESTIGATION_ID_NEW
} from "../components/Investigations/constants";

export const fetchAllInvestigations = (force = false, _id = "") => {
  return (dispatch, getState, XPMobileSDK) => {
    const {
      investigations: { investigations = [] }
    } = getState();
    if (investigations.length && !force) {
      return Promise.resolve();
    }
    XPMobileSDK.getUserInvestigations(
      response => {
        const { entities = {} } = normalize(response, schema.investigations);
        if (entities.investigations) {
          Object.entries(entities.investigations).forEach(([id, inv]) => {
            entities.investigations[id] = {
              ...inv,
              createdAt: parseInt(inv.createdAt),
              modifiedAt: parseInt(inv.modifiedAt),
              endTime: parseInt(inv.endTime),
              startTime: parseInt(inv.startTime)
            };
          });
        }
        dispatch(fetchInvestigationsResponse(entities, _id));
      },
      error => dispatch({ type: FETCH_ALL_INVESTIGATIONS_ERROR, error })
    );
  };
};

export const fetchInvestigationsResponse = (
  { cameras = {}, investigations = {} },
  _id = ""
) => (dispatch, getState) => {
  const cams = Object.values(cameras);
  dispatch({
    type: FETCH_ALL_INVESTIGATIONS_RESPONSE,
    cameras: cams,
    investigations: Object.values(investigations)
  });

  const { investigation } = getState();
  const id =
    investigation && investigation.id && investigation.loading
      ? investigation.id
      : _id;
  if (id && investigations[id]) {
    dispatch({
      type: FETCH_INVESTIGATION_RESPONSE,
      investigation: {
        ...investigations[id],
        cameras: investigation.items.length
          ? investigation.items
              .map(i => cams.find(c => c.id === i))
              .filter(i => !!i)
          : []
      }
    });
  }
};

export const sortInvestigationList = sort => ({
  type: SORT_INVESTIGATIONS,
  sort
});

export const createNewInvestigation = () => ({
  type: CREATE_NEW_INVESTIGATION
});

export const fetchInvestigation = (id, force) => {
  return (dispatch, getState, XPMobileSDK) => {
    if (id == null || id === INVESTIGATION_ID_NEW) {
      return dispatch({
        type: FETCH_INVESTIGATION_RESPONSE
      });
    }
    if (force) {
      XPMobileSDK.getInvestigation(id, response => {
        const { entities = {} } = normalize([response], schema.investigations);
        if (entities.investigations && entities.investigations[id]) {
          let investigation = entities.investigations[id];
          investigation = {
            ...investigation,
            createdAt: parseInt(investigation.createdAt),
            modifiedAt: parseInt(investigation.modifiedAt),
            endTime: parseInt(investigation.endTime),
            startTime: parseInt(investigation.startTime)
          };
          dispatch({ type: FETCH_INVESTIGATION_RESPONSE, investigation });
          if (force === FORCE_REFETCH_REASON_NEW) {
            dispatch({ type: CREATE_INVESTIGATION_SUCCESS, investigation });
          }
          if (force === FORCE_REFETCH_REASON_UPDATED) {
            dispatch({ type: UPDATE_INVESTIGATION_SUCCESS, investigation, id });
          }
        }
      });
      return;
    }
    const { investigations: invs } = getState();
    const investigation =
      invs &&
      invs.investigations &&
      Array.isArray(invs.investigations) &&
      invs.investigations.find(i => i.id === id);
    if (!investigation) {
      return dispatch(fetchAllInvestigations(true, id));
    }
    return dispatch({ type: FETCH_INVESTIGATION_RESPONSE, investigation });
  };
};

export const updateInvestigationState = (investigation, investigationState) => {
  return (dispatch, getState, XPMobileSDK) => {
    dispatch({ type: UPDATING_INVESTIGATION, id: investigation.id });
    const params = {
      ItemId: investigation.id,
      State: investigationState,
      ProcessingMessage: "Yes"
    };
    XPMobileSDK.updateInvestigationData(
      params,
      () => {
        dispatch({
          type: UPDATE_INVESTIGATION_SUCCESS,
          id: investigation.id,
          investigation: { ...investigation, state: investigationState }
        });
      },
      () => {
        dispatch({ type: UPDATE_INVESTIGATION_ERROR, investigation });
      }
    );
  };
};

export const updateInvestigation = investigation => {
  return (dispatch, getState, XPMobileSDK) => {
    const items = investigation.cameras.map(camera => camera.id);

    const params = {
      Name: investigation.name,
      State: investigation.state,
      StartTime: investigation.startTime,
      EndTime: investigation.endTime,
      CameraId: items,
      ProcessingMessage: "Yes"
    };

    // Update existing.
    const { id } = investigation;
    if (id && id !== INVESTIGATION_ID_NEW) {
      dispatch({ type: SAVING_NEW_INVESTIGATION, id });
      return XPMobileSDK.updateInvestigation(
        { ...params, ItemId: id },
        () => {
          dispatch(fetchInvestigation(id, FORCE_REFETCH_REASON_UPDATED));
          dispatch(toggleModal());
        },
        () => {
          dispatch({ type: UPDATE_INVESTIGATION_ERROR, investigation });
        }
      );
    }

    // Create new.
    dispatch({ type: SAVING_NEW_INVESTIGATION });
    return XPMobileSDK.createInvestigation(
      params,
      ({ ItemId }) => {
        dispatch(fetchInvestigation(ItemId, FORCE_REFETCH_REASON_NEW));
        Promise.all([dispatch(toggleModal())])
          .then(() => dispatch(fetchInvestigation(ItemId)))
          .then(() =>
            dispatch(
              push({
                pathname: `/investigations/${ItemId}`
              })
            )
          );
      },
      () => {
        dispatch({ type: CREATE_INVESTIGATION_ERROR, investigation });
      }
    );
  };
};

export const saveInvestigation = investigation => ({
  type: SAVE_INVESTIGATION,
  investigation
});

export const deleteInvestigation = investigation => {
  return (dispatch, getState, XPMobileSDK) => {
    if (investigation) {
      dispatch({ type: UPDATING_INVESTIGATION, id: investigation.id });
      XPMobileSDK.deleteInvestigation(
        investigation.id,
        () => {
          dispatch(push({ pathname: "/investigations" }));
          dispatch(toggleModal());
          dispatch({ type: DELETE_INVESTIGATION_SUCCESS, investigation });
        },
        () => {
          dispatch({ type: DELETE_INVESTIGATION_ERROR, investigation });
          dispatch(toggleModal());
        }
      );
    }
  };
};

export const addCamera = camera => ({
  type: ADD_INVESTIGATION_CAMERA,
  camera
});

export const removeCamera = id => ({
  type: REMOVE_INVESTIGATION_CAMERA,
  id
});

export const sortCameras = items => ({
  type: SORT_INVESTIGATION_CAMERAS,
  items
});
