import {
  FETCH_VIEW,
  FETCH_VIEW_RESPONSE,
  SET_LAYOUT_COLUMNS,
  REORDER_LAYOUT,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS
} from "../constants/actionTypes";
import { loadStateForUser, saveStateForUser } from "../store/localStorage";

export const initialState = {
  id: null,
  loading: true,
  error: null,
  cameras: [],
  camerasOrder: {},
  removeModeActive: false,
  confirmDelete: false,
  layoutColumns: 4
};

const viewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIEW:
      return { ...state, loading: true, error: null };

    case FETCH_VIEW_RESPONSE:
      return {
        ...state,
        loading: false,
        id: action.id,
        cameras: action.cameras,
        error: action.error
      };

    case SET_LAYOUT_COLUMNS:
      return { ...state, layoutColumns: action.layoutColumns };

    case REORDER_LAYOUT: {
      const newCamerasOrder = { ...state.camerasOrder };
      newCamerasOrder[action.folderId] = action.camerasOrder;
      saveStateForUser(action.username, "view", newCamerasOrder);
      return { ...state, camerasOrder: newCamerasOrder };
    }

    case LOGIN_SUCCESS:
      return {
        ...state,
        camerasOrder: loadStateForUser(action.username, "view") || {}
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default viewReducer;
