import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ToolbarButton from "../ToolbarButton";
import DatepickerModal from "../DatepickerModal";
import messages from "../messages";
import moment from "moment";
import { PLAYER_MODE_LIVE, PLAYER_MODE_PLAYBACK } from "../constants";

const CameraToolbar = props => {
  const {
    mode,
    handleTogglePlayerMode,
    toggleDatepickerModal,
    datepickerModalVisible,
    speed,
    timestamp,
    actions,
    setTimestamp,
    enableCapability,
    startTime,
    endTime,
    firstFrameTime,
    lastFrameTime,
    showPtz,
    handleTogglePtz,
    showSnapshot,
    thumbnail,
    getThumbnail,
    getEvents,
    camera,
    isMultiple,
    playing,
    ready,
    snapshotUrl,
    dbStart
  } = props;

  const renderModeToggle = () =>
    ![PLAYER_MODE_PLAYBACK, PLAYER_MODE_LIVE].includes(mode) ? null : (
      <ToolbarButton
        {...(mode === PLAYER_MODE_LIVE
          ? {
              button: "playback",
              onClick: () => handleTogglePlayerMode(PLAYER_MODE_PLAYBACK)
            }
          : {
              button: "live",
              onClick: () => handleTogglePlayerMode(PLAYER_MODE_LIVE)
            })}
      />
    );

  const getMainButtons = () => {
    if (mode === PLAYER_MODE_LIVE) {
      return enableCapability("ptz") ? (
        <ToolbarButton
          disabled={!ready}
          button={showPtz ? "ptzExit" : "ptz"}
          onClick={() => handleTogglePtz()}
        />
      ) : null;
    }
    const backDisabled = timestamp <= firstFrameTime || !ready;
    const forwardDisabled = timestamp >= lastFrameTime || !ready;
    return (
      <div className="camera-player-toolbar-group camera-player-toolbar-group-main">
        <div className="toolbar-buttons">
          <ToolbarButton
            disabled={backDisabled}
            button="prevFrame"
            onClick={() => actions.back()}
          />
          {speed < 0 && playing ? (
            <ToolbarButton
              disabled={backDisabled}
              button="pause"
              onClick={() => actions.pause()}
            />
          ) : (
            <ToolbarButton
              disabled={backDisabled}
              button="reverse"
              onClick={() => actions.reverse()}
            />
          )}
          <ToolbarButton
            disabled={!ready}
            button="speed"
            onClick={() => actions.setSpeed()}
            label={speed}
          />
          {!endTime && (startTime || timestamp)
            ? renderDatepicker("timestamp", timestamp)
            : null}
          {speed > 0 && playing ? (
            <ToolbarButton
              disabled={forwardDisabled}
              button="pause"
              onClick={() => actions.pause()}
            />
          ) : (
            <ToolbarButton
              disabled={forwardDisabled}
              button="play"
              onClick={() => actions.play(Math.abs(speed))}
            />
          )}
          <ToolbarButton
            disabled={forwardDisabled}
            button="nextFrame"
            onClick={() => actions.forward()}
          />
        </div>
      </div>
    );
  };

  const renderDatepicker = (id, timestamp, label = null) => (
    <DatepickerModal
      id={id}
      key={id}
      label={label}
      className={`btn-datepicker ${id}`}
      setTimestamp={setTimestamp(id)}
      timestamp={new Date(timestamp).getTime()}
      visible={
        isMultiple ? datepickerModalVisible === id : datepickerModalVisible
      }
      toggleDatepickerModal={toggleDatepickerModal(id)}
      liveModeEnabled={enableCapability("ptz")}
      thumbnail={thumbnail}
      getThumbnail={getThumbnail}
      getEvents={getEvents}
      isMultiple={isMultiple}
      minTime={dbStart}
    />
  );

  const timeString = timestamp ? new Date(timestamp).toISOString() : "";

  return (
    <div className="camera-player-toolbar">
      <div className="camera-player-toolbar-groups">
        <div className="camera-player-toolbar-group">
          {startTime
            ? renderDatepicker(
                "startTime",
                startTime,
                <FormattedMessage {...messages.startLabel} />
              )
            : null}
          {renderModeToggle()}
        </div>
        <div className="camera-player-toolbar-group camera-player-toolbar-group-main">
          {startTime && endTime ? (
            <div className="label camera-player-duration">
              {mode === PLAYER_MODE_PLAYBACK && (
                <FormattedMessage
                  {...messages.durationLabel}
                  values={{
                    timestamp: format_timestamp(timestamp - startTime),
                    duration: format_timestamp(endTime - startTime)
                  }}
                />
              )}
              <FormattedMessage
                {...messages.timestampLabel}
                values={{
                  timestamp: (t =>
                    `${t.format("ll")} ${t.format("h:mm:ss a")}`)(
                    moment(timestamp)
                  )
                }}
              />
            </div>
          ) : null}
          {getMainButtons()}
        </div>
        <div className="camera-player-toolbar-group">
          {endTime
            ? renderDatepicker(
                "endTime",
                endTime,
                <FormattedMessage {...messages.endLabel} />
              )
            : null}
          {showSnapshot ? (
            <ToolbarButton
              disabled={!ready}
              button="snapshot"
              filename={
                camera
                  ? `${camera.name}${timeString ? `__${timeString}` : ""}`
                  : ""
              }
              href={snapshotUrl}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

CameraToolbar.propTypes = {
  mode: PropTypes.number,
  ready: PropTypes.bool,
  showDatepickerModal: PropTypes.bool,
  speed: PropTypes.number,
  timestamp: PropTypes.number,
  actions: PropTypes.object,
  setTimestamp: PropTypes.func,
  enableCapability: PropTypes.func,
  endTime: PropTypes.number,
  startTime: PropTypes.number,
  firstFrameTime: PropTypes.number,
  lastFrameTime: PropTypes.number,
  showPtz: PropTypes.bool,
  showSnapshot: PropTypes.bool,
  handleTogglePtz: PropTypes.func,
  thumbnail: PropTypes.any,
  getThumbnail: PropTypes.func,
  getEvents: PropTypes.func,
  isMultiple: PropTypes.bool,
  playing: PropTypes.bool,
  snapshotUrl: PropTypes.string,
  dbStart: PropTypes.number.isRequired
};

CameraToolbar.defaultProps = {
  mode: null,
  ready: true,
  showDatepickerModal: false,
  speed: 1,
  timestamp: null,
  disableCapabilities: [],
  endTime: null,
  startTime: null,
  showPtz: false,
  showSnapshot: true,
  isMultiple: false,
  snapshotUrl: ""
};

export default CameraToolbar;

export const format_timestamp = (d, short = false) => {
  const t = moment.duration(d);
  const maybe = (val, post, pre = "") => (val ? `${pre}${val}${post}` : "");
  const str = `${
    // inline comments are to make this easier to read after prettier runs
    maybe(t.days(), "d, ")
  }${
    //
    maybe(`${t.hours()}`.padStart(2, "0"), ":")
  }${
    //
    maybe(`${t.minutes()}`.padStart(2, "0"), ":")
  }${
    //
    maybe(`${t.seconds()}`.padStart(2, "0"), "") //
  }`;
  if (short && str.startsWith("00:") && str.length === 8) {
    return str.slice(3);
  }
  return str;
};
