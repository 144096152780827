import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Spin, Tooltip } from "antd";
import CameraOverlay from "../CameraOverlay";
import CameraThumbnail from "../CameraThumbnail";
import Spinner from "../../Common/Spinner";
import Icon from "../../Common/Icon";
import AntIcon from "@ant-design/icons-react";
import CameraVideoCanvas from "../CameraVideoCanvas";
import CameraVideoStream from "../CameraVideoStream";
import messages from "../messages";
import { PLAYER_MODE_LIVE, PLAYER_MODE_PLAYBACK } from "../constants";
import ErrorMessage from "../../Common/ErrorMessage";

class CameraFrame extends PureComponent {
  constructor(props) {
    super(props);

    this.frameCanvas = React.createRef();

    this.state = {
      showPtz: false,
      canvasContext: null,
      showFullscreen: false,
      connectionLost: false,
      videoStuck: false
    };

    this.timer = null;
    this.frame = React.createRef();
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    if (this.frameCanvas && this.frameCanvas.current) {
      this.setState({
        canvasContext: this.frameCanvas.current.getContext("2d")
      });
      this.props.getSnapshotGetter &&
        this.props.getSnapshotGetter(this.getSnapshot);
    }
    if (
      this.props.isPlaying &&
      this.props.mode === PLAYER_MODE_LIVE &&
      this.props.useFallbackRender
    ) {
      this.timer = setTimeout(
        this.checkLostConnection.bind(this, this.props.frameNumber),
        process.env.NEXT_FRAME_WAIT_TIME || 10000
      );
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentDidUpdate(prevProps) {
    const frame_changed =
      this.props.image &&
      this.props.blob &&
      this.props.frameNumber !== prevProps.frameNumber;
    if (
      frame_changed ||
      this.props.videoConnection !== prevProps.videoConnection
    ) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      if (this.state.connectionLost) {
        this.setState({ connectionLost: false });
      }
    }
    if (this.props.mode === PLAYER_MODE_LIVE && this.props.useFallbackRender) {
      if (
        frame_changed ||
        (this.props.isPlaying && !prevProps.isPlaying) ||
        prevProps.mode !== PLAYER_MODE_LIVE
      ) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(
          this.checkLostConnection.bind(this, this.props.frameNumber),
          process.env.NEXT_FRAME_WAIT_TIME || 10000
        );
      }
    }
    if (
      this.timer &&
      ((prevProps.isPlaying && !this.props.isPlaying) ||
        this.props.mode === PLAYER_MODE_PLAYBACK)
    ) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    if (
      this.props.mode === PLAYER_MODE_LIVE &&
      prevProps.mode !== PLAYER_MODE_LIVE &&
      this.state.connectionLost
    ) {
      this.setState({ connectionLost: false });
    }
  }

  setVideoStuck = value => {
    if (this.state.videoStuck === value) {
      return;
    }
    if (value && !this.state.connectionLost) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.setState({ connectionLost: true });
        clearTimeout(this.timer);
        this.timer = null;
      }, process.env.NEXT_FRAME_WAIT_TIME || 10000);
    } else if (!value) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    }
    this.setState({
      videoStuck: value,
      connectionLost: !value ? false : this.state.connectionLost
    });
  };

  toggleFullscreen = () => this.props.handleToggleFullscreen(this.props.id);

  checkLostConnection = frameNumber => {
    if (
      frameNumber === this.props.frameNumber &&
      this.props.isPlaying &&
      this.props.mode === PLAYER_MODE_LIVE
    ) {
      if (this.mounted) {
        this.setState({ connectionLost: true });
      }
    }
    clearTimeout(this.timer);
    this.timer = null;
  };

  render() {
    const {
      id,
      camera,
      mode,
      speed,
      frameNumber,
      isPlaying,
      showPtz,
      loading,
      showFullscreen,
      videoConnection,
      children,
      image,
      sequenceBar = null,
      isBlank,
      thumbnail,
      setAspectRatio,
      getFrameRef,
      getSnapshotGetter,
      blob,
      onStreamLoad,
      onDirectStreamFail,
      directStreamingEnabled,
      websocketsEnabled,
      isReconnecting,
      aspectRatio
    } = this.props;

    const { videoStuck } = this.state;

    getFrameRef && getFrameRef(this.frame.current);

    const is_direct_streaming =
      mode === PLAYER_MODE_LIVE &&
      websocketsEnabled &&
      directStreamingEnabled &&
      videoConnection &&
      videoConnection.response.parameters.StreamType === "FragmentedMP4";

    const style = aspectRatio
      ? {
          "--camera-aspect-ratio": aspectRatio
        }
      : {};
    if (!!aspectRatio && showFullscreen && this.frame.current) {
      style.maxWidth = `calc(${this.frame.current.offsetHeight}px / ${aspectRatio})`;
    }

    const fullscreenBtn = (
      <Button
        className="btn-fullscreen"
        type="link"
        onClick={this.toggleFullscreen}
      >
        <Icon type="fullscreen" title={messages.fullscreen} />
      </Button>
    );

    return (
      <div
        key={camera.id}
        className={`camera-frame${
          showFullscreen ? " camera-frame-fullscreen" : ""
        }`}
        data-blank={isBlank}
        data-playing={isPlaying}
        ref={this.frame}
        style={style}
      >
        {this.state.connectionLost && mode === PLAYER_MODE_LIVE && (
          <div className="camera-frame__reconnect">
            <ErrorMessage
              error={{ code: "CAMERA_CONNECTION_LOST" }}
              text={messages.lostConnection}
            />
            {!loading ? (
              <Tooltip title="Force Reconnect">
                <button
                  className="camera-frame__reconnect__button"
                  type="button"
                  onClick={this.props.forceReconnect}
                >
                  <AntIcon
                    type="redo-o"
                    theme="outline"
                    style={{ fill: "#fff", color: "#fff", stroke: "#000" }}
                  />
                </button>
              </Tooltip>
            ) : (
              <Spin />
            )}
          </div>
        )}
        <Spinner
          spinning={(videoStuck || loading) && !this.state.connectionLost}
        >
          <CameraThumbnail
            id={id}
            camera={camera}
            mode={mode}
            theme="dark"
            width={image ? image.width : null}
            height={image ? image.height : null}
            showFullscreen={showFullscreen}
            thumbnail={thumbnail}
            video={
              isReconnecting ? null : is_direct_streaming ? (
                <CameraVideoStream
                  id={id}
                  camera={camera}
                  videoConnection={videoConnection}
                  getSnapshotGetter={getSnapshotGetter}
                  onStreamLoad={onStreamLoad}
                  onDirectStreamFail={onDirectStreamFail}
                  setVideoStuck={this.setVideoStuck}
                  setAspectRatio={this.props.setAspectRatio}
                />
              ) : (
                <CameraVideoCanvas
                  image={image}
                  mode={mode}
                  speed={speed}
                  blob={blob}
                  frameNumber={frameNumber}
                  getSnapshotGetter={getSnapshotGetter}
                />
              )
            }
            isPlaying={isPlaying}
            disableLazyLoad={true}
            setAspectRatio={setAspectRatio}
            has_content={is_direct_streaming || !!blob}
          />
          {showPtz && videoConnection && !this.state.connectionLost ? (
            <CameraOverlay
              id={id}
              preset={camera.capabilities.preset}
              toggleFullscreen={this.toggleFullscreen}
              showFullscreen={showFullscreen}
              videoConnection={videoConnection}
            />
          ) : null}
          {mode === PLAYER_MODE_PLAYBACK ? sequenceBar : null}
          {showFullscreen ? null : fullscreenBtn}
          {children}
        </Spinner>
      </div>
    );
  }
}

CameraFrame.propTypes = {
  id: PropTypes.string,
  image: PropTypes.object,
  thumbnail: PropTypes.object,
  camera: PropTypes.object,
  mode: PropTypes.number,
  speed: PropTypes.number,
  isPlaying: PropTypes.bool,
  showPtz: PropTypes.bool,
  loading: PropTypes.bool,
  isBlank: PropTypes.bool,
  handleToggleFullscreen: PropTypes.func,
  showFullscreen: PropTypes.bool,
  videoConnection: PropTypes.object,
  sequenceBar: PropTypes.element,
  children: PropTypes.element,
  blob: PropTypes.object,
  frameNumber: PropTypes.number,
  aspectRatio: PropTypes.number,
  setAspectRatio: PropTypes.func,
  getFrameRef: PropTypes.func,
  getSnapshotGetter: PropTypes.func,
  forceReconnect: PropTypes.func,
  onStreamLoad: PropTypes.func,
  onDirectStreamFail: PropTypes.func,
  useFallbackRender: PropTypes.bool,
  directStreamingEnabled: PropTypes.bool,
  websocketsEnabled: PropTypes.bool,
  isReconnecting: PropTypes.bool,
  sizeInfo: PropTypes.object
};

CameraFrame.defaultProps = {
  id: null,
  image: null,
  camera: null,
  showPtz: false,
  isPlaying: false,
  loading: false
};

export default CameraFrame;
