import {
  FETCH_ALL_INVESTIGATIONS_RESPONSE,
  SORT_INVESTIGATIONS,
  UPDATING_INVESTIGATION,
  SAVING_NEW_INVESTIGATION,
  UPDATE_INVESTIGATION_SUCCESS,
  UPDATE_INVESTIGATION_ERROR,
  CREATE_INVESTIGATION_SUCCESS,
  CREATE_INVESTIGATION_ERROR,
  DELETE_INVESTIGATION_SUCCESS,
  DELETE_INVESTIGATION_ERROR,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS
} from "../constants/actionTypes";

const initialState = {
  loading: false,
  message: null,
  investigations: [],
  cameras: [],
  sort: "status",
  saving: false
};

const investigationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_INVESTIGATIONS_RESPONSE:
      return {
        ...state,
        investigations: action.investigations,
        cameras: action.cameras,
        loading: false,
        message: null
      };

    case SORT_INVESTIGATIONS:
      return { ...state, sort: action.sort };

    case SAVING_NEW_INVESTIGATION:
      return { ...state, saving: true, message: null };

    case UPDATING_INVESTIGATION:
      return {
        ...state,
        investigations: state.investigations.map(investigation => {
          if (investigation.id !== action.id) {
            return investigation;
          }

          return {
            ...investigation,
            loading: true,
            saving: true,
            message: null
          };
        })
      };

    case UPDATE_INVESTIGATION_SUCCESS:
      return {
        ...state,
        investigations: state.investigations.map(investigation => {
          if (investigation.id !== action.id) {
            return investigation;
          }

          return {
            ...action.investigation,
            loading: false,
            message: { type: "success" },
            modified: false
          };
        }),
        saving: false,
        message: {
          type: "success",
          text: `Updated investigation ${action.investigation.name}`
        }
      };

    case UPDATE_INVESTIGATION_ERROR:
      return {
        ...state,
        investigations: state.investigations.map(investigation => {
          if (investigation.id !== action.investigation.id) {
            return investigation;
          }

          return { ...investigation, loading: false };
        }),
        saving: false,
        message: {
          type: "error",
          text: `Error updating investigation ${action.investigation.name}`
        }
      };

    case CREATE_INVESTIGATION_SUCCESS:
      return {
        ...state,
        investigations: state.investigations
          .filter(investigation => investigation.id !== action.investigation.id)
          .concat([
            {
              createdAt: Date.now(),
              ...action.investigation
            }
          ]),

        status: "Saved",
        loading: false,
        saving: false,
        message: {
          type: "success",
          text: `Created investigation ${action.investigation.name}`
        }
      };

    case CREATE_INVESTIGATION_ERROR:
      return {
        ...state,
        loading: false,
        saving: false,
        status: "Error",
        message: {
          type: "error",
          text: `Error creating investigation ${action.investigation.name}`
        }
      };

    case DELETE_INVESTIGATION_SUCCESS:
      return {
        ...state,
        investigations: state.investigations.filter(
          item => item.id !== action.investigation.id
        ),
        saving: false,
        message: {
          type: "success",
          text: `Deleted investigation ${action.investigation.name}`
        }
      };

    case DELETE_INVESTIGATION_ERROR:
      return {
        ...state,
        saving: false,
        message: {
          type: "error",
          text: `Error deleting investigation ${action.investigation.name}`
        }
      };

    case LOGIN_SUCCESS:
      return { ...state, loading: true };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default investigationsReducer;
