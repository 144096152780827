import { SEEK_TYPE_TIME } from "../components/Cameras/constants";

require("dotenv").config();
import {
  fetchThumbnailResponse,
  setThumbnailPending,
  setThumbnailQueued
} from "../actions/thumbnailActions";
import { getResponseData, getResponseError } from "./api";
import { getEnvVar } from "./env";

// Throttle requests by waiting this long between starting the next queued request
const THUMBNAIL_REQUEST_INTERVAL = getEnvVar("THUMBNAIL_REQUEST_INTERVAL", 50); // ms
// When multiple requests are force-enqueued (not lazy queued), wait this amount of time after sending
// the current request before automatically starting the next one (as opposed to starting the next one
// after receiving a response for the current one)
const THUMBNAIL_REQUEST_INTERVAL_MAX_WAIT_TIME = getEnvVar(
  "THUMBNAIL_REQUEST_INTERVAL_MAX_WAIT_TIME",
  150 // ms
);
// As above, but for the lazy queue
const THUMBNAIL_LAZY_REQUEST_INTERVAL = getEnvVar(
  "THUMBNAIL_LAZY_REQUEST_INTERVAL",
  500 // ms
);
// Wait this long before processing the same request again after failing, to prevent spam
const THUMBNAIL_REQUEST_RETRY_INTERVAL = getEnvVar(
  "THUMBNAIL_REQUEST_RETRY_INTERVAL",
  1000 // ms
);
// Max number of tries for a request before giving up
const THUMBNAIL_REQUEST_MAX_ATTEMPTS = getEnvVar(
  "THUMBNAIL_REQUEST_MAX_ATTEMPTS",
  4 // ms
);

class ThumbnailFetchQueue {
  queue = [];
  dispatch = null;
  getState = null;
  XPMobileSDK = null;
  initialized = false;
  running = false;
  lazyQueue = [];
  timers = {};
  resetting = false;

  initialize = (dispatch, getState, XPMobileSDK) => {
    this.dispatch = dispatch;
    this.getState = getState;
    this.XPMobileSDK = XPMobileSDK;
    this.running = false;
    this.initialized = true;
    this.resetting = false;
    this.timers = {};
  };

  reset = () => {
    this.resetting = true;
    this.dispatch = () => {};
    this.getState = () => {};
    Object.keys(this.timers).forEach(k => {
      clearTimeout(this.timers[k]);
      this.timers[k] = null;
    });
    this.running = false;
    this.queue = [];
    this.lazyQueue = [];
    this.timers = {};
    this.initialized = false;
    this.resetting = false;
  };

  isInitialized = () => this.initialized && !this.resetting;

  enqueue = (request, dispatch, getState, lazy = false) => {
    this.dispatch = dispatch || this.dispatch;
    this.getState = getState || this.getState;
    if (typeof request === "string") {
      request = { id: request, loadAttempts: 0, lazy };
    }
    if (!this.queue.some(r => r.id === request.id)) {
      if (request.lazy && !this.lazyQueue.some(r => r.id === request.id)) {
        this.lazyQueue.push(request);
      } else {
        this.lazyQueue = this.lazyQueue.filter(r => r.id !== request.id);
        this.queue.push(request);
      }
    }
    this.dispatch(setThumbnailQueued(request.id, !lazy));
    if (this.isInitialized() && !this.running) {
      this.processQueue();
    }
  };

  reenqueue = (
    { id, loadAttempts, lazy, errorCode, timestamp },
    autoFetchNext = true
  ) => {
    if (!this.isInitialized()) {
      return;
    }
    if (loadAttempts >= THUMBNAIL_REQUEST_MAX_ATTEMPTS) {
      this.dispatch(setThumbnailPending(id, false));
      this.dispatch(setThumbnailQueued(id, false));
    } else {
      this.enqueue({ id, loadAttempts, lazy, errorCode, timestamp });
    }
    autoFetchNext && this.fetchNextOrStop();
  };

  dequeue = () =>
    this.queue.length === 0 ? this.lazyQueue.shift() : this.queue.shift();

  cancel = (id, lazy) => {
    const request = this.queue.find(i => i.id === id);
    if (request) {
      this.queue = this.queue.filter(i => i.id !== id);
      this.dispatch(setThumbnailQueued(request.id, false));
      lazy && this.lazyQueue.push(request);
    }
    if (!lazy) {
      this.lazyQueue = this.lazyQueue.filter(i => i.id !== id);
    }
  };

  processQueue = () => {
    if (this.isInitialized()) {
      this.running = true;
      requestIdleCallback(this.fetchNext);
    }
  };

  fetchNextOrStop = () => {
    if (
      (this.queue.length === 0 && this.lazyQueue.length === 0) ||
      !this.isInitialized()
    ) {
      this.running = false;
    } else {
      this.timers.fetchNext = setTimeout(
        this.processQueue,
        this.queue.length === 0
          ? THUMBNAIL_LAZY_REQUEST_INTERVAL
          : THUMBNAIL_REQUEST_INTERVAL
      );
    }
  };

  getThumbnail = ({ id, loadAttempts = 0 }) => {
    const { attempts = loadAttempts, ...thumbnail } =
      this.getState().thumbnails.items.find(item => item.id === id) || {};
    return Object.assign(
      { id, loadAttempts, image: null, timestamp: 0 },
      { loadAttempts: attempts, thumbnail }
    );
  };

  fetchNext = () => {
    if (
      !this.running ||
      (this.queue.length === 0 && this.lazyQueue.length === 0)
    ) {
      return;
    }
    const req = this.dequeue();
    let { id, loadAttempts, timestamp, image } = this.getThumbnail(req);

    if (
      image ||
      Date.now() - timestamp < THUMBNAIL_REQUEST_RETRY_INTERVAL ||
      loadAttempts > THUMBNAIL_REQUEST_MAX_ATTEMPTS
    ) {
      this.timers[id] = setTimeout(() => {
        this.reenqueue({ id, loadAttempts, lazy: req.lazy });
      }, Date.now() - timestamp + 1);
      return;
    }
    this.dispatch(setThumbnailPending(id, true));

    const params = {
      CameraId: id,
      ComprLevel: 75,
      DestWidth: 544,
      DestHeight: 389,
      ReuseConnection: true,
      SeekType: SEEK_TYPE_TIME,
      Time:
        req.errorCode === 32
          ? (req.timestamp || Date.now()) - loadAttempts * 10000
          : Date.now()
    };

    this.XPMobileSDK.sendCommand(
      "GetThumbnailByTime",
      params,
      {},
      e => {
        if (!this.isInitialized()) {
          return;
        }
        const autoFetchNext = !!this.timers.autoFetchNextTimer;
        if (this.timers.autoFetchNextTimer) {
          clearTimeout(this.timers.autoFetchNextTimer);
          this.timers.autoFetchNextTimer = null;
        }

        const { Thumbnail } = getResponseData(e) || {};
        const error = getResponseError(e);
        let str = null;
        if (typeof Thumbnail === "string") {
          str = Thumbnail.startsWith("data:image/jpeg;base64,")
            ? Thumbnail
            : "data:image/jpeg;base64,".concat(Thumbnail);
        }
        this.dispatch(fetchThumbnailResponse(id, str, error, loadAttempts));

        if (!str && error) {
          this.reenqueue(
            {
              id,
              loadAttempts,
              lazy: req.lazy,
              errorCode: error.code,
              timestamp: params.Time
            },
            autoFetchNext
          );
        } else {
          this.dispatch(setThumbnailPending(id, false));
          this.dispatch(setThumbnailQueued(id, false));
          autoFetchNext && this.fetchNextOrStop();
        }
      },
      e => {
        if (!this.isInitialized()) {
          return;
        }
        const autoFetchNext = !!this.timers.autoFetchNextTimer;
        if (this.timers.autoFetchNextTimer) {
          clearTimeout(this.timers.autoFetchNextTimer);
          this.timers.autoFetchNextTimer = null;
        }

        const error = getResponseError(e);
        this.dispatch(fetchThumbnailResponse(id, null, error, loadAttempts));
        this.reenqueue(
          {
            id,
            loadAttempts,
            lazy: req.lazy,
            errorCode: error ? error.code : undefined,
            timestamp: params.Time
          },
          autoFetchNext
        );
      }
    );
    this.timers.autoFetchNextTimer = setTimeout(
      () => {
        clearTimeout(this.timers.autoFetchNextTimer);
        this.timers.autoFetchNextTimer = null;
        this.fetchNextOrStop();
      },
      req.lazy
        ? THUMBNAIL_LAZY_REQUEST_INTERVAL
        : THUMBNAIL_REQUEST_INTERVAL_MAX_WAIT_TIME
    );
  };
}

const Queue = new ThumbnailFetchQueue();
export default Queue;

export {
  THUMBNAIL_REQUEST_INTERVAL,
  THUMBNAIL_REQUEST_INTERVAL_MAX_WAIT_TIME,
  THUMBNAIL_LAZY_REQUEST_INTERVAL,
  THUMBNAIL_REQUEST_RETRY_INTERVAL,
  THUMBNAIL_REQUEST_MAX_ATTEMPTS
};
