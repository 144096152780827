import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PageHeader from "../../Common/PageHeader";
import messages from "./messages";
import { MODE_NEW } from "../constants";

const EntityPlaceholder = ({ title, type, pathBase }) => (
  <div className="entity-placeholder">
    <PageHeader title={title} />
    <Link
      className="ant-btn ant-btn-outline ant-btn-block"
      to={`${pathBase}/${MODE_NEW}`}
    >
      <FormattedMessage
        {...messages.btnText}
        values={{ type: _.capitalize(type) }}
      />
    </Link>
  </div>
);

EntityPlaceholder.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  pathBase: PropTypes.string
};

export default EntityPlaceholder;
