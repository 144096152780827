import {
  GET_THUMBNAILS,
  FETCH_THUMBNAIL_RESPONSE,
  SET_THUMBNAIL_PENDING,
  SET_THUMBNAIL_QUEUED,
  LOGOUT_SUCCESS
} from "../constants/actionTypes";

const initialState = {
  ids: [],
  items: []
};

const thumbnailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_THUMBNAILS: {
      return {
        ...state,
        ids: state.ids.concat(
          action.ids.filter(id => state.ids.indexOf(id) === -1)
        )
      };
    }

    case FETCH_THUMBNAIL_RESPONSE: {
      if (state.ids.indexOf(action.id) !== -1) {
        const thumbnail = state.items.find(item => item.id === action.id);
        const prevImage = thumbnail ? thumbnail.image : null;
        const image =
          action.thumbnail && action.thumbnail.image
            ? action.thumbnail.image
            : prevImage;
        return {
          ...state,
          items: [
            ...state.items.filter(item => item.id !== action.id),
            {
              ...action.thumbnail,
              image,
              attempts:
                action.thumbnail && action.thumbnail.image
                  ? 0
                  : action.thumbnail.attempts + 1
            }
          ]
        };
      }

      return {
        ...state,
        ids: [...state.ids, action.id],
        items: [
          ...state.items,
          {
            ...action.thumbnail,
            attempts:
              action.thumbnail && action.thumbnail.image
                ? 0
                : action.thumbnail.attempts + 1
          }
        ]
      };
    }

    case SET_THUMBNAIL_PENDING: {
      const thumbnail = {
        ...(state.items.find(i => i.id === action.thumbnail.id) || {
          image: null
        }),
        ...action.thumbnail
      };
      if (state.ids.indexOf(action.thumbnail.id) === -1) {
        return {
          ...state,
          ids: [...state.ids, action.thumbnail.id],
          items: [...state.items, thumbnail]
        };
      } else {
        return {
          ...state,
          items: [
            ...state.items.filter(i => i.id !== action.thumbnail.id),
            thumbnail
          ]
        };
      }
    }

    case SET_THUMBNAIL_QUEUED: {
      const thumbnail = {
        ...(state.items.find(i => i.id === action.thumbnail.id) || {
          image: null
        }),
        ...action.thumbnail
      };
      const index = state.ids.indexOf(action.thumbnail.id);
      if (index === -1) {
        return {
          ...state,
          ids: [...state.ids, action.thumbnail.id],
          items: [...state.items, thumbnail]
        };
      } else {
        const items = state.items
          .slice()
          .filter(i => i.id !== action.thumbnail.id);
        items.splice(index, 0, thumbnail);
        return {
          ...state,
          items
        };
      }
    }

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default thumbnailReducer;
