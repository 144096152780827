import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "components.LoginForm.title",
    defaultMessage: "Web Client Login"
  },
  usernameLabel: {
    id: "components.LoginForm.usernameLabel",
    defaultMessage: "Username"
  },
  passwordLabel: {
    id: "components.LoginForm.passwordLabel",
    defaultMessage: "Password"
  },
  submit: {
    id: "components.LoginForm.submit",
    defaultMessage: "Sign In"
  },
  errorRequired: {
    id: "components.LoginForm.errorRequired",
    defaultMessage: "{field} is required"
  },
  errorIncorrect: {
    id: "components.LoginForm.invalidCredentials",
    defaultMessage: "Incorrect user name or password."
  },
  spinnerConnecting: {
    id: "components.LoginForm.spinnerConnecting",
    defaultMessage: "Connecting to server..."
  },
  spinnerLoggingIn: {
    id: "components.LoginForm.spinnerLoggingIn",
    defaultMessage: "Logging in..."
  },
  authError: {
    id: "components.LoginForm.authError",
    defaultMessage: "Could not login with that username and password"
  }
});
