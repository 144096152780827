import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Layout, Button, message } from "antd";
import { fetchAllViews } from "../../../actions/viewActions";
import * as customViewActions from "../../../actions/customViewActions";
import * as thumbnailActions from "../../../actions/thumbnailActions";
import withAuthWrapper from "../../AuthWrapper";
import PageHeader from "../../Common/PageHeader";
import ViewSidebar from "../../Views/ViewSidebar";
import CameraFeedThumbnail from "../../Cameras/CameraFeedThumbnail";
import Icon from "../../Common/Icon";
import messages from "./messages";
import { VIEW_MODE_EDIT, VIEW_ID_NEW } from "../constants";
import CameraGrid from "../../Cameras/CameraGrid";
import CameraGridColumns from "../../Cameras/CameraGridColumns";
import {
  getCameras,
  getCustomView,
  getCustomViewNotFound
} from "../../../selectors";
import Spinner from "../../Common/Spinner";
import EntityToolbar from "../../EntityAdmin/EntityToolbar";
import NotFoundPage from "../../NotFoundPage";

class ViewPage extends PureComponent {
  componentDidMount() {
    const {
      actions: {
        fetchCustomView,
        fetchAllViews,
        fetchCustomViews,
        setOpenPanel
      },
      match: { params },
      views: { loading }
    } = this.props;
    const { viewId = null } = params;
    fetchAllViews(true);
    fetchCustomViews(loading);
    fetchCustomView(viewId);

    if (viewId === VIEW_ID_NEW) {
      setOpenPanel("addCameras");
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      actions: { fetchCustomView, setOpenPanel }
    } = this.props;
    const { viewId } = params;

    if (!prevProps.match.params || prevProps.match.params.viewId !== viewId) {
      fetchCustomView(viewId);

      if (viewId === VIEW_ID_NEW) {
        setOpenPanel("addCameras");
      }
    }
    if (
      this.props.views.message &&
      prevProps.views.message !== this.props.views.message
    ) {
      const { type = "info", text } = this.props.views.message;
      message[type](text);
    }
  }

  toggleModal = (modal = "") => {
    const {
      actions: { toggleModal },
      utility
    } = this.props;
    toggleModal(utility.showModal === modal ? "" : modal);
  };

  handleDeleteView = id => {
    const {
      actions: { deleteView }
    } = this.props;
    this.toggleModal("delete");
    deleteView(id);
  };

  addCamera = camera => this.props.actions.addCamera(camera.id);
  removeCamera = camera => this.props.actions.removeCamera(camera.id);

  renderThumbnailContent = (item, mode = "") => {
    if (item) {
      switch (mode) {
        case VIEW_MODE_EDIT:
          return (
            <Button
              type="link"
              className="btn-remove-camera btn-remove-camera--view"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.removeCamera(item);
              }}
            >
              <div className="btn-text">
                <Icon type="close" />
                <FormattedMessage {...messages.removeCamera} />
              </div>
            </Button>
          );

        default:
          return "";
      }
    }

    return (
      <Button
        type="link"
        className="btn-add-camera"
        onClick={() => this.props.actions.setOpenPanel("addCameras")}
      >
        <div className="btn-text">
          <Icon type="plus" />
          <FormattedMessage {...messages.addCamera} />
        </div>
      </Button>
    );
  };

  renderCameraListItem = (mode, isNew, size) => camera => (
    <CameraFeedThumbnail
      camera={camera}
      active={this.props.cameraId === camera.id}
      link={!isNew && mode !== VIEW_MODE_EDIT}
      theme="dark"
      showSpinner={true}
      size={size}
    >
      {this.renderThumbnailContent(camera, mode)}
    </CameraFeedThumbnail>
  );

  renderContent = (viewId, mode = "") => {
    const { view = {}, actions } = this.props;
    const { id = null, loading, cameras, layoutColumns = 4 } = view;

    if (
      (view.id && view.loading) ||
      (viewId && (viewId !== id || (viewId !== VIEW_ID_NEW && !cameras.length)))
    ) {
      return <Spinner size="large" />;
    }

    if (!viewId && !mode) {
      return (
        <div className="empty-state">
          <Link
            className="ant-btn ant-btn-outline ant-btn-block"
            to={`/views/${VIEW_ID_NEW}`}
          >
            <FormattedMessage {...messages.newButton} />
          </Link>
        </div>
      );
    }

    return (
      <CameraGrid
        cameras={cameras || []}
        loading={loading || viewId !== id}
        layoutColumns={layoutColumns}
        viewId={id}
        renderItem={this.renderCameraListItem(
          mode,
          id === VIEW_ID_NEW,
          layoutColumns
        )}
        reorderLayout={order => actions.reorderLayout(order)}
        enableSorting={mode === VIEW_MODE_EDIT || viewId === VIEW_ID_NEW}
      />
    );
  };

  renderPageHeader = (viewId, view = {}, mode, showModal, actions) => {
    if (!viewId && !mode) {
      return <PageHeader title="Views" />;
    }

    const { name, layoutColumns = 4 } = view;
    const { updateView, deleteView, setLayoutColumns } = actions;

    const path = `/views/${viewId}`;
    const isNew = viewId === VIEW_ID_NEW;

    return (
      <PageHeader
        title={name || <FormattedMessage {...messages.titleNew} />}
        showTitle
      >
        <EntityToolbar
          entity={{ ...view, type: "View" }}
          layoutColumns={
            <CameraGridColumns
              onSelect={setLayoutColumns}
              active={layoutColumns}
            />
          }
          pathBase={path}
          currentPath={this.props.match.url}
          editPath={isNew ? "" : `${path}/${VIEW_MODE_EDIT}`}
          handleDelete={deleteView}
          handleSave={updateView}
          toggleModal={this.toggleModal}
          showModal={showModal}
          canSave={view.cameras && view.cameras.length > 0}
          saving={this.props.views.saving}
        />
      </PageHeader>
    );
  };

  render() {
    const { Content } = Layout;
    const {
      views,
      actions,
      utility: { showModal, openPanel = "" },
      match: { params },
      view = { cameras: [], modified: false },
      cameras,
      notFound
    } = this.props;
    const { viewId = null, mode = "" } = params;
    const { loading, views: list = [], viewListSort } = views;
    const { sortViewList, setViewStatus, setOpenPanel } = actions;

    return (
      <Content className="views-page">
        <Helmet title="Views" />
        <Layout data-modified={view.modified}>
          <ViewSidebar
            active={viewId}
            loading={loading}
            views={list}
            sort={viewListSort}
            handleSortList={sortViewList}
            handleSetState={setViewStatus}
            openPanel={openPanel}
            setOpenPanel={setOpenPanel}
            addCamera={
              mode === VIEW_MODE_EDIT || viewId === VIEW_ID_NEW
                ? this.addCamera
                : undefined
            }
            removeCamera={
              mode === VIEW_MODE_EDIT || viewId === VIEW_ID_NEW
                ? this.removeCamera
                : undefined
            }
            selectedCameras={view.cameras.map(c => c.id)}
            cameras={cameras}
          />
          {notFound ? (
            <NotFoundPage />
          ) : (
            <Content>
              {this.renderPageHeader(viewId, view, mode, showModal, actions)}
              {this.renderContent(viewId, mode)}
            </Content>
          )}
        </Layout>
      </Content>
    );
  }
}

ViewPage.propTypes = {
  cameraSearch: PropTypes.object,
  views: PropTypes.object,
  view: PropTypes.object,
  actions: PropTypes.object,
  utility: PropTypes.object,
  notFound: PropTypes.bool
};

const mapStateToProps = state => {
  const { cameraSearch, utility, customViews } = state;
  return {
    cameraSearch,
    utility,
    cameras: getCameras(state),
    views: customViews,
    view: getCustomView(state),
    notFound: getCustomViewNotFound(state)
  };
};

export default withAuthWrapper(ViewPage, mapStateToProps, {
  ...customViewActions,
  ...thumbnailActions,
  fetchAllViews
});
