import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "components.SearchPage.title",
    defaultMessage: "Search results"
  },
  resultsSummary: {
    id: "components.SearchPage.resultsSummary",
    defaultMessage:
      '{resultCount, plural, =0 {0 matches} one {# match} other {# matches}} found for "{searchTerm}"'
  },
  resultsListHeader: {
    id: "components.SearchPage.resultsListHeader",
    defaultMessage: "{group} ({count})"
  }
});
