import {
  TOGGLE_SEARCH,
  SEARCH,
  GET_SEARCH_RESULTS,
  LOGOUT_SUCCESS
} from "../constants/actionTypes";
import { getSearch } from "../utils/queryString";

const initialState = {
  loading: false,
  error: "",
  showSearchForm: false,
  searchTerm: getSearch() || "",
  results: []
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SEARCH:
      return { ...state, showSearchForm: action.show };

    case SEARCH:
      return {
        ...state,
        searchTerm: action.searchTerm,
        loading: true,
        showSearchForm: true
      };

    case GET_SEARCH_RESULTS:
      return { ...state, results: action.results, loading: false };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default searchReducer;
