import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions/searchActions";
import { FormattedMessage } from "react-intl";
import { Button, Icon as AntIcon } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import Spinner from "../../Common/Spinner";
import Icon from "../../Common/Icon";
import CameraSearchForm from "../CameraSearchForm";
import CameraThumbnail from "../CameraThumbnail";
import messages from "./messages";
import CameraList from "../CameraList";

class CameraSearch extends PureComponent {
  constructor(props) {
    super(props);

    this.scrollParent = React.createRef();

    this.state = {
      data: props.cameras,
      listHeight: 0,
      pages: [],
      currentPage: -1,
      rowsPerPage: 0,
      searchTerm: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.searchTerm !== this.props.searchTerm ||
      ((!prevProps.cameras || !prevProps.cameras.length) &&
        this.props.cameras &&
        this.props.cameras.length > 0)
    ) {
      this.setState({
        currentPage: -1,
        data: this.getFilteredCameras()
      });
    }
  }

  componentDidMount() {
    const parent = this.scrollParent.current.parentElement.parentElement
      .parentElement;
    parent.addEventListener("transitionend", this.chunkCameras);
  }

  componentWillUnmount() {
    this.scrollParent.current.parentElement.parentElement.parentElement.removeEventListener(
      "transitionend",
      this.chunkCameras
    );
    this.props.actions.cameraSearch();
  }

  getFilteredCameras = () => {
    const { cameras = [], searchTerm } = this.props;
    return searchTerm
      ? cameras.filter(
          item =>
            item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        )
      : cameras;
  };

  chunkCameras = () => {
    const cameras = this.getFilteredCameras();
    const ids = cameras.map(camera => camera.id);
    const rowsPerPage = this.calculateListHeight();
    if (rowsPerPage) {
      this.setState({ pages: _.chunk(ids, rowsPerPage * 2) });
    }
  };

  calculateListHeight = () => {
    if (this.state.rowsPerPage) {
      return this.state.rowsPerPage;
    }

    const listHeight = this.scrollParent.current.clientHeight;
    const rowHeight = 122;
    const rowsPerPage = Math.ceil(listHeight / rowHeight);
    this.setState({ listHeight, rowsPerPage });

    return rowsPerPage;
  };

  // eslint-disable-next-line react/display-name
  renderButtonContent = (camera, isSelected) => {
    const { addCamera, removeCamera } = this.props;
    if (isSelected) {
      return removeCamera ? (
        <Button
          type="link"
          className="btn-remove-camera"
          size="small"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            removeCamera(camera);
          }}
        >
          <div className="btn-text btn-text-remove">
            <Icon type="close" />
            <FormattedMessage {...messages.removeCamera} />
          </div>
          <div className="btn-text btn-text-added">
            <AntIcon type="check-circle" theme="filled" />
            <FormattedMessage {...messages.selectedCamera} />
          </div>
        </Button>
      ) : (
        <div className="ant-btn ant-btn-link ant-btn-sm btn-camera-added">
          <div className="btn-text">
            <AntIcon type="check-circle" theme="filled" />
            <FormattedMessage {...messages.selectedCamera} />
          </div>
        </div>
      );
    }

    if (addCamera) {
      return (
        <Button type="link" size="small" onClick={() => addCamera(camera)}>
          <div className="btn-text">
            <Icon type="plus-circle" />
            <FormattedMessage {...messages.addCamera} />
          </div>
        </Button>
      );
    }
    return null;
  };

  handleCameraSearchSubmit = searchTerm => {
    this.props.actions.cameraSearch(searchTerm);
  };

  handleInfiniteOnLoad = () => {
    const { data, pages, currentPage } = this.state;
    const { cameras } = this.props;
    const nextPage = currentPage + 1;
    const page = pages[nextPage]
      .map(id => cameras.find(c => c.id === id))
      .filter(c => !!c);
    //this.props.getThumbnails(page);
    this.setState({ currentPage: nextPage, data: data.concat(page) });
  };

  // eslint-disable-next-line react/display-name
  getList = (cameras, loading) => {
    const { selectedCameras, camerasLoading } = this.props;
    const { pages, currentPage } = this.state;
    return (
      <InfiniteScroll
        useWindow={false}
        pageStart={-1}
        loadMore={() => this.handleInfiniteOnLoad()}
        hasMore={!loading && currentPage < pages.length - 2}
        loader={<Spinner key="loading" theme="light" />}
      >
        <CameraList
          parentId="camera-search__camera-list"
          cameras={cameras}
          locale={{
            emptyText: "No results"
          }}
          loading={camerasLoading}
          renderItem={camera => {
            const isSelected = selectedCameras.indexOf(camera.id) !== -1;
            return (
              <CameraThumbnail
                active={isSelected}
                id={camera.id}
                camera={camera}
                showSpinner
              >
                {this.renderButtonContent(camera, isSelected)}
              </CameraThumbnail>
            );
          }}
        />
      </InfiniteScroll>
    );
  };

  render() {
    const { cameras: pCameras = [], camerasLoading } = this.props;
    const { data, listHeight } = this.state;
    const cameras = pCameras.filter(camera =>
      data.find(c => c.id === camera.id)
    );
    const loading =
      camerasLoading || data.length
        ? cameras.filter(camera => !camera.image && !camera.error).length
        : false;

    return (
      <div className="camera-search">
        <CameraSearchForm
          handleSearch={value => this.handleCameraSearchSubmit(value)}
        />
        <div className="camera-search-items" ref={this.scrollParent}>
          <div
            className="camera-search-items-content"
            style={listHeight ? { height: listHeight } : null}
          >
            {this.getList(data, loading)}
          </div>
        </div>
      </div>
    );
  }
}

CameraSearch.propTypes = {
  cameras: PropTypes.array.isRequired,
  selectedCameras: PropTypes.array,
  addCamera: PropTypes.func,
  removeCamera: PropTypes.func,
  getThumbnails: PropTypes.func,
  searchTerm: PropTypes.string,
  camerasLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  searchTerm: state.cameraSearch.searchTerm
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraSearch);
