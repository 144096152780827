export const INVESTIGATION_ID_NEW = "new";
export const INVESTIGATION_MODE_EDIT = "edit";
export const INVESTIGATION_MODE_DOWNLOAD = "download";

export const INVESTIGATION_STATE_ACTIVE = "InProgress";
export const INVESTIGATION_STATE_INACTIVE = "Completed";
export const INVESTIGATION_STATE_OLD = "Old";
export const INVESTIGATION_STATUS_SAVING = "Saving";
export const INVESTIGATION_STATUS_SAVED = "Saved";

export const EXPORT_TYPE_AVI = "avi";
export const EXPORT_TYPE_DB = "db";
export const EXPORT_TYPE_MKV = "mkv";
export const EXPORT_STATUS_READY = "Ready";
export const EXPORT_STATUS_PROCESSING = "Processing";
export const EXPORT_STATUS_DELETING = "Deleting";
export const EXPORT_STATUS_LOADING = "Loading";
export const EXPORT_STATUS_REQUESTING = "Requesting";
export const EXPORT_STATUS_QUEUED = "Queued";
export const EXPORT_STATUS_ERROR = "Error";

export const FORCE_REFETCH_REASON_ANY = "any";
export const FORCE_REFETCH_REASON_NEW = "new";
export const FORCE_REFETCH_REASON_UPDATED = "updated";
