import React from "react";
import PropTypes from "prop-types";
import { format_timestamp } from "../CameraToolbar";

const CURSOR_SEQUENCE_SNAP_THRESHOLD = 0.01;

const clamp = (n, offset = 0) => Math.max(Math.min(100 - offset, n), offset);

const CameraSequences = ({
  sequences,
  startTime,
  endTime,
  timestamp,
  setTimestamp,
  cursorPos,
  setCursorPos
}) => {
  const duration = endTime - startTime;
  const get_x = start => clamp((100 * (start - startTime)) / duration);
  const get_width = (start, end) => clamp((100 * (end - start)) / duration);

  const bars = sequences.map((seq, i) => {
    const start = seq.startTime;
    const end = seq.endTime;
    return (
      <div
        key={i}
        className={`camera-player-sequence${
          timestamp >= start && timestamp <= end
            ? " camera-player-sequence-active"
            : ""
        }`}
        data-text={`${format_timestamp(
          start - startTime,
          true
        )} - ${format_timestamp(end - startTime, true)}`}
        data-start={start}
        data-end={end}
        style={{
          left: `${get_x(Math.max(start, startTime))}%`,
          width: `${get_width(start, Math.min(end, endTime))}%`
        }}
      >
        &nbsp;
      </div>
    );
  });

  const setCursorPosition = e => {
    let { target, layerX } = e.nativeEvent;
    if (!target) {
      return;
    }

    let raw_pos = layerX / target.clientWidth;
    if (target.className !== "camera-player-sequences") {
      if (!target.dataset) {
        return;
      }
      const start = parseInt(target.dataset.start || "");
      const end = parseInt(target.dataset.end || "");
      if (isNaN(start) || isNaN(end)) {
        return;
      }
      raw_pos = (start - startTime + raw_pos * (end - start)) / duration;
    }

    const raw_time = Math.round(startTime + raw_pos * duration);
    const nearest_seq = sequences.reduce((closest, s) => {
      const startDiff = Math.abs(raw_time - s.startTime);
      const endDiff = Math.abs(raw_time - s.endTime);
      if (
        startDiff < endDiff &&
        startDiff / duration <= CURSOR_SEQUENCE_SNAP_THRESHOLD
      ) {
        return !closest || closest.diff > startDiff
          ? { timestamp: s.startTime, diff: startDiff }
          : closest;
      } else if (endDiff / duration <= CURSOR_SEQUENCE_SNAP_THRESHOLD) {
        return !closest || closest.diff > endDiff
          ? { timestamp: s.endTime, diff: endDiff }
          : closest;
      }
      return closest;
    }, undefined);
    if (nearest_seq) {
      raw_pos = (nearest_seq.timestamp - startTime) / duration;
    }

    setCursorPos(raw_pos * 100);
  };
  const cursor_pos = clamp((100 * (timestamp - startTime)) / duration, 0.25);

  if (!bars || !duration) {
    return null;
  }

  return (
    <div className="camera-player-sequences-bar">
      <div
        className="camera-player-sequences"
        onMouseMoveCapture={setCursorPosition}
        onMouseLeave={() => {
          setCursorPos(null);
        }}
        onClick={() =>
          setTimestamp(Math.round(startTime + (cursorPos / 100) * duration))
        }
      >
        {bars}
        <div
          style={{ left: `${cursor_pos}%` }}
          className="camera-player-sequences-cursor"
        >
          &nbsp;
        </div>
        {cursorPos != null ? (
          <div
            style={{ left: `${cursorPos}%` }}
            className="camera-player-sequences-cursor camera-player-sequences-cursor-ghost"
            data-text={`${format_timestamp(
              Math.round((cursorPos / 100) * duration),
              true
            )}`}
          >
            &nbsp;
          </div>
        ) : null}
      </div>
    </div>
  );
};

CameraSequences.propTypes = {
  sequences: PropTypes.arrayOf(
    PropTypes.shape({
      Timestamp: PropTypes.string,
      startTime: PropTypes.number.isRequired,
      endTime: PropTypes.number.isRequired,
      id: PropTypes.string
    })
  ).isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  timestamp: PropTypes.number.isRequired,
  setTimestamp: PropTypes.func,
  cursorPos: PropTypes.number,
  setCursorPos: PropTypes.func
};

CameraSequences.defaultProps = {
  sequences: []
};

export default CameraSequences;
