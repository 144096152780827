import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Modal, DatePicker, TimePicker } from "antd";
import { FormattedMessage } from "react-intl";
import Icon from "../../Common/Icon";
import moment from "moment";
import messages from "../messages";

const dateFormat = "ddd MMM D, YYYY";
const timeFormat = "h:mm:ss A";
const floorDay = time =>
  new Date(
    new Date(time).toISOString().replace(/^(.*T).*$/, "$100:00:00.000Z")
  ).getTime();

const combineISOStrings = (date, time) =>
  `${date.slice(0, 10)}T${time.slice(11, -1)}Z`;

class DatePickerModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      timestamp: moment(props.timestamp).clone(),
      thumbnail: props.thumbnail
    };
  }

  componentDidMount() {
    this.fetchEventsAndThumbnail();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.setState(
        {
          timestamp: moment(this.props.timestamp)
        },
        this.fetchEventsAndThumbnail
      );
    } else if (
      prevProps.timestamp !== this.props.timestamp &&
      this.state.current
    ) {
      this.setState(
        {
          current: null
        },
        this.fetchEventsAndThumbnail
      );
    }
  }

  onChangeDate = e => {
    this.setState(
      {
        timestamp: moment(
          combineISOStrings(
            e.toISOString(),
            new Date(this.state.timestamp).toISOString()
          )
        )
      },
      this.fetchEventsAndThumbnail
    );
  };

  onChangeTime = e => {
    this.setState(
      {
        timestamp: moment(
          combineISOStrings(
            new Date(this.state.timestamp).toISOString(),
            e.toISOString()
          )
        )
      },
      this.fetchEventsAndThumbnail
    );
  };

  onClickSubmit = () => {
    this.props.setTimestamp(this.state.timestamp.valueOf());
  };

  fetchThumbnail = () => {
    this.props.getThumbnail(this.state.timestamp.valueOf(), thumbnail =>
      this.setState({ thumbnail })
    );
  };

  fetchEventsAndThumbnail = () => {
    if (this.props.isMultiple) {
      return;
    }
    const current = this.state.current
      ? this.state.current.EndTime.getTime()
      : this.state.timestamp;
    this.props.getEvents(current + 1, "next", next => {
      this.props.getEvents(this.state.timestamp.valueOf() - 1, "prev", prev => {
        this.setState({ next, prev }, this.fetchThumbnail);
      });
    });
  };

  goToEvent = dir => () => {
    const { next, prev } = this.state;
    if (!((dir === "prev" && prev) || (dir === "next" && next))) {
      return;
    }
    this.setState(
      {
        current: this.state[dir],
        timestamp: moment(this.state[dir].StartTime)
      },
      this.fetchEventsAndThumbnail
    );
  };

  enforceMaxTime = () => {
    const { minTime } = this.props;
    const { timestamp } = this.state;
    const getTimes = (limit, change) => () => {
      const times = [];
      for (let i = 0; i < limit; i++) {
        let d = timestamp.toDate();
        change(d, i);
        if (d.getTime() > new Date().getTime() || d.getTime < minTime) {
          times.push(i);
        }
      }
      return times;
    };
    return {
      disabledHours: getTimes(24, (d, i) => d.setHours(i)),
      disabledMinutes: getTimes(60, (d, i) => d.setMinutes(i)),
      disabledSeconds: getTimes(60, (d, i) => d.setSeconds(i))
    };
  };

  viewLive = () => {
    this.props.setTimestamp(new Date().getTime(), true);
  };

  render() {
    const {
      visible,
      setTimestamp,
      toggleDatepickerModal,
      label,
      liveModeEnabled,
      isMultiple,
      id,
      minTime
    } = this.props;
    const { timestamp, thumbnail, next, prev } = this.state;

    return (
      <div className={`datepicker-modal ${id}-modal`}>
        {label ? (
          <div className="label datepicker-modal-label">{label}</div>
        ) : null}
        <Button
          type="dark"
          className="btn-datepicker-modal-toggle"
          onClick={() => toggleDatepickerModal()}
        >
          {moment(this.props.timestamp).format("MMM D, YYYY h:mm:ss A")}
        </Button>
        <Modal
          title={<FormattedMessage {...messages.datepickerModalTitle} />}
          visible={visible}
          footer={
            <Button type="primary" onClick={this.onClickSubmit}>
              <FormattedMessage {...messages.datepickerModalSubmitBtn} />
            </Button>
          }
          onOk={() => setTimestamp()}
          onCancel={() => toggleDatepickerModal()}
          centered
          className="datepicker-modal-datepicker"
          width={626}
        >
          {!liveModeEnabled ? null : (
            <Button
              className="btn-toggle-live"
              type="link"
              onClick={this.viewLive}
            >
              <Icon type="live" />
              <FormattedMessage {...messages.viewLive} />
            </Button>
          )}
          {!isMultiple && (
            <div className="timestamp-nav">
              {prev ? (
                <Button
                  className="timestamp-nav-prev"
                  onClick={this.goToEvent("prev")}
                >
                  <Icon type="left-arrow" />
                  <div className="btn-text">
                    <FormattedMessage {...messages.datepickerModalLast} />
                    <div className="timestamp-nav-value">
                      {moment(prev.StartTime).format("h:mm:ss A")}
                    </div>
                  </div>
                </Button>
              ) : (
                <div className="timestamp-nav-prev" />
              )}
              <div className="timestamp-nav-thumbnail">
                <img src={thumbnail} />
              </div>
              {next ? (
                <Button
                  className="timestamp-nav-next"
                  onClick={this.goToEvent("next")}
                >
                  <div className="btn-text">
                    <FormattedMessage {...messages.datepickerModalNext} />
                    <div className="timestamp-nav-value">
                      {moment(next.StartTime).format("h:mm:ss A")}
                    </div>
                  </div>
                  <Icon type="right-arrow" />
                </Button>
              ) : (
                <div className="timestamp-nav-prev" />
              )}
            </div>
          )}
          <div className="datepicker-modal-datepicker-input">
            <DatePicker
              onChange={this.onChangeDate}
              value={timestamp}
              showTime={false}
              format={dateFormat}
              allowClear={false}
              disabledDate={m =>
                m.valueOf() >= new Date().getTime() ||
                m.valueOf() < floorDay(minTime)
              }
            />
            <TimePicker
              onChange={this.onChangeTime}
              value={timestamp}
              format={timeFormat}
              use12Hours={true}
              allowEmpty={false}
              {...this.enforceMaxTime()}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

DatePickerModal.propTypes = {
  visible: PropTypes.bool,
  timestamp: PropTypes.number.isRequired,
  setTimestamp: PropTypes.func.isRequired,
  toggleDatepickerModal: PropTypes.func,
  label: PropTypes.node,
  liveModeEnabled: PropTypes.bool,
  thumbnail: PropTypes.any,
  getThumbnail: PropTypes.func,
  getEvents: PropTypes.func,
  isMultiple: PropTypes.bool,
  id: PropTypes.string,
  minTime: PropTypes.number
};

DatePickerModal.defaultProps = {
  label: null,
  liveModeEnabled: true,
  timestamp: moment(new Date()),
  isMultiple: false,
  minTime: 0
};

export default DatePickerModal;
