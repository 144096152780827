import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import messages from "./messages";

const ErrorMessage = ({ intl, error, text = null }) => {
  if (
    process.env.SHOW_ERRORS !== "true" &&
    process.env.SHOW_ERRORS !== true &&
    !text
  ) {
    return null;
  }
  const { code = "", message = "" } = error;
  const errorText =
    text && typeof text === "object" ? intl.formatMessage(text) : text;
  return (
    <span className="error-message">
      <span className="error-message-text">
        {errorText ? errorText : message}
      </span>
      <span className="error-message-code">
        {code && code !== 32 && code !== "CAMERA_CONNECTION_LOST"
          ? intl.formatMessage(messages.errorCode, { code })
          : null}
      </span>
    </span>
  );
};

ErrorMessage.propTypes = {
  intl: PropTypes.object,
  error: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default injectIntl(ErrorMessage);
