const SAVED_STATE_KEY = "DVSavedState";

export const loadState = () => {
  try {
    const savedState = localStorage.getItem(SAVED_STATE_KEY);
    if (savedState === null) {
      return undefined;
    }
    return JSON.parse(savedState);
  } catch (err) {
    // unable to read from localStorage
    return undefined;
  }
};

export const loadStateForUser = (user, key) => {
  try {
    const savedState = localStorage.getItem(SAVED_STATE_KEY);

    if (savedState === null) {
      return undefined;
    }
    return JSON.parse(savedState)[user][key];
  } catch (err) {
    // unable to read from localStorage
    return undefined;
  }
};

export const saveState = state => {
  try {
    localStorage.setItem(SAVED_STATE_KEY, JSON.stringify(state));
  } catch (err) {
    // ignore write error
  }
};

export const saveStateForUser = (user, key, state) => {
  const curState = loadState() || {};
  const newState = { [user]: curState[user] || {} };
  newState[user][key] = state;
  try {
    localStorage.setItem(
      SAVED_STATE_KEY,
      JSON.stringify(Object.assign({}, curState, newState))
    );
  } catch (err) {
    // ignore write error
  }
};
