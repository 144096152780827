import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { List } from "antd";
import { NavLink, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import Spinner from "../../Common/Spinner";
import Dropdown from "../../Common/Dropdown";
import Icon from "../../Common/Icon";
import messages from "./messages";
import {
  INVESTIGATION_STATE_ACTIVE,
  INVESTIGATION_STATE_INACTIVE,
  INVESTIGATION_STATE_OLD
} from "../constants";
import { MODE_NEW } from "../../EntityAdmin/constants";
import Button from "../../Common/Button";

const InvestigationList = ({
  loading = true,
  investigations = [],
  activeItem,
  sort,
  handleSortList,
  handleSetState
}) => {
  const { Item } = List;
  const { Meta } = Item;

  // Sort dropdown options
  const sortOptions = [
    {
      value: "status",
      text: "Status"
    },
    {
      value: "date",
      text: "Date"
    }
  ];

  // Get sort label from sort dropdown options.
  const getSortLabel = () => {
    let text = "";
    const activeOption = sortOptions.find(option => option.value === sort);
    if (activeOption) {
      text = activeOption.text;
    }
    return <FormattedMessage {...messages.sortBy} values={{ sort: text }} />;
  };

  // Status Dropdown
  const actions = (investigation, last_in_list) =>
    investigation.state === INVESTIGATION_STATE_OLD ? (
      <Button size="small" type="light">
        Old Export
      </Button>
    ) : (
      <Dropdown
        id={`investigation-status-dropdown--${investigation.id}`}
        menuOptions={[
          {
            value: INVESTIGATION_STATE_ACTIVE,
            text: <FormattedMessage {...messages.statusActive} />
          },
          {
            value: INVESTIGATION_STATE_INACTIVE,
            text: <FormattedMessage {...messages.statusInactive} />
          }
        ]}
        selected={investigation.state}
        btnText={
          investigation.state === INVESTIGATION_STATE_ACTIVE ? (
            <FormattedMessage {...messages.statusActive} />
          ) : (
            <FormattedMessage {...messages.statusInactive} />
          )
        }
        btnType="light-ghost"
        placement={last_in_list ? "topLeft" : "bottomLeft"}
        size="small"
        handleClick={value =>
          value !== investigation.state
            ? handleSetState(investigation, value)
            : null
        }
      />
    );

  const getStatusValue = ({ state }) =>
    Number(state === INVESTIGATION_STATE_ACTIVE);

  const getTimeValue = ({ modifiedAt = "", createdAt = "" }) =>
    parseInt(modifiedAt ? modifiedAt : createdAt, 10);

  return (
    <section
      className="list-panel investigation-list"
      data-length={investigations.length}
    >
      <List
        loading={loading}
        header={
          <div className="header">
            <Link
              to={`/investigations/${MODE_NEW}`}
              className="ant-btn ant-btn-primary ant-btn-sm ant-btn-has-icon"
            >
              <Icon type="plus-circle" />
              <FormattedMessage {...messages.newButton} />
            </Link>
            <div className="header-extras">
              <Dropdown
                id="list-sort"
                btnText={getSortLabel()}
                size="small"
                menuOptions={sortOptions}
                selected={sort}
                handleClick={value => handleSortList(value)}
                btnType="link"
              />
            </div>
          </div>
        }
        dataSource={[...investigations].sort((a, b) => {
          if (sort === "status") {
            return getStatusValue(b) - getStatusValue(a);
          } else {
            return getTimeValue(b) - getTimeValue(a);
          }
        })}
        renderItem={(item, i) => {
          const itemClasses = [
            "ant-list-item--block",
            { "ant-list-item-active": item.id === activeItem },
            {
              "ant-list-item-inactive":
                item.state === INVESTIGATION_STATE_INACTIVE
            }
          ];
          return (
            <Item
              key={item.id}
              className={classnames(itemClasses)}
              actions={[actions(item, i === investigations.length - 1)]}
            >
              <Spinner
                spinning={item.loading ? item.loading : false}
                theme="light"
              >
                <NavLink
                  to={`/investigations/${item.id}`}
                  className="ant-list-item-link"
                >
                  <Meta
                    title={item.name}
                    description={
                      <div className="item-last-modified">
                        <FormattedMessage
                          values={{
                            time: moment(getTimeValue(item)).format(
                              "M/DD/YYYY h:mm:ss A"
                            )
                          }}
                          {...messages.lastModified}
                        />
                      </div>
                    }
                  />
                </NavLink>
              </Spinner>
            </Item>
          );
        }}
      />
    </section>
  );
};

InvestigationList.propTypes = {
  loading: PropTypes.bool,
  investigations: PropTypes.array,
  sort: PropTypes.string,
  handleSortList: PropTypes.func,
  handleSetState: PropTypes.func,
  handlesetActiveInvestigation: PropTypes.func,
  activeItem: PropTypes.string
};

export default InvestigationList;
