import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Layout, Collapse, message } from "antd";
import {
  getInvestigations,
  getInvestigation,
  getInvestigationNotFound
} from "../../../selectors";
import * as investigationActions from "../../../actions/investigationActions";
import * as exportActions from "../../../actions/exportActions";
import * as utilityActions from "../../../actions/utilityActions";
import withData from "../../DataWrapper";
import InvestigationDetailPage from "../InvestigationDetailPage";
import EntityPlaceholder from "../../EntityAdmin/EntityPlaceholder";
import InvestigationList from "../InvestigationList";
import CameraSearch from "../../Cameras/CameraSearch";
import messages from "./messages";
import {
  INVESTIGATION_MODE_DOWNLOAD,
  INVESTIGATION_MODE_EDIT,
  INVESTIGATION_ID_NEW
} from "../constants";
import NotFoundPage from "../../NotFoundPage";

class InvestigationPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: props.investigationId !== INVESTIGATION_ID_NEW
    };
  }

  componentDidMount() {
    const { investigationId } = this.props;
    this.props.actions.fetchAllInvestigations(true);
    if (investigationId && investigationId !== INVESTIGATION_ID_NEW) {
      this.props.actions.fetchInvestigation(investigationId);
    } else {
      this.props.actions.fetchInvestigation();
    }
    if (investigationId === INVESTIGATION_ID_NEW) {
      this.props.actions.setOpenPanel("addCameras");
    }
    this.props.actions.fetchAllExports(true);
  }

  componentDidUpdate(prevProps) {
    const { investigationId, investigation } = this.props;
    if (
      this.props.investigations &&
      prevProps.investigationId !== this.props.investigationId
    ) {
      const id =
        this.props.investigationId === INVESTIGATION_ID_NEW
          ? null
          : this.props.investigationId;
      this.setState(
        {
          fetching: !!id
        },
        () => {
          this.props.actions.fetchInvestigation(id);
          this.props.investigationId === INVESTIGATION_ID_NEW &&
            this.props.actions.setOpenPanel("addCameras");
        }
      );
    } else if (
      this.state.fetching &&
      investigation &&
      investigation.id === investigationId
    ) {
      this.setState({ fetching: false });
    }

    if (
      this.props.investigations.message &&
      prevProps.investigations.message !== this.props.investigations.message
    ) {
      const { type = "info", text } = this.props.investigations.message;
      message[type](text);
    }
  }

  handleCollapse = (key = "") => this.props.actions.setOpenPanel(key);

  handleSetState = (investigation, state) =>
    this.props.actions.updateInvestigationState(investigation, state);

  render() {
    const { Content, Sider } = Layout;
    const { Panel } = Collapse;
    const {
      investigationId,
      pathname,
      investigations,
      investigation,
      cameras,
      handleCameraSearchSubmit,
      cameraSearch,
      actions,
      utility: { openPanel = "", showModal = "" },
      match: { params = {} },
      camerasLoading,
      notFound
    } = this.props;
    const { loading, items = [], sort, saving = false } = investigations;
    const {
      sortInvestigationList,
      addCamera,
      removeCamera,
      getThumbnails,
      updateInvestigation,
      deleteInvestigation,
      toggleModal
    } = actions;
    const activeKey = openPanel ? openPanel : "help";

    const invCameras =
      investigation && investigation.cameras
        ? investigation.cameras.filter(c => !!c)
        : [];

    const pageProps = {
      investigationId,
      investigation,
      pathname,
      handleSave: updateInvestigation,
      handleDelete: deleteInvestigation,
      toggleModal,
      showModal,
      handleRemoveCamera: removeCamera,
      saving,
      cameras: invCameras
    };

    const { investigationId: pathId, mode } = params;
    if (pathId) {
      if (mode === INVESTIGATION_MODE_EDIT) {
        pageProps.removeCameras = true;
      } else if (mode === INVESTIGATION_MODE_DOWNLOAD) {
        pageProps.showExports = true;
      }
    }

    return (
      <Content>
        <Helmet title="Investigations" />
        <Layout>
          <Sider width="375">
            <Collapse
              onChange={this.handleCollapse}
              defaultActiveKey={activeKey}
              activeKey={activeKey}
              accordion
            >
              <Panel header="Add Cameras" key="addCameras" showArrow={false}>
                <CameraSearch
                  addCamera={
                    invCameras.length === 4 ||
                    !(
                      mode === INVESTIGATION_MODE_EDIT ||
                      pathId === INVESTIGATION_ID_NEW
                    )
                      ? undefined
                      : addCamera
                  }
                  removeCamera={
                    invCameras.length === 4 ||
                    !(
                      mode === INVESTIGATION_MODE_EDIT ||
                      pathId === INVESTIGATION_ID_NEW
                    )
                      ? undefined
                      : camera => removeCamera(camera.id)
                  }
                  selectedCameras={invCameras.map(c => c.id)}
                  handleCameraSearchSubmit={handleCameraSearchSubmit}
                  cameras={cameras}
                  {...cameraSearch}
                  getThumbnails={getThumbnails}
                  camerasLoading={camerasLoading}
                />
              </Panel>
              <Panel
                header="List of Investigations"
                key="list"
                showArrow={false}
              >
                <InvestigationList
                  activeItem={investigationId}
                  loading={loading}
                  investigations={items}
                  sort={sort}
                  handleSortList={sortInvestigationList}
                  handleSetState={this.handleSetState}
                />
              </Panel>
              <Panel
                header="Help"
                key="help"
                showArrow={false}
                className="ant-collapse-item--invisible-header"
              >
                <p>
                  <FormattedMessage {...messages.helpTextAddCameras} />
                </p>
                <p>
                  <FormattedMessage {...messages.helpTextListViews} />
                </p>
              </Panel>
            </Collapse>
          </Sider>
          {notFound ? (
            <NotFoundPage />
          ) : (
            <Content className="investigation-page">
              {pathId ? (
                !this.state.fetching && (
                  <InvestigationDetailPage {...pageProps} />
                )
              ) : (
                <EntityPlaceholder
                  title="Investigations"
                  type="investigation"
                  pathBase="/investigations"
                />
              )}
            </Content>
          )}
        </Layout>
      </Content>
    );
  }
}

InvestigationPage.propTypes = {
  investigations: PropTypes.object,
  actions: PropTypes.object,
  cameraSearch: PropTypes.object,
  cameras: PropTypes.array,
  handleCameraSearchSubmit: PropTypes.func,
  utility: PropTypes.object,
  investigationId: PropTypes.string,
  investigation: PropTypes.object,
  match: PropTypes.object,
  camerasLoading: PropTypes.bool
};

InvestigationPage.defaultProps = {
  cameras: [],
  investigations: []
};

const mapStateToProps = state => {
  return {
    ...getInvestigations(state),
    investigation: getInvestigation(state),
    camerasLoading: state.views.loading,
    notFound: getInvestigationNotFound(state)
  };
};

export default withData(InvestigationPage, mapStateToProps, {
  ...investigationActions,
  ...exportActions,
  ...utilityActions
});
