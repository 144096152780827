import {
  TOGGLE_MODAL,
  SET_OPEN_PANEL,
  TOGGLE_WEB_SOCKET,
  TOGGLE_DIRECT_STREAMING,
  LOGOUT_SUCCESS,
  GET_PROMOTIONS,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_ERROR,
  GET_PROMOTIONS_ITEM_ERROR,
  GET_PROMOTIONS_ITEM_SUCCESS,
  GET_PROMOTIONS_ITEM
} from "../constants/actionTypes";

const initialState = {
  showModal: "",
  openPanel: "",
  enableWebsocket: true,
  enableDirectStreaming: true,
  content: {
    promotions: null,
    promotionsLayout: null,
    promotionsItems: {},
    extraMenuLinks: null,
    error: null,
    loading: true
  }
};

const utilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        showModal: state.showModal === action.modal ? "" : action.modal
      };

    case SET_OPEN_PANEL:
      return { ...state, openPanel: action.openPanel };

    case TOGGLE_WEB_SOCKET:
      return { ...state, enableWebsocket: action.enabled };

    case TOGGLE_DIRECT_STREAMING:
      return { ...state, enableDirectStreaming: action.enabled };

    case GET_PROMOTIONS:
      return {
        ...state,
        content: {
          ...state.content,
          promotions: null,
          promotionsLayout: null,
          extraMenuLinks: null,
          error: null,
          loading: true
        }
      };

    case GET_PROMOTIONS_SUCCESS:
      return {
        ...state,
        content: {
          ...state.content,
          promotions: action.promotions,
          promotionsLayout: action.promotionsLayout,
          extraMenuLinks: action.extraMenuLinks,
          error: null,
          loading: false
        }
      };

    case GET_PROMOTIONS_ERROR:
      return {
        ...state,
        content: {
          ...state.content,
          promotions: null,
          promotionsLayout: null,
          extraMenuLinks: null,
          error: action.error,
          loading: false
        }
      };

    case GET_PROMOTIONS_ITEM:
      return {
        ...state,
        content: {
          ...state.content,
          promotionsItems: {
            ...state.content.promotionsItems,
            [action.item]: {
              loading: true
            }
          }
        }
      };

    case GET_PROMOTIONS_ITEM_SUCCESS:
      return {
        ...state,
        content: {
          ...state.content,
          promotionsItems: {
            ...state.content.promotionsItems,
            [action.item]: {
              loading: false,
              error: null,
              content: action.content
            }
          }
        }
      };

    case GET_PROMOTIONS_ITEM_ERROR:
      return {
        ...state,
        content: {
          ...state.content,
          promotionsItems: {
            ...state.content.promotionsItems,
            [action.item]: {
              loading: false,
              error: action.error
            }
          }
        }
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default utilityReducer;
