import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const EmptyState = ({ children = null }) => {
  return (
    <div className="empty-state">
      {children ? (
        children
      ) : (
        <p>
          <FormattedMessage {...messages.defaultText} />
        </p>
      )}
    </div>
  );
};

EmptyState.propTypes = {
  text: PropTypes.node
};

export default EmptyState;
