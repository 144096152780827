import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import messages from "../messages";
import { PLAYER_MODE_PLAYBACK } from "../constants";

const CameraHeader = ({ name, mode }) => {
  const modeLabel =
    mode === PLAYER_MODE_PLAYBACK ? (
      <FormattedMessage {...messages.playback} />
    ) : (
      <FormattedMessage {...messages.live} />
    );

  return (
    <header className="header camera-header">
      <h2 className="camera-name">{name}</h2>
      {mode ? (
        <div className="header-extras camera-header-extras">{modeLabel}</div>
      ) : (
        ""
      )}
    </header>
  );
};

CameraHeader.propTypes = {
  name: PropTypes.string,
  mode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

CameraHeader.defaultProps = {
  name: "",
  mode: null
};

export default CameraHeader;
