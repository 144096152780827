import {
  FETCH_CUSTOM_VIEWS,
  FETCH_CUSTOM_VIEWS_SUCCESS,
  FETCH_CUSTOM_VIEWS_ERROR,
  UPDATE_VIEW,
  UPDATE_VIEW_SUCCESS,
  UPDATE_VIEW_ERROR,
  CREATE_VIEW,
  CREATE_VIEW_SUCCESS,
  CREATE_VIEW_ERROR,
  SAVING_VIEW,
  DELETE_VIEW,
  DELETE_VIEW_SUCCESS,
  DELETE_VIEW_ERROR,
  LOGOUT_SUCCESS
} from "../constants/actionTypes";
import { saveStateForUser } from "../store/localStorage";

export const initialState = {
  loading: true,
  error: null,
  views: [],
  customViewCounter: 0
};

const customViewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOM_VIEWS:
      return { ...state, loading: true, error: null };

    case FETCH_CUSTOM_VIEWS_SUCCESS:
      return {
        ...state,
        views: action.views,
        customViewCounter: action.customViewCounter,
        loading: false,
        error: null
      };

    case FETCH_CUSTOM_VIEWS_ERROR:
      return { ...state, loading: false, error: action.error };

    case SAVING_VIEW:
      return { ...state, saving: true, message: null };

    case UPDATE_VIEW: {
      const newState = {
        views: state.views.map(v => {
          if (v.id !== action.view.id) {
            return v;
          }
          return {
            ...v,
            ...action.view,
            updatedAt: Date.now()
          };
        }),
        customViewCounter: state.customViewCounter
      };
      saveStateForUser(action.username, "customViews", newState);
      return {
        ...state,
        ...newState
      };
    }

    case UPDATE_VIEW_SUCCESS:
      return {
        ...state,
        saving: false,
        message: {
          type: "success",
          text: `Updated view ${action.view.name}`
        }
      };

    case UPDATE_VIEW_ERROR:
      return {
        ...state,
        saving: false,
        message: {
          type: "error",
          text: `Error updating view ${action.view.name}`
        }
      };

    case CREATE_VIEW: {
      const newState = {
        views: state.views.concat({
          ...action.view,
          createdAt: Date.now(),
          updatedAt: Date.now()
        }),
        customViewCounter: state.customViewCounter + 1
      };
      saveStateForUser(action.username, "customViews", newState);
      return {
        ...state,
        ...newState
      };
    }

    case CREATE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        saving: false,
        message: {
          type: "success",
          text: `Created view ${action.view.name}`
        }
      };

    case CREATE_VIEW_ERROR:
      return {
        ...state,
        loading: false,
        saving: false,
        message: {
          type: "error",
          text: `Error creating view ${action.view.name}`
        }
      };

    case DELETE_VIEW: {
      const newState = {
        views: state.views.filter(v => v.id !== action.id),
        customViewCounter: state.customViewCounter
      };
      saveStateForUser(action.username, "customViews", newState);
      return {
        ...state,
        saving: true,
        ...newState
      };
    }

    case DELETE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        saving: false,
        message: {
          type: "success",
          text: `Deleted view ${action.view.name}`
        }
      };

    case DELETE_VIEW_ERROR:
      return {
        ...state,
        loading: false,
        saving: false,
        message: {
          type: "error",
          text: `Error deleting view ${action.view.name}`
        }
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default customViewsReducer;
