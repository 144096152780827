import React from "react";
import PropTypes from "prop-types";
import * as viewActions from "../../actions/viewActions";
import * as thumbnailActions from "../../actions/thumbnailActions";
import * as utilityActions from "../../actions/utilityActions";
import withAuthWrapper from "../AuthWrapper";

export default function(
  ComposedComponent,
  componentMapStatetoProps,
  actionCreators = {}
) {
  class DataWrapper extends React.Component {
    componentDidMount() {
      if (this.props.auth && this.props.auth.authenticated) {
        this.props.actions.fetchAllViews();
      }

      if (super.componentDidMount) {
        super.componentDidMount();
      }
    }

    componentDidUpdate(prevProps) {
      if (super.componentDidUpdate) {
        super.componentDidUpdate(prevProps);
      }

      /*if (this.props.thumbnails) {
        if (!prevProps.thumbnails || !prevProps.thumbnails.ids.length) {
          this.fetchThumbnails(this.props.thumbnails.ids);
        } else if (this.props.thumbnails.ids !== prevProps.thumbnails.ids) {
          this.fetchThumbnails(this.props.thumbnails.ids);
        }
      }*/
    }

    /*fetchThumbnails = ids =>
      ids.map(id => this.props.actions.fetchThumbnail(id));*/

    toggleModal = (modal = "") => {
      const {
        actions: { toggleModal },
        utility
      } = this.props;
      toggleModal(utility.showModal === modal ? "" : modal);
    };

    render() {
      const { match = {} } = this.props;
      const { params } = match;
      return (
        <ComposedComponent
          {...this.props}
          {...params}
          actions={this.props.actions}
          toggleModal={this.toggleModal}
        />
      );
    }
  }

  DataWrapper.propTypes = {
    match: PropTypes.object,
    actions: PropTypes.object,
    thumbnails: PropTypes.object
  };

  DataWrapper.defaultProps = {
    match: {}
  };

  const mapStateToProps = state => {
    const componentState = state ? componentMapStatetoProps(state) : {};
    const {
      router: { location }
    } = state;
    return {
      pathname: location ? location.pathname : "",
      thumbnails: state.thumbnails,
      utility: state.utility,
      ...componentState
    };
  };

  return withAuthWrapper(DataWrapper, mapStateToProps, {
    ...utilityActions,
    ...viewActions,
    ...thumbnailActions,
    ...actionCreators
  });
}
