import {
  CONNECT,
  CONNECT_ERROR,
  CONNECT_SUCCESS,
  CONNECT_REQUEST_SUCCESS,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS
} from "../constants/actionTypes";

export const initialState = {
  loading: false,
  connected: false,
  connection: {},
  authenticated: false,
  error: false,
  loginError: false,
  username: null,
  loggingOut: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONNECT:
      return { ...state, error: false, loading: true };

    case CONNECT_ERROR:
      return { ...state, error: action.error, loading: false };

    case CONNECT_SUCCESS:
      return {
        ...state,
        connected: true,
        error: false,
        connection: action.response,
        loading: false
      };

    case CONNECT_REQUEST_SUCCESS:
      return state;

    case LOGIN:
      return {
        ...state,
        error: false,
        loginError: false,
        loading: true,
        username: action.username
      };

    case LOGIN_ERROR:
      return {
        ...state,
        authenticated: false,
        error: action.error,
        loginError: action.error,
        loading: false,
        username: null
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        error: false,
        loginError: false,
        loading: false
      };

    case LOGOUT:
      return { ...state, error: false, loading: true, loggingOut: true };

    case LOGOUT_SUCCESS:
      return { ...initialState, loginError: state.loginError };

    default:
      return state;
  }
};

export default authReducer;
