import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "components.ViewsPage.title",
    defaultMessage: "Views"
  },
  titleNew: {
    id: "components.ViewsPage.titleNew",
    defaultMessage: "Untitled View"
  },
  newButton: {
    id: "components.ViewsPage.newButton",
    defaultMessage: "Add Cameras to New View"
  },
  addCamerasPanel: {
    id: "components.ViewsPage.addCamerasPanel",
    defaultMessage: "Add Cameras"
  },
  viewLinkButton: {
    id: "components.ViewsPage.viewLinkButton",
    defaultMessage: "View"
  },
  listPanel: {
    id: "components.ViewsPage.listPanel",
    defaultMessage: "List of Views"
  },
  editCamerasButton: {
    id: "components.ViewsPage.editCamerasButton",
    defaultMessage: "Change Cameras"
  },
  deleteButton: {
    id: "components.ViewsPage.deleteButton",
    defaultMessage: "Delete"
  },
  cancel: {
    id: "components.ViewsPage.cancel",
    defaultMessage: "Cancel"
  },
  saveOption: {
    id: "components.ViewsPage.saveOption",
    defaultMessage: "Save"
  },
  saveAsOption: {
    id: "components.ViewsPage.saveAsOption",
    defaultMessage: "Save As"
  },
  addCamera: {
    id: "components.ViewsPage.addCamera",
    defaultMessage: "Add Camera"
  },
  removeCamera: {
    id: "components.ViewsPage.removeCamera",
    defaultMessage: "Remove"
  },
  helpTextAddCameras: {
    id: "components.ViewsPage.helpTextAddCameras",
    defaultMessage:
      'Use "Add Cameras" to add or search for cameras for your custom view.'
  },
  helpTextListViews: {
    id: "components.ViewsPage.helpTextListViews",
    defaultMessage:
      'Use "List of Views" to access and manage your existing views. Add or remove cameras, ' +
      "delete, and save or rename your view with the controls that appear on the right."
  },
  deleteModalTitle: {
    id: "components.ViewsPage.deleteModalTitle",
    defaultMessage: "Delete View"
  },
  deleteModalText: {
    id: "components.ViewsPage.deleteModalText",
    defaultMessage: "Are you sure you want to delete this view?"
  },
  newViewButton: {
    id: "components.ViewsPage.newViewButton",
    defaultMessage: "Create New View"
  },
  saveModalTitle: {
    id: "components.ViewsPage.saveModalTitle",
    defaultMessage: "Save View"
  },
  saveAsModalTitle: {
    id: "components.ViewsPage.saveAsModalTitle",
    defaultMessage: "Save View As"
  },
  saveModalInputLabel: {
    id: "components.ViewsPage.saveModalInputLabel",
    defaultMessage: "View Name"
  }
});
