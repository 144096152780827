import XPMobileSDK from "../../utils/api";
import messages from "./messages";
export const PLAYER_MODE_LIVE = XPMobileSDK.library.VideoConnectionSignal.live;
export const PLAYER_MODE_PLAYBACK =
  XPMobileSDK.library.VideoConnectionSignal.playback;
export const SEEK_TYPE_TIME = "Time";
export const SEEK_TYPE_PREV_FRAME = "PrevFrame";
export const SEEK_TYPE_NEXT_FRAME = "NextFrame";
export const columnOptions = [
  {
    value: 2,
    text: messages.grid2
  },
  {
    value: 4,
    text: messages.grid4
  },
  {
    value: 6,
    text: messages.grid6
  }
];
