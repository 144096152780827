import { normalize } from "normalizr";
import {
  FETCH_ALL_VIEWS,
  FETCH_ALL_VIEWS_SUCCESS,
  FETCH_ALL_VIEWS_ERROR,
  FETCH_VIEW_RESPONSE,
  SET_LAYOUT_COLUMNS,
  REORDER_LAYOUT
} from "../constants/actionTypes";
import * as schema from "../utils/schemas";

export const fetchAllViews = (force = false) => {
  return (dispatch, getState, XPMobileSDK) => {
    const {
      views: { views = [] }
    } = getState();
    if (views.length && !force) {
      return Promise.resolve();
    }
    dispatch({ type: FETCH_ALL_VIEWS });

    XPMobileSDK.getAllViews(
      items => {
        if (!items.length) {
          dispatch(fetchAllViewsError({ message: "No views" }));
        }
        const { entities = {} } = normalize(items[0], schema.rootFolder);
        dispatch(fetchAllViewsSuccess(entities));
      },
      error => dispatch(fetchAllViewsError(error))
    );
  };
};

export const fetchAllViewsSuccess = ({ cameras = {}, views = {} }) => ({
  type: FETCH_ALL_VIEWS_SUCCESS,
  views: Object.values(views),
  cameras: Object.values(cameras)
});

export const fetchAllViewsError = error => ({
  type: FETCH_ALL_VIEWS_ERROR,
  error
});

export const fetchView = id => {
  return (dispatch, getState, XPMobileSDK) => {
    XPMobileSDK.getViews(
      id,
      response => {
        const { entities } = normalize(response, schema.view);
        const { cameras = {} } = entities;
        dispatch(fetchViewResponse(id, Object.values(cameras)));
      },
      error => {
        return dispatch(fetchViewResponse(id, [], error));
      }
    );
  };
};

export const fetchViewResponse = (id, cameras = [], error = null) => {
  return {
    type: FETCH_VIEW_RESPONSE,
    id,
    cameras,
    error
  };
};

export const setLayoutColumns = (layoutColumns = "") => ({
  type: SET_LAYOUT_COLUMNS,
  layoutColumns
});

export const reorderLayout = (folderId, camerasOrder) => (
  dispatch,
  getState
) => {
  dispatch({
    type: REORDER_LAYOUT,
    folderId,
    camerasOrder,
    username: getState().auth.username
  });
};
