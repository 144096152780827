import { defineMessages } from "react-intl";

export default defineMessages({
  aviPackage: {
    id: "components.InvestigationExport.aviPackage",
    defaultMessage: "AVI Package"
  },
  dbPackage: {
    id: "components.InvestigationExport.dbPackage",
    defaultMessage: "Database Package"
  },
  mkvPackage: {
    id: "components.InvestigationExport.mkvPackage",
    defaultMessage: "MKV Package"
  },
  exportFailed: {
    id: "components.InvestigationExport.exportFailed",
    defaultMessage: "Operation failed, please try again."
  },
  exportFailedError: {
    id: "components.InvestigationExport.exportFailedError",
    defaultMessage: "(Error code: {code})"
  },
  cancelConfirm: {
    id: "components.InvestigationExport.cancelConfirm",
    defaultMessage: "Are you sure you want to cancel?"
  },
  deleteConfirm: {
    id: "components.InvestigationExport.deleteConfirm",
    defaultMessage: "Are you sure you want to delete?"
  },
  notAvailable: {
    id: "components.InvestigationExport.notAvailable",
    defaultMessage: "Not Available"
  },
  btnStatusNone: {
    id: "components.InvestigationExport.btnStatusNone",
    defaultMessage: "Prepare"
  },
  btnStatusProcessing: {
    id: "components.InvestigationExport.btnStatusProcessing",
    defaultMessage: "Preparing..."
  },
  btnStatusRequesting: {
    id: "components.InvestigationExport.btnStatusRequesting",
    defaultMessage: "Requesting..."
  },
  btnStatusQueued: {
    id: "components.InvestigationExport.btnStatusQueued",
    defaultMessage: "Queued"
  },
  btnStatusLoading: {
    id: "components.InvestigationExport.btnStatusLoading",
    defaultMessage: "Loading..."
  },
  btnStatusDeleting: {
    id: "components.InvestigationExport.btnStatusDeleting",
    defaultMessage: "Deleting..."
  },
  btnStatusReady: {
    id: "components.InvestigationExport.btnStatusReady",
    defaultMessage: "Download"
  },
  btnConfirmYes: {
    id: "components.InvestigationExport.btnConfirmYes",
    defaultMessage: "Yes"
  },
  btnConfirmNo: {
    id: "components.InvestigationExport.btnConfirmNo",
    defaultMessage: "No"
  }
});
