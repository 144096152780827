import { defineMessages } from "react-intl";

export default defineMessages({
  editCamerasButton: {
    id: "components.EntityToolbar.editCamerasButton",
    defaultMessage: "Change Cameras"
  },
  downloadButton: {
    id: "components.EntityToolbar.downloadButton",
    defaultMessage: "Download"
  },
  deleteButton: {
    id: "components.EntityToolbar.deleteButton",
    defaultMessage: "Delete"
  },
  cancel: {
    id: "components.EntityToolbar.cancel",
    defaultMessage: "Cancel"
  },
  updateOption: {
    id: "components.EntityToolbar.updateOption",
    defaultMessage: "Update Current {type}"
  },
  saving: {
    id: "components.EntityToolbar.saving",
    defaultMessage: "Saving"
  },
  saveOption: {
    id: "components.EntityToolbar.saveOption",
    defaultMessage: "Save"
  },
  saveAsOption: {
    id: "components.EntityToolbar.saveAsOption",
    defaultMessage: "Save As New {type}"
  },
  addCamera: {
    id: "components.EntityToolbar.addCamera",
    defaultMessage: "Add Camera"
  },
  removeCamera: {
    id: "components.EntityToolbar.removeCamera",
    defaultMessage: "Remove Camera"
  },
  deleteModalTitle: {
    id: "components.EntityToolbar.deleteModalTitle",
    defaultMessage: "Delete {type}"
  },
  deleteModalText: {
    id: "components.EntityToolbar.deleteModalText",
    defaultMessage: "Are you sure you want to delete this {type}?"
  },
  titleNew: {
    id: "components.EntityToolbar.titleNew",
    defaultMessage: "Untitled {type}"
  }
});
