import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import moment from "moment";
import configureStore, { history } from "./store/configureStore";
import Root from "./components/Root";
import "./assets/styles/styles.less";
require("./favicon.ico");
import XPMobileSDK from "./utils/api";

require("dotenv").config();
import "./components/XPMobileSdkLib/VideoStream/main";

const store = configureStore();
moment.locale("en");

// need to add this because the new sdk doesn't add it, but still references it
Date.daysToSeconds = days => days * 24 * 60 * 60;

render(
  <AppContainer>
    <Root store={store} history={history} XPMobileSDK={XPMobileSDK} />
  </AppContainer>,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept("./components/Root/index.js", () => {
    const NewRoot = require("./components/Root/index.js").default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById("app")
    );
  });
}
