import React from "react";
import PropTypes from "prop-types";
import { Button, Popover, Switch } from "antd";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const SettingsPopover = ({
  websocketsEnabled,
  handleToggleWebsockets,
  directStreamingEnabled,
  handleToggleDirectStreaming
}) => {
  const settingsPopover = (
    <div className="settings-popover">
      <h2>Websockets</h2>
      <p>
        <FormattedMessage {...messages.websocketsHelpText} />
      </p>
      <p>
        <FormattedMessage {...messages.websocketsSwitchLabel} />
      </p>
      <Switch checked={websocketsEnabled} onChange={handleToggleWebsockets} />
      <h2>Direct Streaming</h2>
      <p>
        <FormattedMessage {...messages.directStreamingHelpText} />
      </p>
      <p>
        <FormattedMessage {...messages.directStreamingSwitchLabel} />
      </p>
      <Switch
        checked={directStreamingEnabled}
        onChange={handleToggleDirectStreaming}
      />
    </div>
  );

  return (
    <Popover content={settingsPopover} trigger="click">
      <Button type="link">
        <FormattedMessage {...messages.buttonText} />
      </Button>
    </Popover>
  );
};

SettingsPopover.propTypes = {
  websocketsEnabled: PropTypes.bool,
  handleToggleWebsockets: PropTypes.func,
  directStreamingEnabled: PropTypes.bool,
  handleToggleDirectStreaming: PropTypes.func
};

export default SettingsPopover;
