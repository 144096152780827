import { schema } from "normalizr";

export const camera = new schema.Entity(
  "cameras",
  {},
  {
    idAttribute: "Id",
    processStrategy: (value, { id: viewId = "" }) => {
      const {
        /* eslint-disable no-unused-vars */
        _getId,
        _idAttribute,
        _key,
        _mergeStrategy,
        _processStrategy,
        schema,
        ...props
      } = value;
      /* eslint-enable no-unused-vars */
      const capabilityProps = [
        "exportAvi",
        "exportDatabase",
        "exportJpeg",
        "live",
        "playback",
        "ptz",
        "preset",
        "sequences"
      ];
      const camera = {};
      const capabilities = {};
      Object.keys(props).map(prop => {
        // Camel-case props.
        const key = _.camelCase(prop);
        let propValue = props[prop];
        // Turn "Yes"/"No" string into boolean.
        if (
          typeof propValue === "string" &&
          (propValue.toLowerCase() === "yes" ||
            propValue.toLowerCase() === "no")
        ) {
          propValue = propValue.toLowerCase() === "yes";
        }

        if (capabilityProps.indexOf(key) !== -1) {
          capabilities[key] = propValue;
        } else {
          camera[key] = propValue;
        }
      });
      return { ...camera, viewId, capabilities };
    }
  }
);

export const views = new schema.Entity(
  "views",
  {
    items: [camera]
  },
  {
    idAttribute: "Id",
    processStrategy: (value, { Id: folderId = "", Name: name = "" }) => {
      const {
        Id: id = "",
        Type: type,
        Name: viewName = "",
        Items: items = []
      } = value;
      return { id, type, viewName, items, folderId, name };
    }
  }
);

export const folder = new schema.Entity("folders", {}, { idAttribute: "Id" });

export const item = new schema.Array(
  {
    folders: folder,
    views: views
  },
  entity => {
    return entity.Type ? `${entity.Type.toLowerCase()}s` : "";
  }
);
folder.define({
  Items: item
});

export const investigation = new schema.Entity(
  "investigations",
  {
    items: [camera]
  },
  {
    idAttribute: "Id",
    processStrategy: value => {
      const investigation = {};
      Object.keys(value).map(
        key => (investigation[_.camelCase(key)] = value[key])
      );

      return investigation;
    }
  }
);

export const _export = new schema.Entity(
  "export",
  {},
  {
    idAttribute: "ExportId",
    processStrategy: item => {
      const export_ = {};
      Object.keys(item).forEach(key => {
        if (key === "Type") {
          item[key] = _.lowerCase(item[key]);
        }
        export_[_.camelCase(key)] = item[key];
      });

      if (export_.type === "aggregated") {
        const parts = export_.name.toLowerCase().split(".");
        const type = parts.slice(-2)[0];
        if (type && EXPORT_TYPES[type]) {
          export_.aggregateType = type;
        }
      }

      export_.updatedAt = new Date();
      return export_;
    }
  }
);

export const exports = [_export];

export const items = new schema.Entity(
  "items",
  { Items: item },
  { idAttribute: "Id" }
);
export const rootFolder = { Items: [folder] };
export const view = { subViews: [camera] };
export const investigations = [investigation];

export const ptzActions = {
  zoom: {
    ["in"]: "ZoomIn",
    out: "ZoomOut"
  },
  move: {
    up: "Up",
    down: "Down",
    left: "Left",
    right: "Right",
    up_left: "UpLeft",
    up_right: "UpRight",
    down_left: "DownLeft",
    down_right: "DownRight"
  },
  home: "Home"
};

export const ptzMoveArgs = {
  Up: { GestureXPercent: 0, GestureYPercent: 2 },
  Down: { GestureXPercent: 0, GestureYPercent: -2 },
  Left: { GestureXPercent: -2, GestureYPercent: 0 },
  Right: { GestureXPercent: 2, GestureYPercent: 0 },
  UpLeft: { GestureXPercent: -2, GestureYPercent: 2 },
  UpRight: { GestureXPercent: 2, GestureYPercent: 2 },
  DownLeft: { GestureXPercent: -2, GestureYPercent: -2 },
  DownRight: { GestureXPercent: 2, GestureYPercent: -2 },
  noop: { GestureXPercent: 0, GestureYPercent: 0 }
};

export const EXPORT_TYPES = {
  avi: "exportAvi",
  // db: "exportDatabase", //disable db exports
  mkv: "exportMkv"
};
