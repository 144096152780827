import { createSelector } from "reselect";
import {
  INVESTIGATION_ID_NEW,
  INVESTIGATION_MODE_EDIT,
  INVESTIGATION_MODE_DOWNLOAD,
  INVESTIGATION_STATE_OLD
} from "../components/Investigations/constants";
import { VIEW_ID_NEW, VIEW_MODE_EDIT } from "../components/Views/constants";

const getAllViewsData = state => (state ? state.views : {});
const getViewData = state => (state ? state.view : {});
const getCustomViewsData = state => (state ? state.customViews : {});
const getCustomViewData = state => (state ? state.customView : {});
const getThumbnails = state => (state ? state.thumbnails : {});
const getPlayer = state => (state ? state.player : {});
const getAllInvestigationsData = state => (state ? state.investigations : {});
const getInvestigationData = state => (state ? state.investigation : {});
const getExportsData = state => (state ? state.exports : {});
const getPathname = state => (state ? state.router.location.pathname : {});

const getInvestigationId = createSelector(
  [getPathname],
  pathname => {
    if (!pathname.startsWith("/investigations")) {
      return "";
    }
    return pathname
      .split("/")
      .filter(
        part =>
          part &&
          ![
            "investigations",
            INVESTIGATION_ID_NEW,
            INVESTIGATION_MODE_EDIT,
            INVESTIGATION_MODE_DOWNLOAD
          ].includes(part)
      )[0];
  }
);

const getCustomViewId = createSelector(
  [getPathname],
  pathname => {
    if (!pathname.startsWith("/views")) {
      return "";
    }
    return pathname
      .split("/")
      .filter(
        part => part && !["views", VIEW_ID_NEW, VIEW_MODE_EDIT].includes(part)
      )[0];
  }
);

export const getViewsData = createSelector(
  [getAllViewsData],
  viewsData => {
    const { views = [], cameras = [] } = viewsData;
    return { views, cameras };
  }
);

export const getCameras = createSelector(
  [getViewsData, getThumbnails],
  (viewsData, thumbnails) => {
    const { cameras } = viewsData;
    const { items = [] } = thumbnails;
    return cameras.map(camera => {
      const thumbnail = items.find(item => item.id === camera.id);
      return { ...camera, ...thumbnail };
    });
  }
);

export const getViews = createSelector(
  [getViewsData, getCameras],
  (viewsData, cameras) => {
    const { views } = viewsData;
    return views.map(view => {
      const viewThumbnails = view.items.map(cameraId => {
        return cameras.find(item => item.id === cameraId);
      });
      return { ...view, cameras: viewThumbnails };
    });
  }
);

export const getView = createSelector(
  [getViews, getViewData],
  (views, viewData) => {
    if (views && viewData) {
      const { id, cameras } = viewData;
      const view = views.find(item => item.id === id);
      if (view && view.cameras) {
        const viewCameras = cameras.map(camera =>
          view.cameras.find(item => item.id === camera.id)
        );
        return { ...view, ...viewData, cameras: viewCameras };
      }
    }
  }
);

export const getInvestigations = createSelector(
  [getAllInvestigationsData, getCameras, getExportsData],
  (investigationsData, cameraData, exportsData) => {
    const {
      investigations = [],
      cameras: investigationCameras,
      ...data
    } = investigationsData;
    const investigationItems = investigations
      .filter(i => {
        if (i.state !== INVESTIGATION_STATE_OLD) return true;
        const id = i.aviExportId || i.dbExportId || i.mkvExportId;
        if (!id) return true;
        const exp = exportsData.exports.find(e => e.exportId === id);
        if (!exp) return false;
        const { cameraId, investigationId, startTime, endTime } = exp;
        const inv = investigations.find(
          j =>
            i.id !== j.id &&
            (j.id === investigationId ||
              (j.items.includes(cameraId) &&
                new Date(j.endTime).getTime() === new Date(endTime).getTime() &&
                new Date(j.startTime).getTime() ===
                  new Date(startTime).getTime()))
        );
        return !inv;
      })
      .map(investigation => {
        const { modifiedAt, createdAt, startTime, endTime } = investigation;
        const cameras = investigation.items.map(item => {
          const camera = cameraData.find(camera => camera.id === item);
          const investigationCamera = investigationCameras.find(
            camera => camera.id === item
          );
          return { ...investigationCamera, ...camera };
        });
        return {
          ...investigation,
          cameras,
          modifiedAt: Number(modifiedAt),
          createdAt: Number(createdAt),
          startTime: Number(startTime),
          endTime: Number(endTime)
        };
      });

    return {
      investigations: { items: investigationItems, ...data },
      cameras: cameraData
    };
  }
);

export const getInvestigation = createSelector(
  [getInvestigations, getInvestigationData, getCameras],
  (investigations, investigationData, cameraData) => {
    if (investigations && investigationData) {
      const { id, items } = investigationData;
      const investigation = id
        ? investigations.investigations.items.find(item => item.id === id)
        : investigationData;

      if (!investigation) {
        return investigationData;
      }

      const investigationCameras = items || investigation.items || [];

      if (investigationCameras.length) {
        const cameras = investigationCameras
          .map(item => cameraData.find(camera => camera.id === item))
          .filter(c => !!c);
        return {
          ...investigation,
          ...investigationData,
          cameras,
          items: cameras.map(c => c && c.id)
        };
      }

      return {
        ...investigation,
        cameras: []
      };
    }
  }
);

export const getExportsByCamera = createSelector(
  [getInvestigation, getExportsData],
  (investigation, exportsData) => {
    if (
      !exportsData ||
      !exportsData.exports ||
      exportsData.exports.length === 0 ||
      !investigation ||
      !investigation.id ||
      investigation.id === INVESTIGATION_ID_NEW
    ) {
      return {};
    }
    const cameras = (investigation.cameras || []).filter(c => c && c.id);
    const exports = {};
    cameras.forEach(c => {
      if (!c || !c.id) {
        return;
      }
      exports[c.id] = {};
    });

    const getStatus = (id, action) => {
      if (!exportsData[action][id]) {
        return {};
      }
      const status = { [action]: exportsData[action][id].loading };
      if (exportsData[action][id].error) {
        status.error = exportsData[action][id].error;
      }
      return status;
    };

    exportsData.exports.forEach(e => {
      if (
        !exports[e.cameraId] ||
        (e.investigationId !== investigation.id &&
          !(
            (e.type === "avi" || e.type === "mkv") &&
            new Date(e.startTime).getTime() === investigation.startTime &&
            new Date(e.endTime).getTime() === investigation.endTime &&
            cameras.findIndex(c => c.id === e.cameraId) !== -1
          ))
      ) {
        return;
      }
      const type = e.type === "aggregated" ? e.aggregateType : e.type;

      const old = exports[e.cameraId][type];
      if (
        !old ||
        (new Date(old.queuedTime).getTime() <
          new Date(e.queuedTime).getTime() &&
          e.type !== "aggregated")
      ) {
        exports[e.cameraId][type] = {
          ...e,
          ...getStatus(e.exportId, "fetching"),
          ...getStatus(e.exportId, "deleting")
        };
      }
    });
    exportsData.queueing.forEach(p => {
      if (
        !exports[p.cameraId] ||
        cameras.findIndex(c => c.id === p.cameraId) === -1
      ) {
        return;
      }
      const type = p.type === "aggregated" ? p.aggregateType : p.type;
      const old = exports[p.cameraId][type];
      if (
        !old ||
        new Date(old.queuedTime).getTime() < new Date(p.queuedTime).getTime()
      ) {
        exports[p.cameraId][type] = {
          ...exports[p.cameraId][type],
          queueing: true,
          error: p.error || (old ? old.error : undefined)
        };
      }
    });

    return exports;
  }
);

export const getCamera = createSelector(
  [getView, getPlayer],
  (view, player) => {
    if (!view || !player) {
      return null;
    }

    const { id = null } = player;
    if (!id) {
      return null;
    }

    const { cameras = [{}] } = view;
    return cameras.find(item => item.id === id);
  }
);

export const getCameraOrViewNotFound = createSelector(
  [getAllViewsData, getViewData, getPlayer],
  (views, viewData, player) => {
    if (views && !views.loading && !views.error && viewData) {
      if (
        !viewData.loading &&
        (views.views.length === 0 ||
          !views.views.find(v => v.id === viewData.id))
      ) {
        return true;
      }
      if (
        !!player &&
        !!player.id &&
        !viewData.loading &&
        (views.cameras.length === 0 ||
          !views.cameras.find(c => c.id === player.id))
      ) {
        return true;
      }
    }
    return false;
  }
);

export const getInvestigationNotFound = createSelector(
  [getAllInvestigationsData, getInvestigationData, getInvestigationId],
  (investigations, investigation, investigationId) =>
    !!investigations &&
    !investigations.loading &&
    !investigations.error &&
    !!investigationId &&
    !!investigation &&
    !investigation.loading &&
    (investigations.investigations.length === 0 ||
      !investigations.investigations.find(v => v.id === investigationId))
);

export const getCustomViewNotFound = createSelector(
  [getCustomViewsData, getCustomViewId],
  (views, viewId) =>
    !!views &&
    !views.loading &&
    !views.error &&
    !!viewId &&
    (views.views.length === 0 || !views.views.find(v => v.id === viewId))
);

export const getCustomView = createSelector(
  [getCustomViewsData, getCustomViewData, getCameras],
  (viewsData, viewData, cameras) => {
    if (viewsData && viewData) {
      const viewThumbnails = viewData.cameras
        .map(cameraId => {
          return cameras.find(item => item.id === cameraId);
        })
        .filter(c => !!c);
      return {
        ...viewData,
        ...(viewsData.views.find(v => v.id === viewData.id) || {}),
        cameras: viewThumbnails,
        layoutColumns: viewData.layoutColumns
      };
    }
  }
);
