import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "components.HomePage.title",
    defaultMessage: "VideoWatch Web Client"
  },
  readMoreLink: {
    id: "components.HomePage.readMoreLink",
    defaultMessage: "Learn More"
  }
});
