import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Input } from "antd";
import DomPurify from "dompurify";
import classnames from "classnames";
import messages from "./messages";

const { Item } = Form;

const SearchForm = Form.create({
  mapPropsToFields(props) {
    const { search } = props;
    const { value = "" } = search;
    return {
      search: Form.createFormField({
        value,
        ...search
      })
    };
  }
})(props => {
  const {
    intl,
    form,
    collapsed = true,
    handleSearchSubmit,
    handleSearchSubmitClick,
    theme = "light",
    size = "default",
    onChange,
    searchTerm
  } = props;
  const { getFieldDecorator, getFieldValue } = form;
  const searchInput = (
    <Input.Search
      placeholder={intl.formatMessage(messages.inputPlaceholder)}
      enterButton="Search"
      onSearch={(value, e) =>
        handleSearchSubmitClick(e, collapsed, DomPurify.sanitize(value))
      }
      className={collapsed ? "is-collapsed" : "not-collapsed"}
      onChange={e => onChange(e.target.value)}
      onBlur={e => {
        if (!e.target.value && searchTerm) {
          onChange(searchTerm);
        }
      }}
    />
  );
  const sizeClass = size => {
    if (size === "small") {
      return "sm";
    }
    if (size === "large") {
      return "lg";
    }

    return "";
  };
  const classNames = classnames(
    "search-form",
    { [`search-form-size-${sizeClass(size)}`]: sizeClass(size) },
    `search-form-theme-${theme}`
  );

  return (
    <Form
      onSubmit={e =>
        handleSearchSubmit(e, DomPurify.sanitize(getFieldValue("search")))
      }
      className={classNames}
    >
      <h3 className="form-title sr-only">
        <FormattedMessage {...messages.title} />
      </h3>
      <Item
        label={intl.formatMessage(messages.inputPlaceholder)}
        className="ant-form-item-label-hidden"
      >
        {getFieldDecorator("search", { rules: [{ required: true }] })(
          searchInput
        )}
      </Item>
    </Form>
  );
});

SearchForm.propTypes = {
  collapsed: PropTypes.bool,
  handleSubmit: PropTypes.func,
  searchTerm: PropTypes.string,
  theme: PropTypes.string,
  size: PropTypes.string,
  search: PropTypes.object,
  onChange: PropTypes.func
};

class SearchFormWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      search: { value = "" }
    } = props;
    this.state = {
      value
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (!!prevProps.search && !this.props.search) ||
      (!prevProps.search && !!this.props.search) ||
      (prevProps.search &&
        this.props.search &&
        prevProps.search.value !== this.props.search.value)
    ) {
      const value = this.props.search ? this.props.search.value : "";
      value !== this.state.value &&
        this.setState({
          value
        });
    }

    if (prevProps.collapsed && !this.props.collapsed) {
      const el = document.getElementById("search");
      if (el) {
        el.focus();
      }
    }
  }

  onChange = value => {
    value !== this.state.value &&
      this.setState({
        value
      });
  };

  render() {
    const { search = {}, ...props } = this.props;
    return (
      <SearchForm
        searchTerm={search.value}
        {...props}
        search={{ ...search, value: this.state.value }}
        onChange={this.onChange}
      />
    );
  }
}

SearchFormWrapper.propTypes = {
  collapsed: PropTypes.bool,
  handleSubmit: PropTypes.func,
  searchTerm: PropTypes.string,
  theme: PropTypes.string,
  size: PropTypes.string,
  search: PropTypes.object
};

export default injectIntl(SearchFormWrapper);
