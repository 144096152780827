import React from "react";
import PropTypes from "prop-types";
import { Dropdown as AntDropdown, Button, Menu } from "antd";
import Icon from "../Icon";

const Dropdown = ({
  id,
  menuOptions = [],
  selected = "",
  btnText = "",
  size = "default",
  handleClick = null,
  btnType = "light",
  disabled = false,
  placement = "bottomLeft"
}) => {
  const toggleActiveClass = visible => {
    const elem = document.getElementById(id);
    if (!elem) {
      return false;
    }

    document.querySelectorAll(".dropdown-container").forEach(e => {
      e.classList.remove("active");
    });

    if (visible) {
      elem.classList.add("active");
    } else {
      elem.classList.remove("active");
    }
  };

  const handleItemClick = value => {
    toggleActiveClass(false);
    if (handleClick) {
      handleClick(value);
    }
  };

  const sizeClass = () => {
    switch (size) {
      case "large":
        return "lg";

      case "small":
        return "sm";

      default:
        return "base";
    }
  };

  // Returns the dropdown trigger button text value. Defaults to the text of the
  // currently selected option.
  const getBtnText = () => {
    if (btnText) {
      return btnText;
    }

    const activeOption = menuOptions.filter(
      option => option.value === selected
    );
    if (activeOption.length) {
      return activeOption[0].text;
    }
  };

  return (
    <div
      className={`dropdown-container dropdown-container-${sizeClass()}`}
      id={id}
    >
      <AntDropdown
        overlay={
          <Menu disabled={disabled} selectedKeys={[selected.toString()]}>
            {menuOptions.map(option => {
              return (
                <Menu.Item key={option.value}>
                  <Button
                    type="link"
                    size={size}
                    onClick={() => handleItemClick(option.value)}
                  >
                    {option.text}
                  </Button>
                </Menu.Item>
              );
            })}
          </Menu>
        }
        disabled={disabled}
        trigger={["click"]}
        overlayClassName={`ant-dropdown-${sizeClass()}`}
        getPopupContainer={() => document.getElementById(id)}
        onVisibleChange={visible => toggleActiveClass(visible)}
        placement={placement}
      >
        <Button type={btnType} size={size}>
          <span className="btn-text">{getBtnText()}</span>
          <Icon type="down-arrow" />
        </Button>
      </AntDropdown>
    </div>
  );
};

Dropdown.propTypes = {
  id: PropTypes.string,
  btnText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  menuOptions: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  handleClick: PropTypes.func,
  btnType: PropTypes.string,
  disabled: PropTypes.bool,
  placement: PropTypes.string
};

export default Dropdown;
