import {
  SEARCH_CAMERAS,
  GET_SEARCH_CAMERAS_RESULTS,
  LOGOUT_SUCCESS
} from "../constants/actionTypes";

const initialState = {
  searchTerm: "",
  results: [],
  loading: false
};

const cameraSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CAMERAS:
      return { ...state, searchTerm: action.searchTerm, loading: true };

    case GET_SEARCH_CAMERAS_RESULTS:
      return { ...state, results: action.results, loading: false };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default cameraSearchReducer;
