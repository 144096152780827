import React from "react";
import PropTypes from "prop-types";
import Icon from "../../Common/Icon";
import { Button } from "antd";
import { columnOptions } from "../constants";

const CameraGridColumns = ({ active, onSelect }) => (
  <div className="column-options">
    {columnOptions.map(option => (
      <Button
        key={option.value}
        type="link"
        onClick={() => onSelect(option.value)}
        className={option.value === active ? "active" : ""}
      >
        <Icon type={`grid-${option.value}`} title={option.text} />
      </Button>
    ))}
  </div>
);

CameraGridColumns.propTypes = {
  active: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default CameraGridColumns;
