import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import Icon from "../../Common/Icon";
import messages from "../messages";

const ToolbarButton = ({
  button,
  onClick,
  disabled,
  label = null,
  href,
  filename
}) => {
  const props = {
    key: button,
    className: `player-action-btn btn-${button}`,
    type: "link-alt",
    onClick,
    disabled
  };
  switch (button) {
    case "live":
      return (
        <Button {...props} className={`btn-${button}`} type="link">
          <Icon type="live" />
          <FormattedMessage {...messages.viewLive} />
        </Button>
      );

    case "playback":
      return (
        <Button {...props} className={`btn-${button}`} type="link">
          <Icon type="play" />
          <FormattedMessage {...messages.playback} />
        </Button>
      );

    case "ptz":
      return (
        <Button {...props} className={`btn-${button}`} type="link">
          <Icon type="movement-controls" />
          <FormattedMessage {...messages.cameraControls} />
        </Button>
      );

    case "ptzExit":
      return (
        <Button {...props} className={`btn-${button}`} type="link">
          <Icon type="exit" />
          <FormattedMessage {...messages.exit} />
        </Button>
      );

    case "speed":
      return (
        <Button {...props} shape="circle">
          <span className="speed-value">{label}</span>
        </Button>
      );

    case "skipBack":
      return (
        <Button {...props}>
          <Icon type="skip-back" title={{ ...messages.skipBack }} />
        </Button>
      );

    case "reverse":
      return (
        <Button {...props}>
          <Icon type="reverse" title={{ ...messages.reverse }} />
        </Button>
      );

    case "pause":
      return (
        <Button {...props}>
          <Icon type="pause" title={{ ...messages.pause }} />
        </Button>
      );

    case "skipForward":
      return (
        <Button {...props}>
          <Icon type="skip" title={{ ...messages.skipForward }} />
        </Button>
      );

    case "prevFrame":
      return (
        <Button {...props}>
          <Icon type="prev" title={{ ...messages.prevFrame }} />
        </Button>
      );

    case "play":
      return (
        <Button {...props}>
          <Icon type="play" title={{ ...messages.play }} />
        </Button>
      );

    case "nextFrame":
      return (
        <Button {...props}>
          <Icon type="next" title={{ ...messages.nextFrame }} />
        </Button>
      );

    case "snapshot":
      return (
        <a
          key={button}
          className={`btn-${button}`}
          href={href}
          download={`${filename || "image"}.png`}
        >
          <Button type="link">
            <Icon type="camera" />
            <FormattedMessage {...messages.snapshot} />
          </Button>
        </a>
      );

    default:
      return null;
  }
};

ToolbarButton.propTypes = {
  button: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filename: PropTypes.string,
  href: PropTypes.string
};

export default ToolbarButton;
