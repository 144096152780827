import React from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "../Breadcrumbs";

const PageHeader = ({
  title,
  showTitle = false,
  children = null,
  breadcrumbs = []
}) => {
  const pageTitle = (
    <h1 className={`page-title ${showTitle ? "" : "sr-only"}`}>{title}</h1>
  );

  if (!showTitle && !children && !breadcrumbs) {
    return pageTitle;
  }

  return (
    <header
      className={`header page-header page-header--title-${
        showTitle ? "visible" : "hidden"
      }`}
    >
      {breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : null}
      {pageTitle}
      {children ? <div className="header-extras">{children}</div> : null}
    </header>
  );
};

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showTitle: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node
};

export default PageHeader;
