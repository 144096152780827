import CamerasPage from "../components/Cameras/CamerasPage";
import CameraViewPage from "../components/Cameras/CameraViewPage";
import CameraPlayerPage from "../components/Cameras/CameraPlayerPage";
import InvestigationPage from "../components/Investigations/InvestigationPage";
import ViewsPage from "../components/Views/ViewsPage";

const routes = [
  {
    path: "/cameras/:viewId/:cameraId",
    text: "Camera",
    component: CameraPlayerPage
  },
  {
    path: "/cameras/:viewId",
    text: "Cameras",
    component: CameraViewPage
  },
  {
    path: "/cameras",
    text: "Cameras",
    exact: true,
    breadcrumbText: "Home",
    component: CamerasPage,
    menu: true
  },
  {
    path: "/investigations/:investigationId/:mode?",
    text: "Investigation",
    component: InvestigationPage
  },
  {
    path: "/investigations",
    text: "Investigations",
    exact: true,
    component: InvestigationPage,
    menu: true
  },
  {
    path: "/views/:viewId/:mode?",
    text: "View",
    component: ViewsPage
  },
  {
    path: "/views",
    text: "Views",
    exact: true,
    component: ViewsPage,
    menu: true
  }
];

export default routes;
