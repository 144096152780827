import { parse, stringify } from "query-string";

export const getQuery = (queryStr = window.location.search) => parse(queryStr);

export const getSearch = queryStr => getQuery(queryStr).search;

export const setSearch = (term, queryStr) => {
  const query = getQuery(queryStr);
  query.search = term;
  const search = stringify(query);
  return search ? `?${search}` : "";
};
