let ENV_ERROR_EMITTED = false;

export const getEnvVar = (name, def) => {
  try {
    return process.env[name] || def;
  } catch {
    !ENV_ERROR_EMITTED &&
      // eslint-disable-next-line no-console
      console.debug(`Couldn't load config from env, using default values.`);
    ENV_ERROR_EMITTED = true;
    return def;
  }
};
