import { defineMessages } from "react-intl";

export default defineMessages({
  error: {
    id: "components.ErrorModal.error",
    defaultMessage: "Error"
  },
  message: {
    id: "components.ErrorModal.message",
    defaultMessage: "Something went wrong, please refresh the page."
  },
  refresh: {
    id: "components.ErrorModal.refresh",
    defaultMessage: "Refresh"
  }
});
