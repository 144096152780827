const {
  XPMobileSDK: XPMobileSDK_,
  XPMobileSDKSettings: XPMobileSDKSettings_
} = require("XPMobileSDK.library.js");
import { getEnvVar } from "./env";

export const XPMobileSDKSettings = XPMobileSDKSettings_;

export const getResponseError = res => {
  if (!res) {
    return null;
  }
  if (res.code != null && res.message != null) {
    return { code: res.code, message: res.message };
  }
  if (res.response) {
    if (res.response.error) {
      return res.response.error;
    }
    if (res.response.isError) {
      return {
        code: res.response.errorCode,
        message: res.response.errorString
      };
    }
  }
  return null;
};

export const getResponseData = res =>
  !res || !res.response || !res.response.outputParameters
    ? null
    : res.response.outputParameters;

const API_REQUEST_CHALLENGES_RETRY_LIMIT = getEnvVar(
  "API_REQUEST_CHALLENGES_RETRY_LIMIT",
  3
);
const API_REQUEST_CHALLENGES_RETRY_INTERVAL = getEnvVar(
  "API_REQUEST_CHALLENGES_RETRY_INTERVAL",
  5000
);

export const ENABLE_CHAP_SUPPORT =
  getEnvVar("ENABLE_CHAP_SUPPORT", "false") === "true";

export const wrapSendCommand = XPMobileSDK => {
  if (!XPMobileSDK.library.Connection) {
    XPMobileSDK.library.Connection = new Connection();
  }

  if (!ENABLE_CHAP_SUPPORT) {
    return;
  }

  const sendCommand = XPMobileSDK.library.Connection.sendCommand;
  XPMobileSDK.library.Connection.sendCommand = function(
    commandName,
    requestParams,
    options,
    successCallback,
    failCallback
  ) {
    return sendCommand(
      commandName,
      requestParams,
      options,
      successCallback,
      function(error) {
        if (error instanceof XMLHttpRequest) {
          return;
        }
        const err = getResponseError(error);
        if (err && `${err.code}` === "19") {
          let count = 0;
          const retry = function(err1) {
            count++;
            if (count <= API_REQUEST_CHALLENGES_RETRY_LIMIT) {
              sendCommand(
                commandName,
                requestParams,
                options,
                successCallback,
                function(err2) {
                  setTimeout(function() {
                    retry(err2);
                  }, API_REQUEST_CHALLENGES_RETRY_INTERVAL);
                }
              );
            } else {
              return failCallback && failCallback(err1);
            }
          };
          retry(error);
        } else {
          return failCallback && failCallback(error);
        }
      }
    );
  };
};

wrapSendCommand(XPMobileSDK_);

export default XPMobileSDK_;
