import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class CameraVideoCanvas extends PureComponent {
  constructor(props) {
    super(props);

    this.frameCanvas = React.createRef();

    this.state = {
      canvasContext: null
    };

    this.lastRenderedFrameNumber = -1;
    this.timer = null;
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    if (this.frameCanvas && this.frameCanvas.current) {
      this.setState({
        canvasContext: this.frameCanvas.current.getContext("2d")
      });
      this.props.getSnapshotGetter &&
        this.props.getSnapshotGetter(this.getSnapshot);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.canvasContext &&
      this.frameCanvas &&
      this.frameCanvas.current
    ) {
      this.setState({
        canvasContext: this.frameCanvas.current.getContext("2d")
      });
      this.props.getSnapshotGetter &&
        this.props.getSnapshotGetter(this.getSnapshot);
    }
    if (
      prevProps.speed !== this.props.speed ||
      this.props.mode !== prevProps.mode
    ) {
      this.lastRenderedFrameNumber = -1;
    }
    const frame_changed =
      this.props.image &&
      this.props.blob &&
      this.props.frameNumber !== prevProps.frameNumber;
    if (frame_changed) {
      this.loadImage();
    }
  }

  loadImage = () => {
    const { image, blob, frameNumber, speed } = this.props;
    image.addEventListener("load", () => {
      const frameDiff = frameNumber - this.lastRenderedFrameNumber;
      if (this.lastRenderedFrameNumber === -1 || frameDiff === 1) {
        this.drawImage(image, frameNumber);
      } else if (frameDiff > 1) {
        setTimeout(() => {
          this.drawImage(image, frameNumber);
        }, frameDiff * (Math.abs(speed) / 20));
      }
    });
    image.setAttribute("src", window.URL.createObjectURL(blob));
  };

  drawImage = (image, frameNumber) => {
    const { canvasContext } = this.state;
    if (canvasContext && this.lastRenderedFrameNumber < frameNumber) {
      this.lastRenderedFrameNumber = frameNumber;
      if (this.frameCanvas.current) {
        this.frameCanvas.current.width !== image.width &&
          this.frameCanvas.current.setAttribute("width", image.width);
        this.frameCanvas.current.height !== image.height &&
          this.frameCanvas.current.setAttribute("height", image.height);
      }
      canvasContext.drawImage(image, 0, 0, image.width, image.height);
    }
    window.URL.revokeObjectURL(image.src);
  };

  getSnapshot = () => {
    return this.frameCanvas && this.frameCanvas.current
      ? this.frameCanvas.current.toDataURL("image/png")
      : "";
  };

  render() {
    return <canvas ref={this.frameCanvas} className="camera-canvas" />;
  }
}

CameraVideoCanvas.propTypes = {
  image: PropTypes.object,
  mode: PropTypes.number,
  speed: PropTypes.number,
  blob: PropTypes.object,
  frameNumber: PropTypes.number,
  getSnapshotGetter: PropTypes.func,
  setAspectRatio: PropTypes.func
};

CameraVideoCanvas.defaultProps = {
  id: null,
  image: null,
  camera: null,
  showPtz: false,
  isPlaying: false,
  loading: false
};

export default CameraVideoCanvas;
