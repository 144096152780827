import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Dropdown from "../../Common/Dropdown";
import SaveModal from "../../Common/SaveModal";
import ConfirmModal from "../../Common/ConfirmModal";
import messages from "./messages";
import {
  INVESTIGATION_STATE_OLD,
  INVESTIGATION_STATUS_SAVING
} from "../../Investigations/constants";
import { Button, Progress } from "antd";
import { MODE_NEW } from "../constants";

const EntityToolbar = ({
  entity,
  layoutColumns,
  pathBase,
  currentPath,
  editPath,
  downloadPath,
  handleDelete,
  handleSave,
  toggleModal,
  showModal,
  canSave,
  saving
}) => {
  const { id = "", type = "", items = [], state } = entity;
  const isNew = !id || id === MODE_NEW;
  canSave = canSave == null ? !!items.length : canSave;
  const isSaving = entity.status === INVESTIGATION_STATUS_SAVING;
  const saveProgress = isNaN(parseInt(entity.statusProgress))
    ? -1
    : parseInt(entity.statusProgress);

  const saveOptions = [
    {
      value: isNew ? "save" : "update",
      text: isNew ? (
        <FormattedMessage {...messages.saveOption} values={{ type }} />
      ) : (
        <FormattedMessage {...messages.updateOption} values={{ type }} />
      )
    },
    {
      value: "saveAs",
      text: <FormattedMessage {...messages.saveAsOption} values={{ type }} />
    }
  ];

  const editButton = editPath && (
    <NavLink
      to={currentPath === editPath ? pathBase : editPath}
      className="ant-btn ant-btn-outline"
      isActive={() => currentPath === editPath}
      onClick={e => {
        if (currentPath === editPath) {
          e.target.blur();
        }
      }}
      disabled={isSaving}
    >
      <FormattedMessage {...messages.editCamerasButton} />
    </NavLink>
  );

  const downloadButton = downloadPath && (
    <NavLink
      to={currentPath === downloadPath ? pathBase : downloadPath}
      className="ant-btn ant-btn-outline"
      isActive={() => currentPath === downloadPath}
      disabled={isNew || isSaving}
    >
      <FormattedMessage {...messages.downloadButton} />
    </NavLink>
  );

  let saveComponent = "";
  if (isSaving) {
    const hasProgress = saveProgress > 0 && saveProgress <= 100;
    saveComponent = (
      <Button
        disabled={true}
        className="ant-btn-outline ant-btn-progress entity-saving"
        loading={!hasProgress}
      >
        <span className="btn-text">
          <FormattedMessage {...messages.saving} />
        </span>
        {hasProgress && (
          <Progress
            type="circle"
            percent={saveProgress}
            successPercent={100}
            width={24}
            strokeWidth={3}
            status="active"
          />
        )}
      </Button>
    );
  } else if (isNew) {
    saveComponent = (
      <Button
        disabled={canSave === false}
        className="ant-btn-outline"
        onClick={() => toggleModal("save")}
      >
        <FormattedMessage {...messages.saveOption} />
      </Button>
    );
  } else {
    saveComponent = (
      <Dropdown
        id="entity-save"
        handleClick={value => toggleModal(value)}
        btnText={
          <span className="btn-text">
            <span className="btn-text--closed">
              <FormattedMessage {...messages.saveOption} />
            </span>
            <span className="btn-text--open">
              <FormattedMessage {...messages.cancel} />
            </span>
          </span>
        }
        disabled={canSave === false}
        menuOptions={saveOptions}
        btnType="outline"
        placement="bottomRight"
      />
    );
  }

  return (
    <div className="toolbar entity-toolbar">
      {layoutColumns}
      {state !== INVESTIGATION_STATE_OLD && editPath ? editButton : null}
      {downloadPath ? downloadButton : null}
      {!isNew && (
        <ConfirmModal
          entity={entity}
          title={
            <FormattedMessage
              {...messages.deleteModalTitle}
              values={{ type }}
            />
          }
          btnText={<FormattedMessage {...messages.deleteButton} />}
          btnType="outline"
          okText="Delete"
          handleOk={handleDelete}
          visible={showModal === "delete"}
          toggle={() => toggleModal("delete")}
          disabled={!id}
        >
          <FormattedMessage
            {...messages.deleteModalText}
            values={{ type: type.toLowerCase() }}
          />
        </ConfirmModal>
      )}
      {state !== INVESTIGATION_STATE_OLD && saveComponent}
      {state !== INVESTIGATION_STATE_OLD &&
        saveOptions.map(saveOption => (
          <SaveModal
            key={saveOption.value}
            modal={saveOption.value}
            visible={showModal === saveOption.value}
            entity={entity}
            handleSubmit={handleSave}
            toggle={() => toggleModal(saveOption.value)}
            loading={saving}
          />
        ))}
    </div>
  );
};

EntityToolbar.propTypes = {
  entity: PropTypes.object,
  layoutColumns: PropTypes.element,
  pathBase: PropTypes.string,
  currentPath: PropTypes.string,
  handleDelete: PropTypes.func,
  handleSave: PropTypes.func,
  toggleModal: PropTypes.func,
  showModal: PropTypes.string,
  canSave: PropTypes.bool,
  saving: PropTypes.bool
};

export default EntityToolbar;
