import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink, Link, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Layout, Menu, Spin } from "antd";
import withData from "../../DataWrapper";
import * as searchActions from "../../../actions/searchActions";
import * as authActions from "../../../actions/authActions";
import HelpModal from "../HelpModal";
import SettingsPopover from "../SettingsPopover";
import ConfirmModal from "../ConfirmModal";
import Logo from "../Logo";
import messages from "../../../constants/messages";
import SearchForm from "../SearchForm";
import { getSearch, setSearch } from "../../../utils/queryString";

class SiteHeader extends Component {
  constructor(props) {
    super(props);
    this.searchForm = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutsideSearch);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideSearch);
  }

  handleClickOutsideSearch = e => {
    if (
      this.props.showHeader &&
      this.searchForm.current &&
      this.searchForm.current.contains(e.target)
    ) {
      return;
    }

    if (this.props.search.showSearchForm) {
      this.props.actions.toggleSearchForm(false);
    }
  };

  handleToggleWebsocket = () => {
    this.props.actions.toggleWebSocket();
  };

  handleToggleDirectStreaming = () => {
    this.props.actions.toggleDirectStreaming();
  };

  toggleModal = modal => {
    this.props.actions.toggleModal(modal);
  };

  handleLogout = () => {
    this.props.actions.logout();
  };

  handleSearchSubmit = (e, searchTerm = "") => {
    e.preventDefault();
    this.props.history.push({
      pathname: "/search",
      search: setSearch(searchTerm, this.props.location.search)
    });
  };

  handleSearchSubmitClick = (e, collapsed, searchTerm) => {
    if (!searchTerm || this.props.search.showSearchForm === false) {
      e.preventDefault();
      this.props.actions.toggleSearchForm(collapsed);
    } else {
      this.handleSearchSubmit(e, searchTerm);
    }
  };

  render() {
    const {
      auth: { username = "", loading },
      utility: { showModal },
      search: { searchTerm = "", showSearchForm = false },
      showHeader = true,
      menuLinks = []
    } = this.props;

    if (!showHeader) {
      return null;
    }

    const { Header } = Layout;
    const { Item } = Menu;

    return (
      <Header>
        <h1 className="site-title">
          <Link to="/cameras">
            <Logo title={messages.title} />
          </Link>
        </h1>
        <Menu mode="horizontal" theme="dark" className="menu--primary">
          {menuLinks
            .filter(link => link.menu)
            .map((link, i) => {
              return (
                <Item key={i}>
                  <NavLink to={link.path} className="menu-link">
                    {link.text}
                  </NavLink>
                </Item>
              );
            })}
        </Menu>
        <Menu
          mode="horizontal"
          theme="dark"
          className={`menu--secondary${showSearchForm ? " search-open" : ""}`}
        >
          <Item>
            <HelpModal
              btnText={
                <div className="btn-text">
                  <FormattedMessage {...messages.help} />
                </div>
              }
            />
          </Item>
          <Item>
            <SettingsPopover
              websocketsEnabled={this.props.utility.enableWebsocket}
              directStreamingEnabled={this.props.utility.enableDirectStreaming}
              handleToggleWebsockets={this.handleToggleWebsocket}
              handleToggleDirectStreaming={this.handleToggleDirectStreaming}
            />
          </Item>
          <Item>
            <ConfirmModal
              toggle={() => this.toggleModal("logout")}
              visible={showModal === "logout"}
              handleOk={this.handleLogout}
              okText="Log out"
              title={<FormattedMessage {...messages.logoutModalTitle} />}
              okButtonProps={{ disabled: loading }}
              cancelButtonProps={{ disabled: loading }}
              btnText={
                <div className="btn-text">
                  <FormattedMessage
                    {...messages.logoutModalTriggerBtn}
                    values={{
                      username: <div className="username">{username}</div>
                    }}
                  />
                </div>
              }
            >
              <Spin spinning={loading}>
                <FormattedMessage {...messages.logoutModalText} />
              </Spin>
            </ConfirmModal>
          </Item>
          <Item className="menu-item--search">
            <div ref={this.searchForm} className="search-form-wrapper">
              <SearchForm
                collapsed={!showSearchForm}
                handleSearchSubmit={this.handleSearchSubmit}
                search={{ value: searchTerm }}
                handleSearchSubmitClick={this.handleSearchSubmitClick}
                size="large"
              />
            </div>
          </Item>
        </Menu>
      </Header>
    );
  }
}

SiteHeader.propTypes = {
  handleSearchSubmit: PropTypes.func,
  menuLinks: PropTypes.array,
  showHeader: PropTypes.bool,
  search: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  auth: PropTypes.object
};

const mapStateToProps = ({ search, auth, router, utility }) => {
  return {
    extraMenuLinks: utility.content
      ? utility.content.extraMenuLinks || []
      : undefined,
    search: { ...search, searchTerm: getSearch(router.location.search) },
    auth
  };
};

export default withData(withRouter(SiteHeader), mapStateToProps, {
  ...searchActions,
  ...authActions
});
