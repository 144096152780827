import {
  START_EXPORT,
  CANCEL_EXPORT,
  START_EXPORT_SUCCESS,
  START_EXPORT_FAILURE,
  DELETE_EXPORT,
  DELETE_EXPORT_SUCCESS,
  DELETE_EXPORT_FAILURE,
  FETCH_ALL_EXPORTS,
  FETCH_ALL_EXPORTS_SUCCESS,
  FETCH_ALL_EXPORTS_FAILURE,
  FETCH_EXPORT,
  FETCH_EXPORT_SUCCESS,
  FETCH_EXPORT_FAILURE,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS
} from "../constants/actionTypes";

const initialState = {
  loading: false,
  exports: [],
  error: null,
  fetching: {},
  deleting: {},
  queueing: []
};

const exportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_EXPORT:
      return {
        ...state,
        queueing: state.queueing
          .filter(
            e => e.cameraId !== action.cameraId || e.type !== action.exportType
          )
          .concat({
            cameraId: action.cameraId,
            type: action.exportType,
            error: null,
            loading: true,
            queuedTime: Date.now(),
            getConnection: action.getConnection,
            investigationId: action.investigationId
          })
      };
    case CANCEL_EXPORT:
      return {
        ...state,
        queueing: state.queueing.filter(
          e => e.cameraId !== action.cameraId || e.type !== action.exportType
        )
      };
    case START_EXPORT_SUCCESS:
      return {
        ...state,
        queueing: state.queueing.filter(
          e => e.cameraId !== action.cameraId || e.type !== action.exportType
        ),
        exports: state.exports.concat({
          investigationId: action.investigationId,
          exportId: action.exportId,
          cameraId: action.cameraId,
          type: action.exportType,
          startTime: action.startTime,
          endTime: action.endTime,
          size: 0,
          state: 0,
          error: null,
          queuedTime: Date.now()
        })
      };
    case START_EXPORT_FAILURE:
      return {
        ...state,
        queueing: state.queueing.map(e => {
          if (e.cameraId === action.cameraId && e.type === action.exportType) {
            return {
              ...e,
              loading: false,
              error: action.error
            };
          }
          return e;
        })
      };
    case DELETE_EXPORT: {
      // eslint-disable-next-line no-unused-vars
      const { [action.exportId]: fetched, ...fetching } = state.fetching;
      return {
        ...state,
        fetching,
        deleting: {
          ...state.deleting,
          [action.exportId]: {
            loading: true
          }
        }
      };
    }
    case DELETE_EXPORT_SUCCESS: {
      // eslint-disable-next-line no-unused-vars
      const { [action.data.exportId]: deleted, ...deleting } = state.deleting;
      return {
        ...state,
        loading: false,
        exports: state.exports.filter(e => e.exportId !== action.data.exportId),
        deleting
      };
    }
    case DELETE_EXPORT_FAILURE: {
      return {
        ...state,
        deleting: {
          ...state.deleting,
          [action.exportId]: {
            loading: false,
            error: action.error
          }
        }
      };
    }
    case FETCH_ALL_EXPORTS:
      return {
        ...state,
        loading: true
      };
    case FETCH_ALL_EXPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        exports: action.exports,
        fetching: {}
      };
    case FETCH_ALL_EXPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case FETCH_EXPORT:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          [action.exportId]: {
            loading: true,
            getConnection: action.getConnection
          }
        }
      };
    case FETCH_EXPORT_SUCCESS: {
      // eslint-disable-next-line no-unused-vars
      const { [action.exportId]: fetched, ...fetching } = state.fetching;
      return {
        ...state,
        loading: false,
        exports: state.exports
          .filter(e => e.exportId !== action.data.exportId)
          .concat(action.data),
        fetching
      };
    }
    case FETCH_EXPORT_FAILURE:
      return {
        ...state,
        fetching: {
          ...state.fetching,
          [action.exportId]: {
            loading: false,
            error: action.error
          }
        }
      };

    case LOGIN_SUCCESS:
      return { ...state, loading: true };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default exportsReducer;
