import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "components.Building.title",
    defaultMessage: "Building"
  },
  empty: {
    id: "components.Building.empty",
    defaultMessage: "There are no cameras for this building."
  },
  emptyActive: {
    id: "components.Building.emptyActive",
    defaultMessage: "Select a camera to view."
  }
});
