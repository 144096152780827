import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import classnames from "classnames";

const Logo = ({ type = "", intl, title = "", size = "default" }) => {
  let titleText = title;
  if (title && typeof title === "object") {
    titleText = intl.formatMessage(title);
  }
  const classes = ["logo", `logo-size-${size}`, { [`logo-${type}`]: type }];
  return (
    <div
      className={classnames(classes)}
      aria-hidden={title ? "false" : "true"}
      title={titleText}
    >
      {titleText}
    </div>
  );
};

Logo.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  intl: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default injectIntl(Logo);
