import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Input } from "antd";
import messages from "./messages";

const { Item } = Form;

const CameraSearchForm = props => {
  const { intl, form, handleSearch } = props;
  const { getFieldDecorator, getFieldValue } = form;
  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        handleSearch(getFieldValue("search"));
      }}
      className="search-form form-dark camera-search-form"
    >
      <h3 className="form-title sr-only">
        <FormattedMessage {...messages.title} />
      </h3>
      <Item
        label={intl.formatMessage(messages.inputPlaceholder)}
        className="ant-form-item-label-hidden"
      >
        {getFieldDecorator("search")(
          <Input.Search
            placeholder={intl.formatMessage(messages.inputPlaceholder)}
            enterButton="Search"
            onSearch={(value, e) => {
              e.preventDefault();
              handleSearch(value);
            }}
          />
        )}
      </Item>
    </Form>
  );
};

CameraSearchForm.propTypes = {
  handleSubmit: PropTypes.func,
  searchTerm: PropTypes.string
};

export default injectIntl(Form.create()(CameraSearchForm));
