import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { List, Card } from "antd";
import CameraThumbnail from "../CameraThumbnail";
import { LazyLoadContainer } from "../../../utils/outOfViewportCheck";

class CameraCard extends LazyLoadContainer {
  render() {
    const { id, name, cameras, custom } = this.props;
    return (
      <article className="camera-card">
        <Link
          to={`/${custom ? "views" : "cameras"}/${id}`}
          className="camera-card-link"
        >
          <Card
            title={
              <h2 className="camera-card-name">
                {name} ({cameras.length})
              </h2>
            }
            bordered={false}
          >
            <List
              className="camera-card-thumbnails"
              dataSource={cameras.slice(0, 3)}
              grid={{ gutter: 18, column: 3 }}
              renderItem={camera => {
                return (
                  <List.Item className="camera-card-thumbnails-item">
                    <CameraThumbnail
                      key={camera.id}
                      viewId={id}
                      showHeader={false}
                      showSpinner
                      camera={camera}
                    />
                  </List.Item>
                );
              }}
            />
          </Card>
        </Link>
      </article>
    );
  }
}

CameraCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  cameras: PropTypes.array,
  custom: PropTypes.bool
};

CameraCard.defaultProps = {
  id: "",
  name: "",
  cameras: [],
  custom: false
};

export default CameraCard;
