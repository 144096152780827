import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "components.CameraViewPage.title",
    defaultMessage: "Cameras"
  },
  rearrangeCameras: {
    id: "components.CameraViewPage.rearrangeCameras",
    defaultMessage: "Rearrange Cameras"
  },
  saveArrangement: {
    id: "components.CameraViewPage.saveArrangement",
    defaultMessage: "Save Arrangement"
  },
  cancel: {
    id: "components.CameraViewPage.cancel",
    defaultMessage: "Cancel"
  }
});
