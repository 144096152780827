import { normalize } from "normalizr";
import {
  START_EXPORT,
  CANCEL_EXPORT,
  START_EXPORT_SUCCESS,
  START_EXPORT_FAILURE,
  DELETE_EXPORT,
  DELETE_EXPORT_SUCCESS,
  DELETE_EXPORT_FAILURE,
  FETCH_ALL_EXPORTS,
  FETCH_ALL_EXPORTS_SUCCESS,
  FETCH_ALL_EXPORTS_FAILURE,
  FETCH_EXPORT,
  FETCH_EXPORT_SUCCESS,
  FETCH_EXPORT_FAILURE
} from "../constants/actionTypes";
import * as schema from "../utils/schemas";
import { getResponseData, getResponseError } from "../utils/api";

export const fetchAllExports = (force = false) => {
  return (dispatch, getState, XPMobileSDK) => {
    const {
      exports: { exports = [] }
    } = getState();
    if (exports.length && !force) {
      return Promise.resolve();
    }
    dispatch({ type: FETCH_ALL_EXPORTS });
    XPMobileSDK.getUserExports(
      data => {
        const { entities = { export: {} } } = normalize(data, schema.exports);
        dispatch(fetchAllExportsSuccess(Object.values(entities.export || {})));
      },
      error => {
        dispatch(fetchAllExportsFailure(error));
      }
    );
  };
};

export const fetchAllExportsSuccess = exports => ({
  type: FETCH_ALL_EXPORTS_SUCCESS,
  exports
});

export const fetchAllExportsFailure = error => ({
  type: FETCH_ALL_EXPORTS_FAILURE,
  error
});

export const fetchExport = exportId => {
  return (dispatch, getState, XPMobileSDK) => {
    const req = XPMobileSDK.getExport(
      exportId,
      data => {
        const { entities = { export: {} } } = normalize(data, schema._export);
        dispatch(fetchExportSuccess(Object.values(entities.export)[0]));
      },
      error => {
        dispatch(fetchExportFailure(error, exportId));
      }
    );
    dispatch({
      type: FETCH_EXPORT,
      exportId,
      getConnection: () => req
    });
  };
};

export const fetchExportSuccess = data => ({
  type: FETCH_EXPORT_SUCCESS,
  data
});

export const fetchExportFailure = (error, exportId) => ({
  type: FETCH_EXPORT_FAILURE,
  error,
  exportId
});

export const startExport = (
  cameraId,
  startTime,
  endTime,
  type,
  investigationId
) => {
  return (dispatch, getState, XPMobileSDK) => {
    let req = null;
    if (!cameraId || !Object.keys(schema.EXPORT_TYPES).includes(type)) {
      return Promise.resolve();
    }
    const onSuccess = exportId => {
      dispatch(
        startExportSuccess(
          exportId,
          cameraId,
          type,
          investigationId,
          startTime,
          endTime
        )
      );
    };
    const onError = error => {
      dispatch(startExportFailure(error, cameraId, type, investigationId));
    };
    if (type === "avi") {
      req = XPMobileSDK.startVideoExport(
        cameraId,
        startTime,
        endTime,
        onSuccess,
        onError
      );
    } else if (type === "db" || type === "aggregated") {
      req = XPMobileSDK.startInvestigationExport(
        investigationId,
        _.capitalize(type),
        false,
        onSuccess,
        onError
      );
    } else if (type === "mkv") {
      req = XPMobileSDK.sendCommand(
        "StartExport",
        {
          CameraId: cameraId,
          //InvestigationId: investigationId,
          StartTime: startTime,
          EndTime: endTime,
          Type: _.capitalize(type)
        },
        {},
        res => {
          const data = getResponseData(res);
          if (data && data.ExportId) {
            return onSuccess(data.ExportId);
          }
          onError({
            code: 500,
            message: "Failed to get ExportId from StartExport response"
          });
        },
        res => onError(getResponseError(res) || res)
      );
    }
    return dispatch({
      type: START_EXPORT,
      cameraId,
      exportType: type,
      investigationId,
      getConnection: () => req
    });
  };
};

export const startExportSuccess = (
  exportId,
  cameraId,
  exportType,
  investigationId,
  startTime,
  endTime
) => {
  return dispatch => {
    dispatch({
      type: START_EXPORT_SUCCESS,
      exportId,
      cameraId,
      exportType,
      investigationId,
      startTime,
      endTime
    });
    dispatch(
      exportType === "db"
        ? fetchAllExports(true)
        : fetchExport(exportId, cameraId, exportType, investigationId)
    );
  };
};

export const startExportFailure = (
  error,
  cameraId,
  exportType,
  investigationId
) => ({
  type: START_EXPORT_FAILURE,
  error,
  cameraId,
  exportType,
  investigationId
});

export const cancelExport = exp => (dispatch, getState, XPMobileSDK) => {
  const { cameraId, type: exportType, exportId } = exp;
  if (exp.getConnection && exp.getConnection()) {
    XPMobileSDK.cancelRequest(exp.getConnection());
    dispatch({
      type: CANCEL_EXPORT,
      cameraId,
      exportType
    });
  }
  if (exportId) {
    dispatch(deleteExport(exp));
  }
};

export const deleteExport = exp => {
  return (dispatch, getState, XPMobileSDK) => {
    const {
      cameraId,
      type: exportType,
      exportId,
      aggregateType,
      investigationId
    } = exp;
    const type =
      exportType === "aggregated" && aggregateType ? aggregateType : exportType;
    const onSuccess = () => {
      dispatch(deleteExportSuccess({ exportId, cameraId, exportType: type }));
    };
    const onError = error => {
      dispatch(deleteExportFailure(error, exportId));
    };
    const { [exportId]: fetched } = getState().exports;
    if (fetched && fetched.getConnection && fetched.getConnection()) {
      XPMobileSDK.cancelRequest(fetched.getConnection());
    }

    dispatch({ type: DELETE_EXPORT, exportId });
    if (
      investigationId &&
      (exportType === "db" || exportType === "aggregated")
    ) {
      XPMobileSDK.deleteInvestigationExport(
        investigationId,
        _.capitalize(type),
        onSuccess,
        onError
      );
    } else {
      XPMobileSDK.deleteExport(exportId, onSuccess, onError);
    }
  };
};

export const deleteExportSuccess = data => {
  return dispatch => {
    dispatch({
      type: DELETE_EXPORT_SUCCESS,
      data
    });
    if (data.exportType === "db") {
      dispatch(fetchAllExports(true));
    }
  };
};

export const deleteExportFailure = (error, exportId) => ({
  type: DELETE_EXPORT_FAILURE,
  error,
  exportId
});
