import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CameraToolbar from "../CameraToolbar";
import CameraFrame from "../CameraFrame";
import {
  PLAYER_MODE_LIVE,
  PLAYER_MODE_PLAYBACK,
  SEEK_TYPE_PREV_FRAME,
  SEEK_TYPE_NEXT_FRAME,
  SEEK_TYPE_TIME
} from "../constants";
import CameraPlayer from "../CameraPlayer";

import XPMobileSDK from "../../../utils/api";

class SingleCameraPlayer extends CameraPlayer {
  displayName = "SingleCameraPlayer";
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      frame: null,
      mode: PLAYER_MODE_LIVE,
      videoConnection: null,
      streamRequest: null,
      showModal: null,
      showFullscreen: null,
      speed: 1,
      timestamp: new Date().getTime(),
      thumbnail: null,
      videoConnectionObserver: null,
      showPtz: false,
      stream: null,
      isReconnecting: false,
      useFallbackRender:
        !this.props.directStreamingEnabled || !this.props.websocketsEnabled
    };
    this.fullscreen_container = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevState.stream || !prevState.stream.videoConnection) &&
      this.state.stream &&
      this.state.stream.videoConnection &&
      this.state.useFallbackRender
    ) {
      const { videoConnection, videoConnectionObserver } = this.state.stream;
      videoConnection.addObserver(videoConnectionObserver);
      videoConnection.open();
    }
    if (
      prevProps.websocketsEnabled !== this.props.websocketsEnabled ||
      prevProps.directStreamingEnabled !== this.props.directStreamingEnabled
    ) {
      this.toggleFallbackRender(
        !this.props.websocketsEnabled || !this.props.directStreamingEnabled
      );
    }
  }

  handleRequestStream = (params = {}) => {
    const { speed = 1, mode = null, seekType = null } = params;
    const { camera } = this.props;
    if (!camera) {
      return null;
    }

    const { stream, useFallbackRender } = this.state;
    const destination = { width: 623, height: 445 };
    const signal = mode ? mode : this.state.mode;

    // Get stream for the camera.
    const { id } = camera;

    if (!stream || !stream.videoConnection || mode) {
      const videoConnectionObserver = {
        videoConnectionReceivedFrame: frame => this.handleReceiveFrame(frame)
      };
      const playbackSpeed = mode && mode === PLAYER_MODE_PLAYBACK ? 0 : 1;
      const streamRequest = XPMobileSDK.requestStream(
        id,
        destination,
        {
          reuseConnection: true,
          signal,
          streamType:
            mode === PLAYER_MODE_LIVE && !useFallbackRender
              ? XPMobileSDK.library.VideoConnectionStream.FragmentedMP4
              : undefined
        },
        videoConnection => {
          const { stream: stream_ } = this.state;
          if (
            stream_ &&
            stream_.videoConnection &&
            stream_.videoConnection.getState() !==
              XPMobileSDK.library.VideoConnectionState.closed
          ) {
            stream_.videoConnection.removeObserver(
              stream_.videoConnectionObserver
            );
            stream_.videoConnection.close();
          }
          this.setState({
            mode: signal,
            speed: playbackSpeed,
            stream: {
              videoConnection: videoConnection,
              videoConnectionObserver
            },
            loading: mode !== PLAYER_MODE_PLAYBACK || playbackSpeed !== 0,
            isReconnecting: false
          });
          if (
            videoConnection.response.parameters.StreamType !== "FragmentedMP4"
          ) {
            videoConnection.addObserver(videoConnectionObserver);
            videoConnection.open();
          }

          // Handle seek.
          if (seekType === SEEK_TYPE_TIME) {
            XPMobileSDK.playbackGoTo(
              videoConnection,
              params.time,
              SEEK_TYPE_TIME,
              () => {
                this.getThumbnailAtTime(videoConnection, params.time);
              }
            );
          } else if (seekType) {
            XPMobileSDK.playbackSeek(videoConnection, seekType);
          }
        },
        error => {
          this.setState({ error, speed: 0 });
        }
      );

      this.setState({
        stream: {
          ...this.state.stream,
          streamRequest
        }
      });
    } else {
      // Handle speed change.
      this.handleSetSpeed(
        seekType === SEEK_TYPE_NEXT_FRAME || seekType === SEEK_TYPE_PREV_FRAME
          ? 0
          : speed
      );

      // Handle seek.
      if (seekType === SEEK_TYPE_TIME) {
        XPMobileSDK.playbackGoTo(
          stream.videoConnection,
          params.time,
          SEEK_TYPE_TIME,
          () => {
            this.getThumbnailAtTime(stream.videoConnection, params.time);
          }
        );
      } else if (seekType) {
        XPMobileSDK.playbackSeek(stream.videoConnection, seekType);
      }
    }
  };

  handleStopStream = callback => {
    const { camera } = this.props;
    const { id } = camera;
    if (!this.state.stream) {
      return null;
    }

    const frame = this.getFrame();
    if (
      frame &&
      frame.image &&
      frame.image.src &&
      camera.image &&
      !(camera.error && camera.error.code === 32)
    ) {
      this.props.fetchThumbnailResponse(id, frame.image.src);
    }
    const { videoConnection, videoConnectionObserver } = this.state.stream;
    if (videoConnection && videoConnectionObserver) {
      videoConnection.removeObserver(videoConnectionObserver);
    }

    requestIdleCallback(() => {
      if (
        videoConnection &&
        videoConnection.isReusable &&
        videoConnection.getState() !==
          XPMobileSDK.library.VideoConnectionState.closed
      ) {
        videoConnection.close();
        callback && callback();
      } else {
        this.destroyStream(callback);
      }
    });
  };

  destroyStream = callback => {
    if (!this.state.stream) {
      return null;
    }
    const {
      videoConnection,
      streamRequest,
      videoConnectionObserver
    } = this.state.stream;

    if (videoConnection) {
      if (videoConnectionObserver) {
        videoConnection.removeObserver(videoConnectionObserver);
      }
      if (
        videoConnection.getState() !==
        XPMobileSDK.library.VideoConnectionState.closed
      ) {
        videoConnection.close();
      }
    }

    if (streamRequest) {
      XPMobileSDK.cancelRequest(streamRequest);
    }
    this.setState(
      {
        stream: null,
        speed: 0
      },
      callback
    );
  };

  getFrame = () => this.state.frame || null;

  handleReceiveFrame = (newFrame = null) => {
    const { blob, hasSizeInformation } = newFrame;

    if (!blob) {
      return null;
    }

    let aspectRatio = this.state.aspectRatio;
    const image = new Image();
    if (hasSizeInformation) {
      const {
        sizeInfo: { destinationSize, sourceSize }
      } = newFrame;
      image.width = destinationSize.width;
      image.height = destinationSize.height;
      aspectRatio = sourceSize.height / sourceSize.width;
      this.setAspectRatio(sourceSize.width, sourceSize.height);
    }

    this.setState({
      aspectRatio,
      loading: false,
      frame: { ...newFrame, image },
      timestamp: newFrame.timestamp
    });
  };

  handleGetSequence = (time, dir, cb) => {
    const { camera } = this.props;
    if (!camera) {
      return;
    }
    XPMobileSDK[dir === "next" ? "getNextSequence" : "getPrevSequence"](
      camera.id,
      time,
      res => cb && typeof cb === "function" && cb(res)
    );
  };

  handleSetSpeed = speed => {
    const { videoConnection } = this.state.stream || {};
    if (speed !== this.state.speed && videoConnection) {
      XPMobileSDK.playbackSpeed(videoConnection, speed);
      this.setState({ speed });
    }
  };

  enableCapability = capability => {
    if (this.props.disableCapabilities.indexOf(capability) !== -1) {
      return false;
    }
    const { camera } = this.props;
    return camera && camera.capabilities && camera.capabilities[capability];
  };

  getThumbnailAtTime = (time, cb) => {
    const { camera } = this.props;
    if (!camera) {
      return;
    }
    XPMobileSDK.getThumbnailByTime(
      { cameraId: camera.id, time: time || this.state.timestamp },
      thumbnail => {
        if (
          typeof thumbnail === "string" &&
          !thumbnail.startsWith("data:image/jpeg;base64,")
        ) {
          thumbnail = "data:image/jpeg;base64,".concat(thumbnail);
        }
        this.setState({ thumbnail });
        cb && typeof cb === "function" && cb(thumbnail);
      }
    );
  };

  toggleDatepickerModal = (switchToLive = false) => {
    this.handleToggleModal("datepicker");
    if (switchToLive) {
      this.handleTogglePlayerMode(PLAYER_MODE_LIVE);
    }
  };

  handleTogglePlayerMode = mode => {
    if (!this.state.stream) {
      this.handleRequestStream({ mode });
      return;
    }
    this.handleStopStream(() => {
      this.handleRequestStream({ mode });
    });
  };

  getSnapshotUrl = () => "";

  forceReconnect = () => {
    const { speed, mode } = this.state;
    this.destroyStream(() => {
      this.setState({ loading: true, isReconnecting: true }, () =>
        this.handleRequestStream({ mode, speed })
      );
    });
  };

  onStreamLoad = () => {
    this.setState({ loading: false });
  };

  onDirectStreamFail = () => {
    this.toggleFallbackRender(false);
  };

  toggleFallbackRender = enabled => {
    const { speed, mode } = this.state;
    if (!this.state.stream) {
      this.setState({ useFallbackRender: enabled, loading: true });
      return;
    }
    this.destroyStream(() => {
      this.setState({ useFallbackRender: enabled, loading: true }, () =>
        setTimeout(() => {
          this.handleRequestStream({ mode, speed });
        }, 1000)
      );
    });
  };

  renderFrame = (() => {
    const renderFrame = () => {
      const { camera, directStreamingEnabled, websocketsEnabled } = this.props;
      const {
        showPtz,
        showFullscreen,
        speed,
        mode,
        stream,
        loading,
        useFallbackRender,
        isReconnecting
      } = this.state;
      const { videoConnection } = stream || {};

      const { image = null, blob = null, frameNumber = null } =
        this.getFrame() || {};
      return (
        <CameraFrame
          key={camera.id}
          id={camera.id}
          camera={camera}
          image={image}
          mode={mode}
          speed={speed}
          loading={loading}
          isPlaying={speed !== 0}
          showPtz={mode === PLAYER_MODE_LIVE && showPtz}
          handleToggleFullscreen={this.handleToggleFullscreen}
          showFullscreen={camera.id === showFullscreen}
          videoConnection={videoConnection}
          forceReconnect={this.forceReconnect}
          blob={blob}
          frameNumber={frameNumber}
          setAspectRatio={this.setAspectRatio}
          getSnapshotGetter={func =>
            typeof func === "function" && (this.getSnapshotUrl = func)
          }
          useFallbackRender={useFallbackRender}
          onStreamLoad={this.onStreamLoad}
          onDirectStreamFail={this.onDirectStreamFail}
          isBlank={
            mode !== PLAYER_MODE_LIVE &&
            !loading &&
            (!camera.image || (camera.error && camera.error.code === 32))
          }
          directStreamingEnabled={directStreamingEnabled}
          websocketsEnabled={websocketsEnabled}
          isReconnecting={isReconnecting}
        />
      );
    };
    renderFrame.displayName = "renderFrame()";
    return renderFrame;
  })();

  renderToolbar = (() => {
    const renderToolbar = () => {
      const { startTime, endTime, camera } = this.props;
      const {
        showPtz,
        showModal,
        timestamp,
        thumbnail,
        speed,
        mode
      } = this.state;

      return (
        <CameraToolbar
          setTimestamp={this.handleSetTimestamp}
          toggleDatepickerModal={() => this.toggleDatepickerModal}
          datepickerModalVisible={showModal === "datepicker"}
          snapshotUrl={this.getSnapshotUrl()}
          camera={camera}
          mode={mode}
          speed={speed}
          startTime={startTime}
          endTime={endTime}
          timestamp={new Date(timestamp).getTime()}
          enableCapability={this.enableCapability}
          handleTogglePlayerMode={this.handleTogglePlayerMode}
          showPtz={mode === PLAYER_MODE_LIVE && showPtz}
          handleTogglePtz={this.handleTogglePtz}
          showSnapshot={true}
          thumbnail={thumbnail}
          getThumbnail={this.getThumbnailAtTime.bind(this)}
          getEvents={this.handleGetSequence.bind(this)}
          playing={speed !== 0}
          actions={{
            play: () => this.handleRequestStream({ speed: 1 }),
            pause: () => this.handleRequestStream({ speed: 0 }),
            forward: () =>
              this.handleRequestStream({ seekType: SEEK_TYPE_NEXT_FRAME }),
            back: () =>
              this.handleRequestStream({ seekType: SEEK_TYPE_PREV_FRAME }),
            setSpeed: this.handleToggleSpeed,
            reverse: () => this.handleRequestStream({ speed: -1 }),
            togglePtz: this.handleTogglePtz
          }}
        />
      );
    };
    renderToolbar.displayName = "renderToolbar()";
    return renderToolbar;
  })();

  renderFullscreen = (() => {
    const renderFullscreen = frame => {
      const { camera } = this.props;
      if (!camera) {
        return null;
      }

      return (
        <div className="camera-player-fullscreen">
          {frame}
          {this.renderToolbar()}
        </div>
      );
    };
    renderFullscreen.displayName = "renderFullscreen()";
    return renderFullscreen;
  })();

  render() {
    const { showFullscreen, aspectRatio } = this.state;
    const { camera } = this.props;
    const frame =
      typeof camera === "object" && camera.id ? this.renderFrame(camera) : null;

    return (
      <div
        className="camera-player camera-player-single"
        style={
          aspectRatio
            ? {
                "--camera-aspect-ratio": aspectRatio
              }
            : {}
        }
        data-fullscreen={!!showFullscreen}
      >
        <Helmet
          htmlAttributes={{
            ["class"]: showFullscreen ? "camera-player-fullscreen" : ""
          }}
        />
        <div className="camera-player-content" ref={this.frame}>
          <div className="camera-player-frames">
            {showFullscreen ? null : frame}
          </div>
          {showFullscreen ? null : this.renderToolbar(camera)}
        </div>
        <div ref={this.fullscreen_container} id="camera-player-fullscreen">
          {showFullscreen ? this.renderFullscreen(frame) : null}
        </div>
      </div>
    );
  }
}

SingleCameraPlayer.propTypes = {
  ...CameraPlayer.propTypes,
  camera: PropTypes.object
};

SingleCameraPlayer.defaultProps = {
  ...CameraPlayer.defaultProps,
  camera: null
};

export default SingleCameraPlayer;
