import { defineMessages } from "react-intl";

export default defineMessages({
  playback: {
    id: "components.CameraPlayer.playback",
    defaultMessage: "Playback"
  },
  live: {
    id: "components.CameraPlayer.live",
    defaultMessage: "Live"
  },
  cameraControls: {
    id: "components.CameraPlayer.cameraControls",
    defaultMessage: "Camera Controls"
  },
  snapshot: {
    id: "components.CameraPlayer.snapshot",
    defaultMessage: "Snapshot"
  },
  viewLive: {
    id: "components.CameraPlayer.viewLive",
    defaultMessage: "View Live"
  },
  skipBack: {
    id: "components.CameraPlayer.skipBack",
    defaultMessage: "Skip Back"
  },
  skipForward: {
    id: "components.CameraPlayer.skipForward",
    defaultMessage: "Skip Forward"
  },
  reverse: {
    id: "components.CameraPlayer.reverse",
    defaultMessage: "Reverse"
  },
  play: {
    id: "components.CameraPlayer.play",
    defaultMessage: "Play"
  },
  pause: {
    id: "components.CameraPlayer.pause",
    defaultMessage: "Pause"
  },
  nextFrame: {
    id: "components.CameraPlayer.nextFrame",
    defaultMessage: "Next Frame"
  },
  prevFrame: {
    id: "components.CameraPlayer.prevFrame",
    defaultMessage: "Previous Frame"
  },
  dateTime: {
    id: "components.CameraPlayer.dateTime",
    defaultMessage: "Date/Time"
  },
  playbackSpeed: {
    id: "components.CameraPlayer.playbackSpeed",
    defaultMessage: "Playback Speed"
  },
  left: {
    id: "components.CameraPlayer.left",
    defaultMessage: "Left"
  },
  right: {
    id: "components.CameraPlayer.right",
    defaultMessage: "Right"
  },
  up: {
    id: "components.CameraPlayer.up",
    defaultMessage: "Up"
  },
  down: {
    id: "components.CameraPlayer.down",
    defaultMessage: "Down"
  },
  zoomIn: {
    id: "components.CameraPlayer.zoomIn",
    defaultMessage: "Zoom In"
  },
  zoomOut: {
    id: "components.CameraPlayer.zoomOut",
    defaultMessage: "Zoom Out"
  },
  goToPreset: {
    id: "components.CameraPlayer.goToPreset",
    defaultMessage: "Return to Preset Position"
  },
  exit: {
    id: "components.CameraPlayer.exit",
    defaultMessage: "Exit"
  },
  saveToDevice: {
    id: "components.CameraPlayer.saveToDevice",
    defaultMessage: "Save To Device"
  },
  sendViaEmail: {
    id: "components.CameraPlayer.sendViaEmail",
    defaultMessage: "Send Via Email"
  },
  cancel: {
    id: "components.CameraPlayer.cancel",
    defaultMessage: "Cancel"
  },
  fullscreen: {
    id: "components.CameraPlayer.fullscreen",
    defaultMessage: "Fullscreen"
  },
  addCamera: {
    id: "components.CameraPlaceholder.addCamera",
    defaultMessage: "Add Camera"
  },
  removeCamera: {
    id: "components.CameraPlaceholder.removeCamera",
    defaultMessage: "Remove Camera"
  },
  datepickerModalTitle: {
    id: "components.DatepickerModal.title",
    defaultMessage: "Set Date and Time"
  },
  datepickerModalLast: {
    id: "components.DatepickerModal.last",
    defaultMessage: "Last Event"
  },
  datepickerModalNext: {
    id: "components.DatepickerModal.next",
    defaultMessage: "Next Event"
  },
  datepickerModalSubmitBtn: {
    id: "components.DatepickerModal.goBtn",
    defaultMessage: "Go to Time"
  },
  errorThumbnail: {
    id: "components.CameraThumbnail.errorThumbnail",
    defaultMessage: "Error loading thumbnail"
  },
  startLabel: {
    id: "components.CameraThumbnail.startLabel",
    defaultMessage: "Start:"
  },
  endLabel: {
    id: "components.CameraThumbnail.endLabel",
    defaultMessage: "End:"
  },
  durationLabel: {
    id: "components.CameraThumbnail.durationLabel",
    defaultMessage: "{timestamp} / {duration}"
  },
  timestampLabel: {
    id: "components.CameraThumbnail.timestampLabel",
    defaultMessage: "{timestamp}"
  },
  grid2: {
    id: "components.CameraViewPage.grid2",
    defaultMessage: "2 Column"
  },
  grid4: {
    id: "components.CameraViewPage.grid4",
    defaultMessage: "4 Column"
  },
  grid6: {
    id: "components.CameraViewPage.grid6",
    defaultMessage: "6 Column"
  },
  lostConnection: {
    id: "components.ErrorMessage.lostConnection",
    defaultMessage: "Lost Connection to Camera"
  }
});
