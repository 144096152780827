import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class CameraVideoStream extends PureComponent {
  constructor(props) {
    super(props);

    this.frameVideoStream = React.createRef();
    this.frameCanvas = document.createElement("canvas");

    this.timer = null;
    this.mounted = false;
    this.videoStreamListenersSet = false;
    this.stopped = true;
  }

  componentDidMount() {
    this.mounted = true;
    this.props.getSnapshotGetter &&
      this.props.getSnapshotGetter(this.getSnapshot);

    this.setEventListeners();
    if (this.props.videoConnection && this.frameVideoStream.current) {
      this.frameVideoStream.current.setAttribute(
        "videoId",
        this.props.videoConnection.videoId
      );
      this.play();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.videoStreamListenersSet) {
      this.setEventListeners();
    }
    if (!this.frameVideoStream.current) {
      return;
    }
    if (!this.props.videoConnection) {
      if (prevProps.videoConnection) {
        this.clearDirectStream();
        // this.frameVideoStream.current.dispatchEvent(new CustomEvent("destroy"));
      }
      return;
    }
    if (
      !prevProps.videoConnection ||
      prevProps.videoConnection.id !== this.props.videoConnection.id
    ) {
      // this.stop()
      this.frameVideoStream.current.setAttribute(
        "videoId",
        this.props.videoConnection.videoId
      );
      this.play();
    }
    this.props.getSnapshotGetter &&
      this.props.getSnapshotGetter(this.getSnapshot);
  }

  getSnapshot = () => {
    if (!this.frameCanvas || !this.frameVideoStream.current) {
      return "";
    }
    const video = this.frameVideoStream.current.shadowRoot.firstElementChild;
    this.frameCanvas.height = video.videoHeight;
    this.frameCanvas.width = video.videoWidth;
    const ctx = this.frameCanvas.getContext("2d");
    ctx.drawImage(video, 0, 0);
    return this.frameCanvas.toDataURL("image/png");
  };

  onDirectStreamingError = event => {
    // eslint-disable-next-line no-console
    console.error(`Failed to use videos-stream, switching to canvas`, event);
    if (this.frameVideoStream.current) {
      this.frameVideoStream.current.destroy(new CustomEvent("destroy"));
    }
    this.props.onDirectStreamFail();
  };

  onVideoStuckStart = event => {
    if (this.mounted) {
      this.props.setVideoStuck(true, event);
    }
  };

  onVideoStuckEnd = event => {
    if (this.mounted) {
      this.props.setVideoStuck(false, event);
    }
  };

  onFrameSizeChange = event => {
    if (this.mounted && this.props.setAspectRatio) {
      this.props.setAspectRatio(event.detail.width, event.detail.height);
    }
  };

  setEventListeners = (active = true) => {
    if (!this.frameVideoStream.current) {
      return;
    }
    const method = active ? "addEventListener" : "removeEventListener";
    this.frameVideoStream.current[method](
      "fallback",
      this.onDirectStreamingError
    );
    this.frameVideoStream.current[method](
      "errorConnect",
      this.onDirectStreamingError
    );
    this.frameVideoStream.current[method](
      "playerStarted",
      this.props.onStreamLoad
    );
    this.frameVideoStream.current[method](
      "beginVideoStuck",
      this.onVideoStuckStart
    );
    this.frameVideoStream.current[method](
      "endVideoStuck",
      this.onVideoStuckEnd
    );
    this.frameVideoStream.current[method]("resize", this.onFrameSizeChange);
    this.videoStreamListenersSet = active;
  };

  clearDirectStream = () => {
    if (!this.frameVideoStream.current) {
      return;
    }
    this.setEventListeners(false);
    // if (destroy) {
    //   this.frameVideoStream.current._destroy(new CustomEvent("destroy"));
    // }
  };

  restartStream = () => {
    if (!this.frameVideoStream.current) {
      return;
    }
    this.frameVideoStream.current.restartStream();
  };

  destroy = () => {
    this.frameVideoStream.current.destroy(new CustomEvent("destroy"));
  };

  play = () => {
    if (!this.frameVideoStream.current || !this.props.videoConnection) {
      return;
    }
    if (!this.videoStreamListenersSet) {
      this.setEventListeners();
    }
    if (this.stopped) {
      this.frameVideoStream.current.dispatchEvent(
        new CustomEvent("start", {
          detail: { videoConnection: this.props.videoConnection }
        })
      );
      this.props.videoConnection.open();
    } else if (this.frameVideoStream.current.shadowRoot.firstElementChild) {
      this.frameVideoStream.current.shadowRoot.firstElementChild.play();
    }
    this.stopped = false;
  };

  pause = () => {
    if (
      this.stopped ||
      !this.frameVideoStream.current ||
      !this.frameVideoStream.current.shadowRoot.firstElementChild
    ) {
      return;
    }
    this.frameVideoStream.current.shadowRoot.firstElementChild.pause();
    this.frameVideoStream.current.dispatchEvent(
      new CustomEvent("pause", {
        detail: { videoConnection: this.props.videoConnection }
      })
    );
  };

  stop = () => {
    if (
      this.stopped ||
      !this.frameVideoStream.current ||
      !this.frameVideoStream.current.shadowRoot.firstElementChild
    ) {
      return;
    }
    this.clearDirectStream();
    this.stopped = true;
  };

  render() {
    const { id, camera, hideWithoutConnection, videoConnection } = this.props;

    return hideWithoutConnection && !videoConnection ? null : (
      <videos-stream
        id={`canvas-${camera.id}`}
        class="camera-canvas"
        cameraId={id}
        name={camera.name}
        ref={this.frameVideoStream}
      />
    );
  }
}

CameraVideoStream.propTypes = {
  id: PropTypes.string,
  camera: PropTypes.object,
  videoConnection: PropTypes.object,
  getSnapshotGetter: PropTypes.func,
  onStreamLoad: PropTypes.func,
  onDirectStreamFail: PropTypes.func,
  setVideoStuck: PropTypes.func,
  hideWithoutConnection: PropTypes.bool,
  setAspectRatio: PropTypes.func,
  onRestartStream: PropTypes.func
};

CameraVideoStream.defaultProps = {
  id: null,
  camera: null
};

export default CameraVideoStream;
