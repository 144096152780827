import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Modal } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";

const { Item } = Form;

const SaveModal = ({
  form,
  modal,
  entity,
  visible,
  toggle,
  handleSubmit,
  intl,
  loading,
  ...rest
}) => {
  const { getFieldDecorator, resetFields } = form;
  const saveAs = modal === "saveAs";
  const modalTitle = saveAs
    ? messages.saveAsModalTitle
    : modal === "rename"
    ? messages.renameModalTitle
    : messages.saveModalTitle;
  const { name, type } = entity;
  return (
    <Modal
      title={intl.formatMessage(modalTitle, { type })}
      visible={visible}
      footer={null}
      centered={true}
      closable={false}
      className="confirm-modal"
      {...rest}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
          form.validateFields((err, values) => {
            if (!err) {
              handleSubmit({
                ...entity,
                name: values.name,
                id: saveAs ? null : entity.id
              });
              resetFields();
            }
          });
        }}
        className="form-dark"
        layout="vertical"
      >
        <Item
          label={intl.formatMessage(messages.saveModalInputLabel, { type })}
        >
          {getFieldDecorator("name", { initialValue: name ? name : "" })(
            <Input
              required={true}
              placeholder={
                name ? name : intl.formatMessage(messages.titleNew, { type })
              }
            />
          )}
        </Item>
        <div className="form-actions">
          <Button onClick={() => toggle()}>
            <FormattedMessage {...messages.cancelBtn} />
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            <FormattedMessage {...messages.submitBtn} />
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

SaveModal.propTypes = {
  modalTitle: PropTypes.node,
  inputLabel: PropTypes.node,
  entity: PropTypes.object,
  visible: PropTypes.bool,
  toggle: PropTypes.func,
  handleSubmit: PropTypes.func,
  placeholder: PropTypes.object,
  loading: PropTypes.bool
};

SaveModal.defaultProps = {
  loading: false,
  visible: false
};

export default injectIntl(Form.create()(SaveModal));
