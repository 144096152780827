export const CONNECT = "auth/CONNECT";
export const CONNECT_ERROR = "auth/CONNECT_ERROR";
export const CONNECT_SUCCESS = "auth/CONNECT_SUCCESS";
export const CONNECT_REQUEST_SUCCESS = "auth/CONNECT_REQUEST_SUCCESS";
export const CONNECT_CHANGE = "auth/CONNECT_CHANGE";
export const LOGIN = "auth/LOGIN";
export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_ERROR = "auth/LOGIN_ERROR";
export const LOGOUT = "api/LOGOUT";
export const LOGOUT_SUCCESS = "auth/LOGOUT_SUCCESS";

export const SET_ACTIVE_CAMERA = "player/SET_ACTIVE_CAMERA";
export const MOVE_CAMERA = "player/MOVE_CAMERA";

export const FETCH_VIEW = "api/FETCH_VIEW";
export const FETCH_ALL_VIEWS = "api/FETCH_ALL_VIEWS";
export const FETCH_ALL_VIEWS_SUCCESS = "views/FETCH_ALL_VIEWS_SUCCESS";
export const FETCH_ALL_VIEWS_ERROR = "views/FETCH_ALL_VIEWS_ERROR";
export const FETCH_VIEW_RESPONSE = "view/FETCH_VIEW_RESPONSE";
export const SET_LAYOUT_COLUMNS = "view/SET_LAYOUT_COLUMNS";
export const REORDER_LAYOUT = "view/REORDER_LAYOUT";

export const FETCH_CUSTOM_VIEWS = "customView/FETCH_CUSTOM_VIEWS";
export const FETCH_CUSTOM_VIEWS_SUCCESS =
  "customView/FETCH_CUSTOM_VIEWS_SUCCESS";
export const FETCH_CUSTOM_VIEWS_ERROR = "customView/FETCH_CUSTOM_VIEWS_ERROR";
export const FETCH_CUSTOM_VIEW = "customView/FETCH_CUSTOM_VIEW";
export const FETCH_CUSTOM_VIEW_RESPONSE =
  "customView/FETCH_CUSTOM_VIEW_RESPONSE";
export const ADD_VIEW_CAMERA = "customView/ADD_CAMERA";
export const REMOVE_VIEW_CAMERA = "customView/REMOVE_CAMERA";
export const SET_CUSTOM_LAYOUT_COLUMNS = "customView/SET_LAYOUT_COLUMNS";
export const REORDER_CUSTOM_LAYOUT = "customView/REORDER_LAYOUT";
export const SAVING_VIEW = "customView/SAVING_VIEW";
export const UPDATE_VIEW = "customView/UPDATE_VIEW";
export const UPDATE_VIEW_SUCCESS = "customView/UPDATE_VIEW_SUCCESS";
export const UPDATE_VIEW_ERROR = "customView/UPDATE_VIEW_ERROR";
export const CREATE_VIEW = "customView/CREATE_VIEW";
export const CREATE_VIEW_SUCCESS = "customView/CREATE_VIEW_SUCCESS";
export const CREATE_VIEW_ERROR = "customView/CREATE_VIEW_ERROR";
export const DELETE_VIEW = "customView/DELETE_VIEW";
export const DELETE_VIEW_SUCCESS = "customView/DELETE_VIEW_SUCCESS";
export const DELETE_VIEW_ERROR = "customView/DELETE_VIEW_ERROR";
export const SET_VIEW_NOT_MODIFIED = "customView/SET_VIEW_NOT_MODIFIED";

export const GET_THUMBNAILS = "thumbnails/GET_THUMBNAILS";
export const SET_THUMBNAIL_PENDING = "thumbnails/SET_THUMBNAIL_PENDING";
export const SET_THUMBNAIL_QUEUED = "thumbnails/SET_THUMBNAIL_QUEUED";
export const FETCH_THUMBNAIL_RESPONSE = "thumbnails/FETCH_THUMBNAIL_RESPONSE";
export const FETCH_THUMBNAIL_ERROR = "thumbnails/FETCH_THUMBNAIL_ERROR";

export const FETCH_ALL_INVESTIGATIONS = "api/FETCH_ALL_INVESTIGATIONS";
export const FETCH_ALL_INVESTIGATIONS_RESPONSE =
  "investigations/FETCH_ALL_INVESTIGATIONS_RESPONSE";
export const FETCH_ALL_INVESTIGATIONS_ERROR =
  "api/FETCH_ALL_INVESTIGATIONS_ERROR";
export const SORT_INVESTIGATIONS = "investigations/SORT_INVESTIGATIONS";
export const SET_INVESTIGATION_STATUS =
  "investigations/SET_INVESTIGATION_STATUS";

export const FETCH_INVESTIGATION = "api/FETCH_INVESTIGATION";
export const FETCH_INVESTIGATION_RESPONSE =
  "investigation/FETCH_INVESTIGATION_RESPONSE";
export const UPDATING_INVESTIGATION = "investigations/UPDATING_INVESTIGATION";
export const UPDATE_INVESTIGATION_SUCCESS =
  "investigations/UPDATE_INVESTIGATION_SUCCESS";
export const UPDATE_INVESTIGATION_ERROR =
  "investigations/UPDATE_INVESTIGATION_ERROR";
export const CREATE_NEW_INVESTIGATION =
  "investigation/CREATE_NEW_INVESTIGATION";
export const SAVING_NEW_INVESTIGATION =
  "investigation/SAVING_NEW_INVESTIGATION";
export const CREATE_INVESTIGATION_SUCCESS =
  "investigations/CREATE_INVESTIGATION_SUCCESS";
export const CREATE_INVESTIGATION_ERROR =
  "investigations/CREATE_INVESTIGATION_ERROR";
export const SAVE_INVESTIGATION = "api/SAVE_INVESTIGATION";
export const DELETE_INVESTIGATION_SUCCESS = "api/DELETE_INVESTIGATION_SUCCESS";
export const DELETE_INVESTIGATION_ERROR = "api/DELETE_INVESTIGATION_ERROR";
export const ADD_INVESTIGATION_CAMERA = "INVESTIGATION/ADD_CAMERA";
export const REMOVE_INVESTIGATION_CAMERA = "INVESTIGATION/REMOVE_CAMERA";
export const SORT_INVESTIGATION_CAMERAS =
  "INVESTIGATION/SORT_INVESTIGATION_CAMERAS";

export const START_EXPORT = "api/START_EXPORT";
export const CANCEL_EXPORT = "api/CANCEL_EXPORT";
export const START_EXPORT_SUCCESS = "api/START_EXPORT_SUCCESS";
export const START_EXPORT_FAILURE = "api/START_EXPORT_FAILURE";
export const DELETE_EXPORT = "api/DELETE_EXPORT";
export const DELETE_EXPORT_SUCCESS = "api/DELETE_EXPORT_SUCCESS";
export const DELETE_EXPORT_FAILURE = "api/DELETE_EXPORT_FAILURE";
export const FETCH_ALL_EXPORTS = "api/FETCH_ALL_EXPORTS";
export const FETCH_ALL_EXPORTS_SUCCESS = "api/FETCH_ALL_EXPORTS_SUCCESS";
export const FETCH_ALL_EXPORTS_FAILURE = "api/FETCH_ALL_EXPORTS_FAILURE";
export const FETCH_EXPORT = "api/FETCH_EXPORT";
export const FETCH_EXPORT_SUCCESS = "api/FETCH_EXPORT_SUCCESS";
export const FETCH_EXPORT_FAILURE = "api/FETCH_EXPORT_FAILURE";

export const LOAD_SEARCH_CAMERAS_THUMBNAILS =
  "cameraSearch/LOAD_SEARCH_CAMERAS_THUMBNAILS";
export const SEARCH_CAMERAS = "cameraSearch/SEARCH_CAMERAS";
export const GET_SEARCH_CAMERAS_RESULTS =
  "cameraSearch/GET_SEARCH_CAMERAS_RESULTS";

export const TOGGLE_MODAL = "utility/TOGGLE_MODAL";
export const SET_OPEN_PANEL = "utility/SET_OPEN_PANEL";
export const TOGGLE_WEB_SOCKET = "utility/TOGGLE_WEB_SOCKET";
export const TOGGLE_DIRECT_STREAMING = "utility/TOGGLE_DIRECT_STREAMING";

export const TOGGLE_SEARCH = "search/TOGGLE_SEARCH";
export const SEARCH = "search/SEARCH";
export const GET_SEARCH_RESULTS = "search/GET_SEARCH_RESULTS";

export const GET_PROMOTIONS = "utility/GET_PROMOTIONS";
export const GET_PROMOTIONS_SUCCESS = "utility/GET_PROMOTIONS_SUCCESS";
export const GET_PROMOTIONS_ERROR = "utility/GET_PROMOTIONS_ERROR";
export const GET_PROMOTIONS_ITEM = "utility/GET_PROMOTIONS_ITEM";
export const GET_PROMOTIONS_ITEM_SUCCESS =
  "utility/GET_PROMOTIONS_ITEM_SUCCESS";
export const GET_PROMOTIONS_ITEM_ERROR = "utility/GET_PROMOTIONS_ITEM_ERROR";
