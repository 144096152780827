import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import Icon from "../../Common/Icon";
import messages from "../messages";
import { bindActionCreators } from "redux";
import * as player_actions from "../../../actions/playerActions";
import connect from "react-redux/es/connect/connect";
import { ptzActions as ptz } from "../../../utils/schemas";

class CameraOverlay extends PureComponent {
  componentDidMount() {
    this.props.actions.ptzInit(this.props.id, this.props.videoConnection);
  }

  render() {
    const { id, actions, videoConnection, preset } = this.props;
    const { ptzMove, ptzZoom, ptzPreset } = actions;
    const prevDef = f => e => {
      e.preventDefault();
      e.stopPropagation();
      f && f(e);
    };

    return (
      <div className="camera-player-overlay">
        <div className="camera-player-ptz">
          <Button
            className="camera-player-ptz-btn btn-ptz-up"
            type="link"
            onClick={prevDef(() => ptzMove(id, videoConnection, ptz.move.up))}
          >
            <Icon type="up-arrow-thin" title={messages.up} />
          </Button>
          <Button
            className="camera-player-ptz-btn btn-ptz-left"
            type="link"
            onClick={prevDef(() => ptzMove(id, videoConnection, ptz.move.left))}
          >
            <Icon type="left-arrow-thin" title={messages.left} />
          </Button>
          <Button
            className="camera-player-ptz-btn btn-ptz-right"
            type="link"
            onClick={prevDef(() =>
              ptzMove(id, videoConnection, ptz.move.right)
            )}
          >
            <Icon type="right-arrow-thin" title={messages.right} />
          </Button>
          <Button
            className="camera-player-ptz-btn btn-ptz-down"
            type="link"
            onClick={prevDef(() => ptzMove(id, videoConnection, ptz.move.down))}
          >
            <Icon type="down-arrow-thin" title={messages.down} />
          </Button>
          {preset && (
            <div className="camera-player-ptz-preset">
              <Button
                className="camera-player-ptz-btn btn-ptz-preset"
                type="link"
                onClick={prevDef(() => ptzPreset(id, videoConnection))}
              >
                <Icon type="movement-controls" title={messages.goToPreset} />
              </Button>
            </div>
          )}
          <div className="camera-player-ptz-zoom">
            <Button
              className="camera-player-ptz-btn btn-ptz-zoom-in"
              type="link"
              onClick={prevDef(() => ptzZoom(id, videoConnection, ptz.zoom.in))}
            >
              <Icon type="plus-square" title={messages.zoomIn} />
            </Button>
            <Button
              className="camera-player-ptz-btn btn-ptz-zoom-out"
              type="link"
              onClick={prevDef(() =>
                ptzZoom(id, videoConnection, ptz.zoom.out)
              )}
            >
              <Icon type="minus-square" title={messages.zoomOut} />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

CameraOverlay.propTypes = {
  id: PropTypes.string.isRequired,
  preset: PropTypes.bool,
  actions: PropTypes.object,
  videoConnection: PropTypes.object.isRequired
};

const mapStateToProps = ({ cameras, utility }) => {
  return { cameras, utility };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(player_actions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraOverlay);
