import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { Spin, Tooltip } from "antd";
import CameraHeader from "../CameraHeader";
import ErrorMessage from "../../Common/ErrorMessage";
import { LazyLoadFrame } from "../../../utils/outOfViewportCheck";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions/thumbnailActions";
import { THUMBNAIL_REQUEST_MAX_ATTEMPTS } from "../../../utils/thumbnailFetchQueue";
import messages from "../../Common/ErrorMessage/messages";
import { getEnvVar } from "../../../utils/env";
import CameraVideoStream from "../CameraVideoStream";
import { PLAYER_MODE_LIVE } from "../constants";
import XPMobileSDK from "../../../utils/api";
import AntIcon from "@ant-design/icons-react";

const THUMBNAIL_MAX_AGE = getEnvVar(
  "THUMBNAIL_MAX_AGE",
  1000 * 60 * 5 // ms
);

class CameraFeedThumbnail extends LazyLoadFrame {
  constructor(props) {
    super(props);

    this.state = {
      ...(this.state || {}),
      loading: false,
      error: null,
      frame: null,
      thumbnail: null,
      stream: null,
      connectionLost: false,
      useFallbackRender:
        !props.websocketsEnabled || !props.directStreamingEnabled
    };

    this.frame = React.createRef();
    this.frameCanvas = React.createRef();
    this.frameStream = React.createRef();
    this.lastRenderedFrameNumber = -1;
    this.timer = null;
  }
  frame = null;
  frameCanvas = null;
  frameStream = null;
  canvasContext = null;
  frameImage = null;
  lastRenderedFrameNumber = -1;
  maxWidthRule = null;
  aspectRatio = null;
  selectorText =
    ".camera-player-page .camera-player-single .camera-player-content";
  didSoftRestart = false;

  componentDidMount() {
    super.componentDidMount();
    this.getAspectRatioCssRule();
    if (this.frameCanvas.current) {
      this.canvasContext = this.frameCanvas.current.getContext("2d");
    }
    if (this.shouldShowVideo()) {
      this.handleRequestStream();
    }
  }
  componentWillUnmount() {
    super.componentWillUnmount();
    this.destroyStream();
    // this.frameStream.current && this.frameStream.current.destroy();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (
      this.props.camera &&
      (!prevProps.camera || prevProps.camera.id !== this.props.camera.id)
    ) {
      this.forceReconnect();
    } else if (
      prevProps.websocketsEnabled !== this.props.websocketsEnabled ||
      prevProps.directStreamingEnabled !== this.props.directStreamingEnabled
    ) {
      this.toggleFallbackRender(
        !this.props.websocketsEnabled || !this.props.directStreamingEnabled
      );
    } else if (
      this.shouldShowVideo(prevProps, prevState) &&
      !this.shouldShowVideo()
    ) {
      this.handlePauseStream();
    } else if (
      !this.shouldShowVideo(prevProps, prevState) &&
      this.shouldShowVideo()
    ) {
      this.state.stream
        ? this.handleResumeStream()
        : this.handleRequestStream();
    }
    if (
      this.props.size !== prevProps.size &&
      this.state.stream &&
      this.state.stream.videoConnection
    ) {
      const { clientWidth } =
        this.frame.current ||
        this.frameStream.current ||
        this.frameCanvas.current ||
        {};
      if (!clientWidth) {
        return;
      }
      const width = Math.max(360, clientWidth);
      requestIdleCallback(() =>
        XPMobileSDK.changeStream(
          this.state.stream.videoConnection,
          {},
          {
            width: width || 670,
            height: (380 / 670) * (width || 670)
          }
        )
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { stream: curStream = {}, ...curState } = this.state;
    const { stream: nxtStream = {}, ...nxtState } = nextState;
    // eslint-disable-next-line no-unused-vars
    const { actions: _, index, children, ...curProps } = this.props;
    const {
      // eslint-disable-next-line no-unused-vars
      actions: __,
      // eslint-disable-next-line no-unused-vars
      index: ___,
      children: nextChildren,
      ...nxtProps
    } = nextProps;
    let ret = false;
    if (
      JSON.stringify(nxtProps) !== JSON.stringify(curProps) ||
      children !== nextChildren
    ) {
      // console.log(`props actually changed (${index})`);
      ret = true;
    }
    if (JSON.stringify(nxtState) !== JSON.stringify(curState)) {
      // console.log(`state actually changed (${index})`);
      ret = true;
    }
    if (
      curStream !== nxtStream ||
      (curStream && curStream.videoConnection) !==
        (nxtStream && nxtStream.videoConnection)
    ) {
      // console.log(`stream changed (${index})`);
      ret = true;
    }
    return ret;
  }

  needToFetchContent = () =>
    this.props.auth.authenticated &&
    this.state.frameInitialized &&
    !this.state.outOfView &&
    this.props.thumbnail &&
    (this.props.thumbnail.image
      ? this.props.thumbnail.timestamp - Date.now() > THUMBNAIL_MAX_AGE
      : this.props.thumbnail.attempts == null ||
        this.props.thumbnail.attempts < THUMBNAIL_REQUEST_MAX_ATTEMPTS);

  shouldLazyFetch = () =>
    this.props.auth.authenticated &&
    this.state.frameInitialized &&
    this.state.outOfView &&
    this.props.thumbnail &&
    (this.props.thumbnail.image
      ? this.props.thumbnail.timestamp - Date.now() > THUMBNAIL_MAX_AGE
      : this.props.thumbnail.attempts == null ||
        this.props.thumbnail.attempts < THUMBNAIL_REQUEST_MAX_ATTEMPTS);

  isFetching = (props = this.props) =>
    props.thumbnail && (props.thumbnail.queued || props.thumbnail.pending);

  fetchContent = (lazy = false) => {
    this.props.camera &&
      this.props.camera.id &&
      this.props.thumbnail &&
      this.props.thumbnail.id &&
      this.props.actions.fetchThumbnail(this.props.thumbnail.id, lazy);
  };

  cancelFetchContent = (enqueueLazy = true) => {
    this.props.camera &&
      this.props.camera.id &&
      this.props.thumbnail &&
      this.props.thumbnail.id &&
      this.props.actions.cancelFetchThumbnail(
        this.props.thumbnail.id,
        enqueueLazy
      );
  };

  fetchFinished = prevProps =>
    this.fetching && this.isFetching(prevProps) && !this.isFetching();

  shouldShowVideo = (props = this.props, state = this.state) =>
    !state.outOfView && props.link;

  getFrameId = () =>
    this.props.thumbnail && !this.props.disableLazyLoad
      ? `thumbnail/${this.props.thumbnail.id}`
      : undefined;

  handleRequestStream = () => {
    const { camera } = this.props;
    if (!camera) {
      return null;
    }

    const { stream, useFallbackRender } = this.state;
    const { clientWidth } =
      this.frame.current ||
      this.frameStream.current ||
      this.frameCanvas.current ||
      {};
    const width = Math.max(360, clientWidth);
    const destination = {
      width: width || 670,
      height: (380 / 670) * (width || 670)
    };
    const signal = PLAYER_MODE_LIVE;

    // Get stream for the camera.
    const { id } = camera;

    if (!stream || !stream.videoConnection) {
      this.lastRenderedFrameNumber = -1;
      const videoConnectionObserver = {
        videoConnectionReceivedFrame: frame => this.handleReceiveFrame(frame)
      };
      const streamRequest = XPMobileSDK.requestStream(
        id,
        destination,
        {
          reuseConnection: true,
          signal,
          streamType: !useFallbackRender
            ? XPMobileSDK.library.VideoConnectionStream.FragmentedMP4
            : undefined
        },
        videoConnection => {
          const is_direct_stream =
            videoConnection.response.parameters.StreamType === "FragmentedMP4";
          if (this.frameCanvas.current) {
            this.canvasContext = this.frameCanvas.current.getContext("2d");
          }
          this.setStateIfMounted(
            {
              loading: true,
              stream: {
                videoConnection: videoConnection,
                videoConnectionObserver,
                destination
              },
              useFallbackRender: !is_direct_stream,
              videoStuck: is_direct_stream
            },
            () => {
              if (!is_direct_stream) {
                this.setLostConnectionTimer(-1);
              }
            }
          );
          if (!is_direct_stream) {
            videoConnection.addObserver(videoConnectionObserver);
            videoConnection.open();
          }
        },
        error => {
          this.setStateIfMounted({ error });
        }
      );

      this.setStateIfMounted({
        loading: true,
        stream: {
          ...this.state.stream,
          streamRequest
        }
      });
    }
  };

  handlePauseStream = () => {
    if (!this.state.stream) {
      return;
    }
    if (!this.props.websocketsEnabled) {
      return this.handleStopStream();
    }

    if (!this.state.useFallbackRender) {
      requestIdleCallback(() => {
        if (
          this.frameStream.current &&
          this.mounted &&
          !this.shouldShowVideo()
        ) {
          this.frameStream.current.pause();
        }
      });
      return;
    }

    const { videoConnection, videoConnectionObserver } = this.state.stream;

    if (videoConnection && videoConnectionObserver) {
      this.lastRenderedFrameNumber = -1;
      videoConnection.removeObserver(videoConnectionObserver);
      if (!this.state.useFallbackRender) {
        videoConnection.close();
      }
    }
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  handleResumeStream = () => {
    if (!this.state.stream) {
      this.handleRequestStream();
      return;
    }
    if (this.frameCanvas.current) {
      this.canvasContext = this.frameCanvas.current.getContext("2d");
    }

    if (!this.state.useFallbackRender) {
      requestIdleCallback(() => {
        if (
          this.frameStream.current &&
          this.mounted &&
          this.shouldShowVideo()
        ) {
          this.frameStream.current.play();
        }
      });
      return;
    }

    const { videoConnection, videoConnectionObserver } = this.state.stream;

    if (videoConnection) {
      videoConnection.addObserver(videoConnectionObserver);
      if (!this.state.useFallbackRender) {
        videoConnection.open();
      }
      this.lastRenderedFrameNumber = -1;
      this.setLostConnectionTimer(-1);
    }
  };

  handleStopStream = callback => {
    if (!this.state.stream) {
      return null;
    }

    const { videoConnection, videoConnectionObserver } = this.state.stream;
    if (videoConnection && videoConnectionObserver) {
      videoConnection.removeObserver(videoConnectionObserver);
    }

    requestIdleCallback(() => {
      if (
        videoConnection &&
        videoConnection.isReusable &&
        videoConnection.getState() !==
          XPMobileSDK.library.VideoConnectionState.closed
      ) {
        videoConnection.close();
        callback && callback();
      } else {
        this.destroyStream(callback);
      }
    });
  };

  destroyStream = callback => {
    if (!this.state.stream) {
      return null;
    }
    const {
      videoConnection,
      streamRequest,
      videoConnectionObserver
    } = this.state.stream;

    if (videoConnection) {
      if (videoConnectionObserver) {
        videoConnection.removeObserver(videoConnectionObserver);
      }
      if (
        videoConnection.getState() !==
        XPMobileSDK.library.VideoConnectionState.closed
      ) {
        videoConnection.close();
      }
    }

    if (streamRequest) {
      XPMobileSDK.cancelRequest(streamRequest);
    }
    if (this.frameStream.current) {
      this.frameStream.current.destroy();
    }

    this.frameImage = null;
    this.lastRenderedFrameNumber = -1;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.setStateIfMounted(
      {
        stream: null
      },
      callback
    );
  };

  handleReceiveFrame = (newFrame = null) => {
    const { blob, frameNumber } = newFrame;

    if (!blob || !this.frameCanvas.current || !this.state.useFallbackRender) {
      return null;
    }

    if (frameNumber !== this.lastRenderedFrameNumber) {
      if (this.state.connectionLost) {
        this.setStateIfMounted({ connectionLost: false });
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(
        this.checkLostConnection.bind(this, frameNumber),
        process.env.NEXT_FRAME_WAIT_TIME || 10000
      );
    }

    const { width, height } = this.frameCanvas.current;

    const image = new Image();
    image.addEventListener("load", () => {
      if (this.canvasContext && frameNumber > this.lastRenderedFrameNumber) {
        this.lastRenderedFrameNumber = frameNumber;
        this.resetImage();
        this.canvasContext.drawImage(image, 0, 0, width, height);
        if (!this.frameImage) {
          this.frameImage = image;
          this.forceUpdate();
        } else {
          this.frameImage = image;
        }
      }
    });
    image.setAttribute("src", window.URL.createObjectURL(blob));
  };

  resetImage = () => {
    if (this.frameImage && this.frameImage.src) {
      window.URL.revokeObjectURL(this.frameImage.src);
    }
  };

  getAspectRatioCssRule = () => {
    try {
      const sheet = Object.values(document.styleSheets).find(
        s =>
          s.href.startsWith(`${location.origin}/main`) || s.rules.length > 100
      );
      const rules = Object.values(sheet.rules || sheet.cssRules || {});
      const maxWidthRule = rules.find(
        r =>
          r &&
          r.selectorText === this.selectorText &&
          r.cssText.search(/max-width: calc/i) !== -1
      );
      if (maxWidthRule) {
        this.maxWidthRule = maxWidthRule.style.maxWidth;
      } else {
        // eslint-disable-next-line no-console
        console.error(`Failed to find CSS rule for frame width.`);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Failed to get CSS rule for frame width:`, e);
    }
  };

  setAspectRatio = ratio => {
    if (
      !this.frame ||
      !this.frame.current ||
      !this.maxWidthRule ||
      isNaN(ratio) ||
      ratio === this.aspectRatio ||
      this.maxWidthRule.search(/^calc\(.* \/ 0\.715\)$/) === -1
    ) {
      return;
    }

    this.aspectRatio = ratio;
    this.frame.current.setAttribute(
      "style",
      `max-width: ${this.maxWidthRule.replace(" / 0.715)", ` / ${ratio})`)};`
    );
  };

  getThumbnail = () => {
    const { camera, showSpinner, width, height, thumbnail } = this.props;
    const { stream, useFallbackRender, loading, videoStuck } = this.state;
    const { videoConnection } = this.state.stream || {};

    if (!camera) {
      return null;
    }

    const { name = "Camera Thumbnail" } = camera;
    const image =
      thumbnail.image ||
      camera.image ||
      (this.frameCanvas.current
        ? this.frameCanvas.current.toDataURL("image/png")
        : undefined);
    const error = this.shouldShowVideo()
      ? this.state.error
      : thumbnail.error || camera.error;
    const is_loading =
      !error &&
      (this.shouldShowVideo()
        ? useFallbackRender
          ? !(stream && stream.videoConnection && this.frameImage) &&
            !this.state.connectionLost
          : loading || videoStuck
        : !image);
    const aspectRatio =
      width > 0 && height > 0 ? Math.round((height / width) * 100, 2) : null;
    const style = aspectRatio
      ? { height: 0, paddingBottom: `${aspectRatio}%` }
      : {};

    width > 0 && height > 0 && this.setAspectRatio(height / width);

    return (
      <>
        <div
          className={classnames("camera-image", {
            "camera-player-content": this.shouldShowVideo()
          })}
          ref={this.frame}
          style={style}
        >
          {image ? <img src={image} draggable={false} alt={name} /> : null}
          {useFallbackRender ? (
            <canvas
              ref={this.frameCanvas}
              className="camera-canvas"
              id={`canvas-${camera.id}`}
              width={image && image.width ? image.width : 643}
              height={image && image.height ? image.height : 445}
            />
          ) : (
            <CameraVideoStream
              ref={this.frameStream}
              key={camera.id}
              id={camera.id}
              camera={camera}
              videoConnection={videoConnection}
              onStreamLoad={() => {
                this.setStateIfMounted({ loading: false });
              }}
              onDirectStreamFail={() => this.toggleFallbackRender(true)}
              setVideoStuck={this.setVideoStuck}
              hideWithoutConnection={true}
            />
          )}
          {showSpinner && is_loading && !this.state.connectionLost ? (
            <Spin />
          ) : null}
        </div>
        {this.state.connectionLost && (
          <div className="camera-frame__reconnect">
            <ErrorMessage
              error={{ code: "CAMERA_CONNECTION_LOST" }}
              text={messages.lostConnection}
            />
            {!this.state.loading ? (
              <Tooltip title="Force Reconnect">
                <button
                  className="camera-frame__reconnect__button"
                  type="button"
                  onClick={this.forceReconnect}
                >
                  <AntIcon
                    type="redo-o"
                    theme="outline"
                    style={{ fill: "#fff", color: "#fff", stroke: "#000" }}
                  />
                </button>
              </Tooltip>
            ) : (
              <Spin />
            )}
          </div>
        )}
        {error && !is_loading && !camera.image ? (
          <div className="camera-thumbnail-error">
            <ErrorMessage
              error={error}
              text={
                error.code === 32 ? messages.noContent : messages.failedToLoad
              }
            />
          </div>
        ) : null}
      </>
    );
  };

  setLostConnectionTimer = frameNumber => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    const { stream: { videoConnection } = {} } = this.state;
    if (
      videoConnection &&
      videoConnection.response.parameters.StreamType === "FragmentedMP4"
    ) {
      this.timer = setTimeout(() => {
        this.setStateIfMounted({ connectionLost: true });
        clearTimeout(this.timer);
        this.timer = null;
      }, process.env.NEXT_FRAME_WAIT_TIME || 10000);
    } else {
      this.timer = setTimeout(
        this.checkLostConnection.bind(this, frameNumber),
        process.env.NEXT_FRAME_WAIT_TIME || 10000
      );
    }
  };

  checkLostConnection = frameNumber => {
    if (frameNumber === this.lastRenderedFrameNumber) {
      this.setStateIfMounted({ connectionLost: true });
    }
    clearTimeout(this.timer);
    this.timer = null;
  };

  setVideoStuck = value => {
    if (this.state.videoStuck === value) {
      return;
    }
    if (value && !this.state.connectionLost) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.setStateIfMounted({ connectionLost: true });
        clearTimeout(this.timer);
        this.timer = null;
      }, process.env.NEXT_FRAME_WAIT_TIME || 10000);
    } else if (!value) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    }
    // eslint-disable-next-line no-console
    // console.warn(`video ${value ? "" : "un"}stuck`, event);
    this.setStateIfMounted({
      videoStuck: value,
      connectionLost: !value ? false : this.state.connectionLost
    });
  };

  forceReconnect = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (
      this.state.useFallbackRender ||
      this.didSoftRestart ||
      !this.frameStream.current
    ) {
      this.destroyStream(() => {
        this.setStateIfMounted({ loading: true }, () => {
          setTimeout(() => {
            this.shouldShowVideo() && this.handleRequestStream();
          }, 1500);
        });
      });
    } else {
      this.didSoftRestart = true;
      this.setStateIfMounted({ loading: true }, () => {
        this.frameStream.current.restartStream();
      });
    }
  };

  toggleFallbackRender = enabled => {
    if (!this.state.stream) {
      this.setState({ useFallbackRender: enabled, loading: true });
      return;
    }
    this.destroyStream(() => {
      this.setState({ useFallbackRender: enabled, loading: true }, () =>
        setTimeout(() => {
          this.mounted && this.shouldShowVideo() && this.handleRequestStream();
        }, 1500)
      );
    });
  };

  render() {
    const { id, viewId, active, children, link, theme, camera } = this.props;
    const { useFallbackRender } = this.state;

    const thumbnail = this.getThumbnail();
    const classNames = classnames(
      "camera",
      `camera-theme-${theme}`,
      { "camera--active": active },
      { "camera--no-image": !thumbnail },
      { "camera-has-video": true },
      {
        "camera-is-playing":
          this.shouldShowVideo() && (useFallbackRender ? this.frameImage : true)
      }
    );

    const content = (
      <div className="camera-thumbnail camera-feed-thumbnail">
        <div className="camera-thumbnail-content">
          {camera && camera.name ? (
            <CameraHeader name={camera.name} mode={PLAYER_MODE_LIVE} />
          ) : null}
          {thumbnail}
        </div>
        {children ? <div className="camera-content">{children}</div> : null}
      </div>
    );

    const cameraId = !id && camera ? camera.id : id;
    const cameraViewId = !viewId && camera ? camera.viewId : viewId;
    return (
      <article
        ref={this.frame}
        id={cameraId}
        className={classnames(classNames)}
      >
        {link && cameraId && cameraViewId ? (
          <NavLink
            className="camera-link"
            to={`/cameras/${cameraViewId}/${cameraId}`}
            isActive={() => active}
          >
            {content}
          </NavLink>
        ) : (
          content
        )}
      </article>
    );
  }
}

CameraFeedThumbnail.propTypes = {
  id: PropTypes.string,
  viewId: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
  link: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showHeader: PropTypes.bool,
  theme: PropTypes.string,
  camera: PropTypes.object,
  showSpinner: PropTypes.bool,
  auth: PropTypes.object,
  thumbnail: PropTypes.object,
  websocketsEnabled: PropTypes.bool,
  directStreamingEnabled: PropTypes.bool,
  useDirectStream: PropTypes.bool,
  size: PropTypes.any
};

CameraFeedThumbnail.defaultProps = {
  id: "",
  viewId: "",
  active: false,
  children: null,
  link: false,
  error: null,
  showHeader: true,
  theme: "light",
  camera: null,
  showSpinner: false,
  useDirectStream: true
};

const mapStateToProps = (state, props) => ({
  auth: state.auth,
  websocketsEnabled: state.utility.enableWebsocket,
  directStreamingEnabled: state.utility.enableDirectStreaming,
  thumbnail:
    props.camera && props.camera.id
      ? state.thumbnails.items.find(t => t.id === props.camera.id) || {
          id: props.camera.id,
          image: null
        }
      : undefined
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraFeedThumbnail);
