import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Layout, List } from "antd";
import withData from "../DataWrapper";
import { getViews, getCameras } from "../../selectors";
import * as actions from "../../actions/searchActions";
import PageHeader from "../Common/PageHeader";
import Spinner from "../Common/Spinner";
import CameraCard from "../Cameras/CameraCard";
import CameraThumbnail from "../Cameras/CameraThumbnail";
import messages from "./messages";
import { getSearch } from "../../utils/queryString";

class SearchPage extends Component {
  componentDidMount() {
    if (this.props.ready) {
      this.getResults();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.searchTerm !== prevProps.searchTerm ||
      (this.props.ready && !prevProps.ready) ||
      (this.props.searchTerm &&
        this.props.searchItems.cameras.length !==
          prevProps.searchItems.cameras.length)
    ) {
      this.getResults();
    }
  }

  getResults = () => {
    const {
      searchTerm,
      searchItems,
      actions: { getResults }
    } = this.props;
    const results = Object.keys(searchItems).map(group => {
      if (!searchTerm) {
        return { group, items: [] };
      }

      const items = searchItems[group].filter(
        item => item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      );
      return { group, items };
    });

    getResults(results);
  };

  renderSearchResults = ({ group, items }) => (
    <List
      key={group}
      className="search-results-group"
      header={
        <h2 className="search-results-group-header">
          <FormattedMessage
            {...messages.resultsListHeader}
            values={{ count: items.length, group: _.capitalize(group) }}
          />
        </h2>
      }
      locale={{
        emptyText: "No results"
      }}
      dataSource={items}
      grid={{ gutter: 15, column: group === "cameras" ? 4 : 3 }}
      renderItem={item => {
        return (
          <List.Item key={item.id}>
            {item.type === "Camera" ? (
              <CameraThumbnail
                id={item.id}
                camera={item}
                showSpinner={true}
                link
              />
            ) : (
              <CameraCard {...item} />
            )}
          </List.Item>
        );
      }}
    />
  );

  render() {
    const { Content } = Layout;
    const { searchTerm, results, loading } = this.props;
    const resultCount = results
      ? results.reduce((acc, curr) => acc + curr.items.length, 0)
      : 0;
    return (
      <Content>
        <Layout className="container">
          <Helmet title="Search" />
          <Spinner spinning={loading} tip="Searching">
            <PageHeader
              title={
                <span className="search-results-title">
                  <h2>
                    <FormattedMessage {...messages.title} />
                  </h2>
                  <span className="search-results-title-summary">
                    <FormattedMessage
                      {...messages.resultsSummary}
                      values={{ resultCount, searchTerm }}
                    />
                  </span>
                </span>
              }
              showTitle
            />
            {results
              ? results.map(group => this.renderSearchResults(group))
              : null}
          </Spinner>
        </Layout>
      </Content>
    );
  }
}

SearchPage.propTypes = {
  actions: PropTypes.object,
  searchTerm: PropTypes.string,
  searchItems: PropTypes.object,
  ready: PropTypes.bool
};

SearchPage.defaultProps = {
  searchTerm: "",
  results: []
};

const mapStateToProps = state => {
  const cameras = getCameras(state);
  return {
    ready: !state.views.loading,
    ...state.search,
    searchTerm: getSearch(state.router.location.search),
    searchItems: {
      views: [
        ...getViews(state),
        ...state.customViews.views.map(v => ({
          ...v,
          cameras: v.cameras
            .map(cameraId => {
              return cameras.find(item => item.id === cameraId);
            })
            .filter(c => !!c),
          custom: true
        }))
      ],
      cameras
    }
  };
};

export default withData(SearchPage, mapStateToProps, actions);
