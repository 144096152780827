import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Layout } from "antd";
import PageHeader from "../Common/PageHeader";
import messages from "./messages";

const NotFoundPage = () => {
  const { Content } = Layout;
  return (
    <Content>
      <Layout className="container">
        <Helmet title="404" />
        <PageHeader
          title={<FormattedMessage {...messages.title} />}
          showTitle
        />
        <Link to="/"> Go back to homepage </Link>
      </Layout>
    </Content>
  );
};

export default NotFoundPage;
