import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "components.InvestigationDetailPage.title",
    defaultMessage: "Investigations"
  },
  titleNew: {
    id: "components.InvestigationDetailPage.titleNew",
    defaultMessage: "Untitled Investigation"
  },
  addCamera: {
    id: "components.InvestigationDetailPage.addCamera",
    defaultMessage: "Add Camera"
  },
  removeCamera: {
    id: "components.InvestigationDetailPage.removeCamera",
    defaultMessage: "Remove"
  }
});
