import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import App from "../App";

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <IntlProvider locale="en">
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App history={history} />
          </ConnectedRouter>
        </Provider>
      </IntlProvider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
