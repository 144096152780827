import {
  PLAYER_MODE_LIVE,
  PLAYER_MODE_PLAYBACK,
  SEEK_TYPE_NEXT_FRAME,
  SEEK_TYPE_PREV_FRAME,
  SEEK_TYPE_TIME
} from "../components/Cameras/constants";

import XPMobileSDK from "./api";

export const sort_by_endtime = (a, b) =>
  a.endTime < b.endTime ? -1 : a.endTime === b.endTime ? 0 : 1;

class PlaybackController {
  constructor({
    InvestigationId,
    cameras,
    mode,
    handleReceiveFrame,
    handleError,
    startTime,
    onInit,
    useFallbackRender,
    onSwitchToFallback
  }) {
    this.ready = false;
    this.investigationId = InvestigationId;
    this.cameras = cameras;
    this.videoConnection = null;
    this.videoConnectionObserver = null;
    this.useFallbackRender = useFallbackRender;
    this.connections = {};
    this.sequences = {};
    this.reverseSequences = {};
    this.frames = {};
    this.speed = 0;
    this.mode = mode;
    this.playing = false;
    this.startTime = startTime;
    this._onInit = onInit || this._onInit;
    this._handleReceiveFrame = handleReceiveFrame || this._handleReceiveFrame;
    this._handleError = handleError || this._handleError;
    this._onSwitchToFallback = onSwitchToFallback;
    this.connectionRequest = XPMobileSDK.createPlaybackController(
      {
        InvestigationId,
        Time: startTime
      },
      videoConnection =>
        this.onRecieveVideoConnection(videoConnection, cameras, mode),
      error => this._handleError(undefined, error)
    );
    this.destroy = this.destroy.bind(this);
  }

  ready = false;
  initStarted = false;
  id = null;
  investigationId = null;
  connectionRequest = null;
  videoConnection = null;
  videoConnectionObserver = null;
  useFallbackRender = false;
  cameras = [];
  connections = {};
  startTime = undefined;
  sequences = {};
  reverseSequences = {};
  frames = {};
  speed = 0;
  mode = null;
  playing = false;
  destroying = false;
  shouldCallOnInit = true;
  _handleReceiveFrame = () => {};
  _handleError = () => {};
  _onInit = () => {};

  destroy() {
    this.ready = false;
    this.initStarted = false;
    this.destroying = true;
    this.destroyCameraConnections();
    this.destroyConnection();
    delete this.id;
    delete this.investigationId;
    delete this.connections;
  }

  isConnected = id => {
    const videoConnection =
      id && this.connections && this.connections[id]
        ? this.connections[id].videoConnection
        : this.videoConnection;

    return (
      videoConnection &&
      ((this.mode === PLAYER_MODE_LIVE &&
        this.connections[id] &&
        !this.connections[id].useFallbackRender) ||
        videoConnection.getState() !==
          XPMobileSDK.library.VideoConnectionState.notOpened)
    );
  };

  cameraIsDirectStreaming = id => {
    const videoConnection =
      id && this.connections && this.connections[id]
        ? this.connections[id].videoConnection
        : this.videoConnection;
    return this.connectionIsDirectStreaming(videoConnection);
  };
  connectionIsDirectStreaming = videoConnection =>
    !!videoConnection &&
    videoConnection.response.parameters.StreamType === "FragmentedMP4";

  setSequences = (sequences, reverseSequences) => {
    this.sequences = sequences;
    this.reverseSequences = reverseSequences;
  };

  setSpeed = speed => {
    this.speed = speed;
  };

  setFrameTime = (id, timestamp) => {
    this.frames[id] = timestamp;
  };

  onRecieveVideoConnection = (videoConnection, cameras, mode) => {
    if (this.destroying) {
      return;
    }
    this.videoConnection = videoConnection;
    this.id = videoConnection.videoId;

    this.videoConnectionObserver = {
      videoConnectionReceivedFrame: this._handleReceiveFrame
    };
    if (this.connectionIsDirectStreaming(videoConnection)) {
      this.useFallbackRender = false;
    } else {
      if (mode !== PLAYER_MODE_PLAYBACK && !this.useFallbackRender) {
        this._onSwitchToFallback();
      }
      videoConnection.addObserver(this.videoConnectionObserver);
    }
    videoConnection.getState() ===
      XPMobileSDK.library.VideoConnectionState.notOpened &&
      videoConnection.open();

    Array.isArray(cameras) &&
      cameras.forEach(c => this.initCameraStream(c.id, mode));
  };

  beginInit = (cameras, mode) => {
    this.initStarted = true;
    Array.isArray(cameras) &&
      cameras.forEach(c => this.initCameraStream(c.id, mode));
  };

  initCameraStream = (cameraId, mode) => {
    if (!this.videoConnection || this.destroying) {
      return;
    }
    if (!this.connections) {
      this.connections = {};
    }
    this.connections[cameraId] = {};
    const size = { width: 623, height: 445 };
    const params = {
      reuseConnection: true,
      signal: mode,
      streamType:
        mode === PLAYER_MODE_LIVE && !this.useFallbackRender
          ? XPMobileSDK.library.VideoConnectionStream.FragmentedMP4
          : undefined
    };
    if (mode === PLAYER_MODE_PLAYBACK) {
      params.playbackControllerId = this.id;
      params.seekType = SEEK_TYPE_TIME;
      params.time = this.startTime;
    }
    this.connections[cameraId].connectionRequest = XPMobileSDK.requestStream(
      cameraId,
      size,
      params,
      this.onReceiveStream.bind(this, cameraId),
      this.onStreamError.bind(this, cameraId)
    );
  };

  onReceiveStream = (cameraId, videoConnection) => {
    if (this.destroying) {
      return;
    }
    if (!videoConnection) {
      return this.onStreamError(
        cameraId,
        new Error(`Failed to create video connection for camera ${cameraId}`)
      );
    }
    if (this.connections[cameraId].videoConnection) {
      if (this.connections[cameraId].videoConnectionObserver) {
        this.connections[cameraId].videoConnection.removeObserver(
          this.connections[cameraId].videoConnectionObserver
        );
      }
      this.connections[cameraId].videoConnection.close();
      delete this.connections[cameraId].videoConnection;
      delete this.connections[cameraId].videoConnectionObserver;
    }

    if (!this.connectionIsDirectStreaming(videoConnection)) {
      this.connections[cameraId].useFallbackRender = true;
      const videoConnectionObserver = {
        videoConnectionReceivedFrame: frame => {
          frame && this.setFrameTime(cameraId, frame.timestamp.getTime());
          this._handleReceiveFrame(cameraId, frame);
        }
      };
      videoConnection.addObserver(videoConnectionObserver);
      videoConnection.getState() ===
        XPMobileSDK.library.VideoConnectionState.notOpened &&
        videoConnection.open();
      this.connections[
        cameraId
      ].videoConnectionObserver = videoConnectionObserver;
    }

    this.connections[cameraId].videoConnection = videoConnection;

    this.initFinished();
  };

  onStreamError = (id, error) => {
    if (this.destroying) {
      return;
    }
    this._handleError(id, error);
    this.destroyConnection(id ? this.connections[id] : this);
  };

  initFinished = () => {
    if (!this.cameras.some(c => !this.isConnected(c.id)) && !this.ready) {
      this.ready = true;
      this._onInit && this._onInit();
    }
  };

  destroyConnection = (s = this) => {
    this.ready = false;
    if (s.videoConnection) {
      if (s.videoConnectionObserver) {
        s.videoConnection.removeObserver(s.videoConnectionObserver);
      }
      s.videoConnection.close();
      try {
        s.videoConnection.destroy();
        // this fails sometimes, but only in production builds...
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Error during videoConnection.destroy():`, e);
      }
      if (s.connectionRequest) {
        XPMobileSDK.cancelRequest(s.connectionRequest);
      }
    }
    delete s.connectionRequest;
    delete s.videoConnection;
    delete s.videoConnectionObserver;
  };

  destroyCameraConnections = () => {
    Object.values(this.connections || {}).forEach(s =>
      this.destroyConnection(s)
    );
    this.initStarted = false;
  };

  toggleFallbackRender = (enabled, onThisInit) => {
    this.useFallbackRender = enabled;
    this.destroyCameraConnections();
    if (onThisInit) {
      const prevOnInit = this._onInit;
      this._onInit = () => {
        onThisInit();
        this._onInit = prevOnInit;
      };
    }
    this.beginInit(this.cameras, this.mode);
  };

  play = (speed, timestamp, onSuccess, onError) => {
    if (!this.ready || !this.isConnected()) {
      return;
    }
    XPMobileSDK.changeMultipleStreams(
      {
        PlaybackControllerId: this.id,
        Speed: speed,
        SeekType: SEEK_TYPE_TIME,
        Time: timestamp
      },
      (...args) => {
        this.timestamp = timestamp;
        this.speed = speed;
        this.playing = speed !== 0;
        onSuccess(...args);
      },
      onError
    );
  };

  pause = (timestamp, onSuccess, onError) =>
    this.play(0, timestamp, onSuccess, onError);

  reverse = (speed, onSuccess, onError) =>
    this.play(-1 * speed, onSuccess, onError);

  seekFrame = (seekType, timestamp, onError) => {
    if (!this.ready || !this.isConnected()) {
      return;
    }
    if (this.playing) {
      this.pause(timestamp, () => this._seekFrameByCamera(seekType), onError);
    } else {
      this._seekFrameByCamera(seekType);
    }
  };

  _seekFrameByCamera = seekType => {
    if (!this.ready) {
      return;
    }
    const reverse = seekType === SEEK_TYPE_PREV_FRAME;
    Object.entries(this.connections).forEach(([id, conn]) => {
      if (this.isConnected(id)) {
        const timestamp = this.frames[id];
        const curSeq = this.getCurrentSequence(this.timestamp, id, reverse);
        /*const curSeqAll = this.getCurrentSequence(
          this.timestamp,
          "all",
          reverse
        );
        const nextSeq = this.getNextSequence(this.timestamp, "all", reverse);*/
        if (
          !!curSeq ||
          !timestamp ||
          (reverse ? timestamp >= this.timestamp : timestamp <= this.timestamp)
        ) {
          XPMobileSDK.playbackSeek(conn.videoConnection, seekType);
        }
      }
    });
  };

  seekTime = (timestamp, resumePlayback, onSuccess, onError) => {
    if (!this.ready || !this.isConnected()) {
      return;
    }

    // I don't understand why this needs to be done 3 times,
    // but if I don't, it won't actually seek, it'll just
    // resume playing where it was before
    XPMobileSDK.changeMultipleStreams(
      {
        PlaybackControllerId: this.id,
        Speed: 0,
        SeekType: SEEK_TYPE_TIME,
        Time: timestamp
      },
      () => {
        this.timestamp = timestamp;
        this.frames = {};
        setTimeout(
          () =>
            XPMobileSDK.changeMultipleStreams(
              {
                PlaybackControllerId: this.id,
                Speed: 0,
                SeekType: SEEK_TYPE_TIME,
                Time: timestamp
              },
              () =>
                setTimeout(
                  resumePlayback
                    ? () => this.play(this.speed, timestamp, onSuccess, onError)
                    : () =>
                        this.seekFrame(
                          SEEK_TYPE_NEXT_FRAME,
                          onSuccess,
                          onError
                        ),
                  100
                ),
              onError
            ),
          100
        );
      },
      onError
    );
  };

  _togglePlaybackMode = (mode, onSuccess, onError) => {
    try {
      if (this.mode !== mode) {
        this.destroyCameraConnections();
        this.mode = mode;
      }
      if (!this.initStarted) {
        this.beginInit(this.cameras, mode);
      }
      onSuccess && onSuccess();
    } catch (e) {
      onError && onError(e);
    }
  };

  togglePlaybackMode = (mode, onSuccess, onError) => {
    if (this.mode === PLAYER_MODE_PLAYBACK) {
      this.pause(this.timestamp, () =>
        this._togglePlaybackMode(mode, onSuccess, onError)
      );
    } else {
      this._togglePlaybackMode(mode, onSuccess, onError);
    }
  };

  getSequences = (id = "all", reverse = false) => {
    const { sequences = {}, reverseSequences = {} } = this;
    return (reverse ? reverseSequences[id] : sequences[id]) || [];
  };

  getCurrentSequence = (
    frameTime = this.timestamp,
    id = "all",
    reverse = false
  ) => {
    const curSeqs = this.getSequences(id, reverse).filter(
      s => s.startTime <= frameTime && s.endTime >= frameTime
    );
    return reverse ? curSeqs[0] : _.last(curSeqs);
  };

  getNextSequence = (
    frameTime = this.timestamp,
    id = "all",
    reverse = false
  ) => {
    const seqs = this.getSequences(id, reverse);
    return reverse
      ? _.findLast(seqs, s => s.endTime < frameTime)
      : seqs.find(s => s.startTime > frameTime);
  };

  _getNextTimestamp = (frameTime, id, reverse = false) => {
    const curSeq = this.getCurrentSequence(this.timestamp, id, reverse);
    const curSeqAll = this.getCurrentSequence(this.timestamp, "all", reverse);
    const nextSeq = this.getNextSequence(this.timestamp, "all", reverse);
    if (
      curSeq &&
      (reverse ? frameTime >= curSeq.startTime : frameTime <= curSeq.endTime) &&
      (reverse ? frameTime < this.timestamp : frameTime > this.timestamp)
    ) {
      return frameTime;
    }
    if (
      nextSeq &&
      nextSeq.id === id &&
      (!curSeqAll ||
        (reverse
          ? curSeqAll.startTime >= this.timestamp
          : curSeqAll.endTime <= this.timestamp) ||
        (Math.abs(this.speed) > 1 &&
          (reverse
            ? frameTime <= nextSeq.endTime
            : frameTime >= nextSeq.startTime)))
    ) {
      return reverse ? nextSeq.endTime : nextSeq.startTime;
    }
    return this.timestamp || this.startTime;
  };

  getNextTimestamp = (frameTime, id, seekType) => {
    this.timestamp = this._getNextTimestamp(
      frameTime,
      id,
      seekType != null ? seekType === SEEK_TYPE_PREV_FRAME : this.speed < 0
    );
    return this.timestamp;
  };
}

export default PlaybackController;
