/* eslint-disable import/no-named-as-default */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { hot } from "react-hot-loader";
import { Layout } from "antd";
import classnames from "classnames";
import routes from "../../utils/routes";
import SiteHeader from "../Common/SiteHeader";
import HomePage from "../HomePage";
import SearchPage from "../SearchPage";
import NotFoundPage from "../NotFoundPage";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/authActions";
import connect from "react-redux/es/connect/connect";
import ErrorModal from "../Common/ErrorModal";

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends Component {
  render() {
    const { Content } = Layout;
    const {
      history,
      utility: { showModal = "" }
    } = this.props;

    const isHome = history.location.pathname === "/";
    const htmlClass = classnames("page", { "page--home": isHome });

    return (
      <Layout className="site-layout">
        <Helmet
          titleTemplate="%s - Videowatch"
          defaultTitle="Videowatch"
          htmlAttributes={{ ["class"]: htmlClass }}
        />
        <SiteHeader
          menuLinks={routes}
          showHeader={!isHome}
          handleSearchSubmit={this.handleSearchSubmit}
        />
        <Content>
          <Switch>
            <Route exact path="/" component={HomePage} />
            {routes.map((link, i) => (
              <Route
                exact={link.exact}
                path={link.path}
                component={link.component}
                key={i}
                breadcrumbName={i}
              />
            ))}
            <Route exact path="/search" component={SearchPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </Content>
        <ErrorModal visible={showModal === "connectionError"} />
      </Layout>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  utility: PropTypes.object
};

// so we can get a full overview of app state by inspecting this component
const mapStateToProps = state => {
  return { ...state };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default hot(module)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
