import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "components.CameraSearchForm.title",
    defaultMessage: "Search"
  },
  inputPlaceholder: {
    id: "components.CameraSearchForm.inputPlaceholder",
    defaultMessage: "Search..."
  },
  submit: {
    id: "components.CameraSearchForm.submit",
    defaultMessage: "Search"
  }
});
