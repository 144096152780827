import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import XPMobileSDK from "../../../utils/api";
import { PLAYER_MODE_LIVE } from "../constants";

const playbackSpeeds = [1, 2, 4, 8, 16];

class CameraPlayer extends PureComponent {
  constructor(props) {
    super(props);

    this.frameCanvas = React.createRef();
    this.frame = React.createRef();

    this.state = {
      loading: false,
      error: null,
      frame: null,
      mode: PLAYER_MODE_LIVE,
      videoController: null,
      streamRequest: null,
      showModal: null,
      showFullscreen: null,
      speed: 1,
      timestamp: new Date().getTime(),
      thumbnail: null,
      videoConnectionObserver: null,
      showPtz: false,
      stream: null,
      aspectRatio: null
    };
  }
  frame = null;
  maxWidthRule = null;
  aspectRatio = null;
  selectorText =
    ".camera-player-page .camera-player-single .camera-player-content";

  componentDidMount() {
    this.handleTogglePlayerMode(PLAYER_MODE_LIVE);
    this.getAspectRatioCssRule();
  }

  componentWillUnmount() {
    this.handleStopStream();
  }

  handleToggleModal = (modal = null) =>
    this.setState({ showModal: this.state.showModal === modal ? null : modal });

  handleTogglePtz = (show = null) =>
    this.setState({ showPtz: show !== null ? show : !this.state.showPtz });

  handleToggleSpeed = () => {
    const { speed } = this.state;
    const i = playbackSpeeds.indexOf(Math.abs(speed));
    const playbackSpeed =
      (i < playbackSpeeds.length - 1
        ? playbackSpeeds[i + 1]
        : playbackSpeeds[0]) * (speed > 0 ? 1 : -1);
    this.handleSetSpeed(playbackSpeed);
  };

  handleSetTimestamp = () => timestamp => {
    this.setState({ timestamp }, () => {
      this.handleRequestStream({
        seekType: "Time",
        time: this.state.timestamp
      });
    });
    this.toggleDatepickerModal();
  };

  resetImage = frame => {
    if (frame && frame.image && frame.image.src) {
      window.URL.revokeObjectURL(frame.image.src);
    }
  };

  getAspectRatioCssRule = () => {
    try {
      const sheet = Object.values(document.styleSheets).find(
        s =>
          s.href.startsWith(`${location.origin}/main`) || s.rules.length > 100
      );
      const rules = Object.values(sheet.rules || sheet.cssRules || {});
      const maxWidthRule = rules.find(
        r =>
          r &&
          r.selectorText === this.selectorText &&
          r.cssText.search(/max-width: calc/i) !== -1
      );
      if (maxWidthRule) {
        this.maxWidthRule = maxWidthRule.style.maxWidth;
      } else {
        // eslint-disable-next-line no-console
        console.error(`Failed to find CSS rule for frame width.`);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Failed to get CSS rule for frame width:`, e);
    }
  };

  setAspectRatio = (width, height) => {
    const { stream } = this.state;
    const old_ratio = this.aspectRatio;
    const ratio = height / width;
    if (
      !stream ||
      !stream.videoConnection ||
      !this.frame ||
      !this.frame.current ||
      !this.maxWidthRule ||
      isNaN(ratio) ||
      ratio === this.aspectRatio
    ) {
      return;
    }

    const { clientHeight = 0 } = this.frame.current || {};
    let f_width = Math.min(Math.max(1280, clientHeight / ratio), width);
    let f_height = Math.min(720, height);
    if (this.state.useFallbackRender && this.state.mode === PLAYER_MODE_LIVE) {
      if (f_width > width / 2) {
        f_width *= 2 / 3;
      }
      if (f_height > height / 2) {
        f_height *= 2 / 3;
      }
    }

    XPMobileSDK.changeStream(
      stream.videoConnection,
      {},
      {
        width: f_width || f_height / ratio,
        height: f_width ? ratio * f_width : f_height
      },
      () => {
        this.setState({ aspectRatio: ratio });
      },
      () => {
        this.aspectRatio = old_ratio;
      }
    );
    this.aspectRatio = ratio;

    if (this.maxWidthRule.search(/^calc\(.* \/ 0\.715\)$/) !== -1) {
      this.frame.current.setAttribute(
        "style",
        `max-width: ${this.maxWidthRule.replace(" / 0.715)", ` / ${ratio})`)};`
      );
    }
  };

  handleToggleFullscreen = id => {
    if (this.fullscreen_container.current) {
      this.setState(
        {
          showFullscreen: id,
          loading: !this.state.useFallbackRender
        },
        () => {
          setTimeout(() => {
            this.setFullscreenListeners(true);
            this.requestFullscreen(this.fullscreen_container.current);
          }, 100);
        }
      );
    }
  };

  onFullscreenExit = () => {
    if (document.fullscreenElement) {
      return;
    }
    this.setState({
      showFullscreen: false,
      loading: !this.state.useFallbackRender
    });
    this.setFullscreenListeners(false);
  };

  onFullscreenError = (...args) => {
    // eslint-disable-next-line no-console
    console.error(`Error in fullscreen`, ...args);
    this.setState({ showFullscreen: false });
    this.setFullscreenListeners(false);
  };

  requestFullscreen = elem => {
    if (!elem) {
      return;
    }
    elem.requestFullscreen =
      elem.requestFullscreen ||
      elem.mozRequestFullScreen ||
      elem.webkitRequestFullscreen ||
      elem.msRequestFullscreen;
    if (typeof elem.requestFullscreen === "function") {
      elem.requestFullscreen();
    } else {
      window.alert("This browser does not support fullscreen view.");
    }
  };

  setFullscreenListeners = enabled => {
    const method = enabled ? "addEventListener" : "removeEventListener";
    document[method]("fullscreenchange", this.onFullscreenExit);
    document[method]("mozfullscreenchange", this.onFullscreenExit);
    document[method]("webkitfullscreenchange", this.onFullscreenExit);
    document[method]("fullscreenerror", this.onFullscreenError);
    document[method]("mozfullscreenerror", this.onFullscreenError);
    document[method]("webkitfullscreenerror", this.onFullscreenError);
  };
}

CameraPlayer.propTypes = {
  mode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableCapabilities: PropTypes.array,
  playerState: PropTypes.string,
  showDatepickerModal: PropTypes.bool,
  playbackSpeed: PropTypes.number,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.number,
  startTime: PropTypes.number,
  defaultMode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchThumbnailResponse: PropTypes.func.isRequired,
  directStreamingEnabled: PropTypes.bool,
  websocketsEnabled: PropTypes.bool
};

CameraPlayer.defaultProps = {
  defaultMode: null,
  disableCapabilities: [],
  endTime: null,
  startTime: null
};

export default CameraPlayer;
