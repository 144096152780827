import {
  FETCH_CUSTOM_VIEW,
  FETCH_CUSTOM_VIEW_RESPONSE,
  ADD_VIEW_CAMERA,
  REMOVE_VIEW_CAMERA,
  REORDER_CUSTOM_LAYOUT,
  SET_CUSTOM_LAYOUT_COLUMNS,
  SET_VIEW_NOT_MODIFIED,
  LOGOUT_SUCCESS
} from "../constants/actionTypes";
import { VIEW_ID_NEW } from "../components/Views/constants";

const initialState = {
  id: null,
  loading: true,
  error: null,
  cameras: [],
  removeModeActive: false,
  confirmDelete: false,
  layoutColumns: 4
};

const customViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOM_VIEW:
      return { ...state, loading: true, error: null };

    case FETCH_CUSTOM_VIEW_RESPONSE:
      if (action.view) {
        return {
          ...state,
          loading: false,
          modified: false,
          ...action.view
        };
      }
      return {
        ...initialState,
        loading: false,
        modified: false,
        id: action.isNew ? VIEW_ID_NEW : null
      };

    case SET_CUSTOM_LAYOUT_COLUMNS:
      return { ...state, layoutColumns: action.layoutColumns, modified: true };

    case REORDER_CUSTOM_LAYOUT: {
      return { ...state, cameras: action.order, modified: true };
    }

    case ADD_VIEW_CAMERA:
      return {
        ...state,
        cameras: state.cameras.concat(action.id),
        modified: true
      };

    case REMOVE_VIEW_CAMERA:
      return {
        ...state,
        cameras: [...state.cameras.filter(id => action.id !== id)],
        modified: true
      };

    case SET_VIEW_NOT_MODIFIED:
      return {
        ...state,
        modified: false
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default customViewReducer;
