import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

const Icon = ({ type, intl, title = "" }) => {
  let titleText = title;
  if (title && typeof title === "object") {
    titleText = intl.formatMessage(title);
  }
  return (
    <span
      className={`icon icon-${type}`}
      title={titleText}
      aria-hidden={title ? "false" : "true"}
    />
  );
};

Icon.propTypes = {
  type: PropTypes.string,
  intl: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default injectIntl(Icon);
