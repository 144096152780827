import React from "react";
import PropTypes from "prop-types";

// load content when it is within this # of pixels outside the edge of the viewport
const VIEWPORT_EDGE_LOAD_THRESHOLD = 128; //px
// const FRAME_WATCHER_SELECTOR =
//   ".site-layout > .ant-layout-content > .ant-layout-content";
const frameRegistry = {};
const observerDebounce = {};

const observerCallback = entries => {
  entries.forEach(e => {
    if (!e.target) {
      return;
    }
    const id = e.target.dataset["observerTargetId"];
    if (typeof frameRegistry[id] === "function") {
      if (observerDebounce[id]) {
        clearTimeout(observerDebounce[id]);
      }
      observerDebounce[id] = setTimeout(() => {
        if (typeof frameRegistry[id] === "function") {
          frameRegistry[id](!e.isIntersecting);
        }
        clearTimeout(observerDebounce[id]);
        observerDebounce[id] = null;
      }, 100);
    }
  });
};

const SUPPORTS_INTERSECTION_OBSERVER = !!("IntersectionObserver" in window);
const intersectObs = SUPPORTS_INTERSECTION_OBSERVER
  ? new IntersectionObserver(observerCallback)
  : null;

// https://stackoverflow.com/questions/35939886/find-first-scrollable-parent
const getScrollParent = elem => {
  if (elem == null) {
    return null;
  }
  const { overflowX, overflowY } = window.getComputedStyle(elem);
  if (
    ["scroll", "auto"].includes(overflowX) ||
    ["scroll", "auto"].includes(overflowY)
  ) {
    return elem;
  } else {
    return getScrollParent(elem.parentNode);
  }
};

export const registerFrame = (id, checkFunction) => {
  if (!id) {
    return;
  }
  frameRegistry[id] = checkFunction;
};

export const removeFrame = id => {
  frameRegistry[id] = null;
  delete frameRegistry[id];
};

export const clearAllFrames = () => {
  Object.keys(frameRegistry).forEach(id => {
    frameRegistry[id] = null;
    delete frameRegistry[id];
  });
};

const timers = {};
const onceEvery = (time, callback, id, wait = true) => e => {
  if (wait) {
    clearTimeout(timers[id]);
    delete timers[id];
  }
  if (!timers[id]) {
    timers[id] = setTimeout(() => {
      clearTimeout(timers[id]);
      delete timers[id];
      callback(e);
    }, time);
  }
};

export const isOutOfView = (elem, scrollParent = getScrollParent(elem)) => {
  if (!elem) {
    return true;
  }
  if (!scrollParent) {
    return false;
  }
  const rect = elem.getBoundingClientRect();
  const pRect = scrollParent ? scrollParent.getBoundingClientRect() : {};
  if (!scrollParent) {
    pRect.left = 0;
    pRect.right = window.innerWidth || document.documentElement.clientWidth;
    pRect.top = 0;
    pRect.bottom = window.innerHeight || document.documentElement.clientHeight;
  }
  return (
    rect.left > pRect.right + VIEWPORT_EDGE_LOAD_THRESHOLD ||
    rect.right < pRect.left - VIEWPORT_EDGE_LOAD_THRESHOLD ||
    rect.top > pRect.bottom + VIEWPORT_EDGE_LOAD_THRESHOLD ||
    rect.bottom < pRect.top - VIEWPORT_EDGE_LOAD_THRESHOLD
  );
};

class LazyLoadContainer__Manual extends React.Component {
  constructor() {
    super();
    this.scroll_parent = null;
    this.scroll_parent_found = false;
    this.observer = new MutationObserver(this.mutation_callback);
  }
  componentDidMount() {
    const { parentId } = this.props;
    if (parentId) {
      const scrollParent = getScrollParent(document.getElementById(parentId));
      if (scrollParent instanceof HTMLElement) {
        scrollParent.addEventListener("scroll", this.container_scroll_listener);
        this.observer.observe(scrollParent, {
          subtree: true,
          childList: true,
          attributes: true
        });
        this.scroll_parent_found = true;
        this.scroll_parent = scrollParent;
      }
    }
    if (!this.scroll_parent) {
      this.scroll_parent = document.body;
      window.addEventListener("scroll", this.doc_scroll_listener);
    }
    window.addEventListener("resize", this.resize_listener);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.doc_scroll_listener);
    window.removeEventListener("resize", this.resize_listener);
    this.observer.disconnect();
    this.scroll_parent &&
      this.scroll_parent.removeEventListener(
        "scroll",
        this.container_scroll_listener
      );
  }

  componentDidUpdate() {
    if (!this.scroll_parent_found && this.props.parentId) {
      const scrollParent = getScrollParent(
        document.getElementById(this.props.parentId)
      );
      if (scrollParent instanceof HTMLElement) {
        window.removeEventListener("scroll", this.doc_scroll_listener);
        scrollParent.addEventListener("scroll", this.container_scroll_listener);
        this.observer.observe(scrollParent, {
          subtree: true,
          childList: true,
          attributes: true
        });
        this.scroll_parent_found = true;
        this.scroll_parent = scrollParent;
      }
    }
  }

  execFrameChecks = e => {
    Object.values(frameRegistry).forEach(check => {
      check && check(e ? e.target : null);
    });
  };

  doc_scroll_listener = onceEvery(
    200,
    this.execFrameChecks.bind(this, { target: document.body }),
    "doc_scroll"
  );
  resize_listener = onceEvery(
    200,
    () => this.execFrameChecks({ target: this.scroll_parent || document.body }),
    "resize",
    true
  );
  container_scroll_listener = onceEvery(
    200,
    this.execFrameChecks,
    "cont_scroll"
  );
  mutation_callback = onceEvery(
    200,
    () => this.execFrameChecks({ target: this.scroll_parent }),
    "mutate",
    true
  );
}
LazyLoadContainer__Manual.propTypes = {
  parentId: PropTypes.string.isRequired
};

class LazyLoadContainer__Observer extends React.PureComponent {
  /** need to define these so that child classes won't crash when calling `super` */
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  componentWillUnmount() {}
  componentDidUpdate() {}
  execFrameChecks = () => {};
}

class LazyLoadFrame__Manual extends React.Component {
  constructor(props) {
    super(props);
    this.frame = React.createRef();
    this.scrollStateChangeLock = false;
    this.state = {
      ...(this.state || {}),
      frameInitialized: false,
      outOfView: true
    };
  }
  frame;
  scrollStateChangeLock = false;
  mounted;

  componentDidMount() {
    this.mounted = true;
    if (this.getFrameId()) {
      this.initializeFrame(this.getFrameId());
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.state.frameInitialized) {
      removeFrame(this.getFrameId());
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.frameInitialized && this.getFrameId()) {
      this.initializeFrame(this.getFrameId());
    } else if (
      !prevState.frameInitialized &&
      this.state.frameInitialized &&
      !this.isFetching()
    ) {
      if (this.needToFetchContent(prevState)) {
        this.fetchContent();
      } else if (this.shouldLazyFetch()) {
        this.fetchContent(true);
      }
    } else if (this.needToFetchContent(prevState) && !this.isFetching()) {
      this.fetchContent();
      this.fetching = true;
      this.scrollStateChangeLock = false;
    } else if (
      this.fetching &&
      this.isFetching() &&
      !this.needToFetchContent(prevState)
    ) {
      this.cancelFetchContent(this.getFrameId());
      this.fetching = false;
    } else if (this.fetchFinished(prevProps)) {
      this.fetching = false;
    }
  }

  setStateIfMounted = (...args) => {
    if (!this.mounted) {
      return;
    }
    this.setState(...args);
  };

  initializeFrame = id => {
    if (!id || !this.mounted) {
      return;
    }
    registerFrame(id, this.checkFrameOutOfView);
    this.setStateIfMounted({
      outOfView: isOutOfView(this.frame ? this.frame.current : null),
      frameInitialized: true
    });
  };

  checkFrameOutOfView = scrollParent => {
    if (!this.mounted) {
      return;
    }
    let oov = !this.frame
      ? true
      : isOutOfView(this.frame.current, scrollParent);
    if (oov !== this.state.outOfView) {
      this.scrollStateChangeLock = true;
      this.setStateIfMounted({ outOfView: oov });
    }
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  fetchContent = () => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `fetchContent` method in any component class that extends `LazyLoadFrame`."
    );
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  cancelFetchContent = id => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `cancelFetchContent` method in any component class that extends `LazyLoadFrame`."
    );
    return id;
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  // eslint-disable-next-line no-unused-vars
  fetchFinished = (prevProps, prevState) => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `fetchFinished` method in any component class that extends `LazyLoadFrame`."
    );
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  getFrameId = () => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `getFrameId` method in any component class that extends `LazyLoadFrame`."
    );
    return "no-id";
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  needToFetchContent = () => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `needToFetchContent` method in any component class that extends `LazyLoadFrame`."
    );
    return false;
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  shouldLazyFetch = () => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `shouldLazyFetch` method in any component class that extends `LazyLoadFrame`."
    );
    return false;
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  isFetching = () => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `isFetching` method in any component class that extends `LazyLoadFrame`."
    );
    return false;
  };
}

class LazyLoadFrame__Observer extends React.Component {
  constructor(props) {
    super(props);
    this.frame = React.createRef();
    this.scrollStateChangeLock = false;
    this.fetching = false;
    this.state = {
      ...(this.state || {}),
      frameInitialized: false,
      outOfView: true
    };
  }
  frame;
  scrollStateChangeLock = false;
  fetching = false;
  mounted;
  idleCallback = null;

  componentDidMount() {
    this.mounted = true;
    if (this.getFrameId()) {
      this.initializeFrame(this.getFrameId());
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.state.frameInitialized) {
      this.destroyFrame(this.getFrameId());
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.frameInitialized && this.getFrameId()) {
      this.initializeFrame(this.getFrameId());
    } else if (
      !prevState.frameInitialized &&
      this.state.frameInitialized &&
      !this.isFetching()
    ) {
      if (this.needToFetchContent(prevState)) {
        this.fetchContent();
      } else if (this.shouldLazyFetch()) {
        this.idleCallback = requestIdleCallback(() => this.fetchContent(true));
      }
    } else if (this.needToFetchContent(prevState) && !this.isFetching()) {
      this.fetchContent();
      this.fetching = true;
      this.scrollStateChangeLock = false;
    } else if (
      this.fetching &&
      this.isFetching() &&
      !this.needToFetchContent(prevState)
    ) {
      this.cancelFetchContent(true);
      this.fetching = false;
    } else if (this.fetchFinished(prevProps)) {
      this.fetching = false;
    }
  }

  setStateIfMounted = (...args) => {
    if (!this.mounted) {
      return;
    }
    this.setState(...args);
  };

  initializeFrame = id => {
    if (!id || !this.mounted || !this.frame || !this.frame.current) {
      return;
    }
    this.frame.current.dataset["observerTargetId"] = id;
    registerFrame(id, this.checkFrameOutOfView);
    intersectObs.observe(this.frame.current);
    this.setStateIfMounted({
      outOfView: isOutOfView(this.frame ? this.frame.current : null),
      frameInitialized: true
    });
  };

  destroyFrame = id => {
    if (!id) {
      return;
    }
    this.idleCallback && cancelIdleCallback(this.idleCallback);
    this.cancelFetchContent(false);
    removeFrame(id);
    intersectObs.unobserve(this.frame.current);
  };

  checkFrameOutOfView = outOfView => {
    if (!this.mounted) {
      return;
    }
    if (outOfView !== this.state.outOfView) {
      this.scrollStateChangeLock = true;
      this.setStateIfMounted({ outOfView });
    }
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  fetchContent = () => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `fetchContent` method in any component class that extends `LazyLoadFrame`."
    );
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  cancelFetchContent = id => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `cancelFetchContent` method in any component class that extends `LazyLoadFrame`."
    );
    return id;
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  // eslint-disable-next-line no-unused-vars
  fetchFinished = (prevProps, prevState) => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `fetchFinished` method in any component class that extends `LazyLoadFrame`."
    );
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  getFrameId = () => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `getFrameId` method in any component class that extends `LazyLoadFrame`."
    );
    return "no-id";
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  needToFetchContent = () => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `needToFetchContent` method in any component class that extends `LazyLoadFrame`."
    );
    return false;
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  shouldLazyFetch = () => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `shouldLazyFetch` method in any component class that extends `LazyLoadFrame`."
    );
    return false;
  };

  /* OVERRIDE THIS METHOD IN CHILD CLASS */
  isFetching = () => {
    // eslint-disable-next-line no-console
    console.warn(
      "You must override the `isFetching` method in any component class that extends `LazyLoadFrame`."
    );
    return false;
  };
}

let LazyLoadContainer = SUPPORTS_INTERSECTION_OBSERVER
  ? LazyLoadContainer__Observer
  : LazyLoadContainer__Manual;
let LazyLoadFrame = SUPPORTS_INTERSECTION_OBSERVER
  ? LazyLoadFrame__Observer
  : LazyLoadFrame__Manual;

export { LazyLoadContainer, LazyLoadFrame };
