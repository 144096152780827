import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Input, Button } from "antd";
import Spinner from "../Spinner";
import messages from "./messages";

const { Item } = Form;

const LoginForm = props => {
  const {
    intl,
    form,
    authenticate,
    authenticated,
    connected,
    loading,
    error,
    loginError
  } = props;
  const { getFieldDecorator } = form;
  const inputsInvalid =
    Object.values(form.getFieldsError()).filter(e => e).length > 0;
  let spinnerTip = "";

  if (!connected) {
    spinnerTip = intl.formatMessage(messages.spinnerConnecting);
  } else if (!authenticated) {
    spinnerTip = intl.formatMessage(messages.spinnerLoggingIn);
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        form.validateFields((err, values) => {
          if (!err) {
            const { username, password } = values;
            authenticate(username, password);
          }
        });
      }}
      className={`login-form ${(error || inputsInvalid || loginError) &&
        "has-error"}`}
    >
      <h3 className="form-title">
        <FormattedMessage {...messages.title} />
      </h3>
      <Spinner spinning={loading} tip={loading ? spinnerTip : ""} size="large">
        <div className="login-form__error-wrapper">
          <Item
            label={intl.formatMessage(messages.usernameLabel)}
            className="ant-form-item-label-hidden"
          >
            {getFieldDecorator("username", {
              rules: [
                { required: true, message: "Please input your username!" }
              ]
            })(
              <Input
                placeholder={intl.formatMessage(messages.usernameLabel)}
                disabled={loading}
              />
            )}
          </Item>
          <Item
            label={intl.formatMessage(messages.passwordLabel)}
            className="ant-form-item-label-hidden"
          >
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your password!" }
              ]
            })(
              <Input
                type="password"
                placeholder={intl.formatMessage(messages.passwordLabel)}
                disabled={loading}
              />
            )}
          </Item>
          {(loginError || error) && (
            <span className="form-error">
              <FormattedMessage
                {...((loginError || error).code === 15
                  ? messages.errorIncorrect
                  : messages.authError)}
              />
            </span>
          )}
        </div>
        <div className="form-actions">
          <Button type="primary" htmlType="submit" block>
            <FormattedMessage {...messages.submit} />
          </Button>
        </div>
      </Spinner>
    </Form>
  );
};

LoginForm.propTypes = {
  intl: PropTypes.object,
  form: PropTypes.object,
  authenticate: PropTypes.func,
  authenticated: PropTypes.bool,
  connected: PropTypes.bool,
  loading: PropTypes.bool
};

export default injectIntl(Form.create()(LoginForm));
