const XPMobileSDKSettings = {
  fileName: "XPMobileSDK.js",
  clientType: "WebClient",
  communicationChanel: "/XProtectMobile/Communication",
  videoChanel: "/XProtectMobile/Video",
  audioChannel: "/XProtectMobile/Audio",
  MobileServerURL: process.env.API_URL,
  defaultEncryptionPadding: "Iso10126",
  primeLength: 1024,
  videoConnectionTimeout: 2e4,
  resamplingFactor: 1e-6,
  liveMessageMinimumInterval: 1e3,
  socketRestartMinimumInterval: 1e3,
  videoStreamRestartMinimumInterval: 2e4,

  supportsMultiThreaded: false,
  supportsCarousels: false,
  supportsFootages: false,
  supportsCHAP: true,

  DirectStreaming: true,
  DiagnosticsLayout: true,
  SupportsAudioIn: true,
  SupportsAudioOut: false,
  AudioCompressionLevel: 99,
  AudioCompressionLevelAudioAPI: 41,
  NoVideoTimeout: 5e3,
  EnableConsoleLog: true,
  SupportsAdaptiveStreaming: false,

  includes: ["Lib/XPMobileSDK.library.js"]
};

module.exports = XPMobileSDKSettings;
