import { defineMessages } from "react-intl";

export default defineMessages({
  statusActive: {
    id: "components.InvestigationList.statusActive",
    defaultMessage: "Ongoing"
  },
  statusInactive: {
    id: "components.InvestigationList.statusInactive",
    defaultMessage: "Closed"
  },
  newButton: {
    id: "components.InvestigationList.newButton",
    defaultMessage: "Create New Investigation"
  },
  sortBy: {
    id: "components.InvestigationList.sortByLabel",
    defaultMessage: "Sort by {sort}"
  },
  lastModified: {
    id: "components.InvestigationList.lastModified",
    defaultMessage: "Last Modified: {time}"
  }
});
