import React from "react";
import PropTypes from "prop-types";
import { Button as AntButton } from "antd";
import Icon from "../Icon";

const Button = props => {
  const {
    children,
    icon = null,
    iconRight = false,
    className = "",
    ...otherProps
  } = props;
  let classes = className;
  if (icon) {
    classes += " ant-btn-has-icon";
  }

  const renderIcon = () => {
    if (!icon) {
      return "";
    }

    if (typeof icon === "string") {
      return <Icon type={icon} />;
    } else if (typeof icon === "object") {
      return <Icon {...icon} />;
    }
  };

  return (
    <AntButton className={classes.trim()} {...otherProps}>
      {!iconRight ? renderIcon() : ""}
      {children}
      {iconRight ? renderIcon() : ""}
    </AntButton>
  );
};

Button.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  iconRight: PropTypes.bool
};

export default Button;
