import {
  FETCH_CUSTOM_VIEWS,
  FETCH_CUSTOM_VIEWS_SUCCESS,
  FETCH_CUSTOM_VIEWS_ERROR,
  FETCH_CUSTOM_VIEW,
  FETCH_CUSTOM_VIEW_RESPONSE,
  SET_CUSTOM_LAYOUT_COLUMNS,
  REORDER_CUSTOM_LAYOUT,
  ADD_VIEW_CAMERA,
  REMOVE_VIEW_CAMERA,
  SAVING_VIEW,
  UPDATE_VIEW,
  UPDATE_VIEW_SUCCESS,
  UPDATE_VIEW_ERROR,
  CREATE_VIEW,
  CREATE_VIEW_SUCCESS,
  CREATE_VIEW_ERROR,
  DELETE_VIEW,
  DELETE_VIEW_SUCCESS,
  DELETE_VIEW_ERROR,
  SET_VIEW_NOT_MODIFIED
} from "../constants/actionTypes";
import { toggleModal } from "./utilityActions";
import { push } from "connected-react-router";
import { loadStateForUser } from "../store/localStorage";
import { VIEW_ID_NEW } from "../components/Views/constants";

const dispatchAfter = (dispatch, actions = [], time = 100) => {
  setTimeout(() => {
    actions.forEach(a => dispatch(a));
  }, time);
};

const getViewRecord = view => {
  if (!view) {
    return;
  }
  const { id, name, cameras, updatedAt, createdAt, layoutColumns } = view;
  return {
    id,
    name,
    cameras: cameras.map(c => c.id),
    layoutColumns,
    updatedAt,
    createdAt
  };
};

export const fetchCustomViews = (force = false) => {
  return (dispatch, getState) => {
    const {
      auth: { username },
      customViews: { views = [] }
    } = getState();
    if (views.length && !force) {
      return Promise.resolve();
    }
    try {
      dispatch({ type: FETCH_CUSTOM_VIEWS });
      const { views: new_views = [], customViewCounter = 0 } = loadStateForUser(
        username,
        "customViews"
      );
      if (!new_views.length) {
        dispatch(fetchCustomViewsError({ message: "No views" }));
      }
      return dispatch(fetchCustomViewsSuccess(new_views, customViewCounter));
    } catch (e) {
      return dispatch(fetchCustomViewsError(e));
    }
  };
};

export const fetchCustomViewsSuccess = (views, customViewCounter) => ({
  type: FETCH_CUSTOM_VIEWS_SUCCESS,
  views,
  customViewCounter
});

export const fetchCustomViewsError = error => ({
  type: FETCH_CUSTOM_VIEWS_ERROR,
  error
});

export const fetchCustomView = id => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_CUSTOM_VIEW });
    const view = getState().customViews.views.find(v => v.id === id);
    if (view) {
      return dispatch({ type: FETCH_CUSTOM_VIEW_RESPONSE, view });
    }
    return dispatch({
      type: FETCH_CUSTOM_VIEW_RESPONSE,
      isNew: id === VIEW_ID_NEW
    });
  };
};

export const addCamera = id => ({
  type: ADD_VIEW_CAMERA,
  id
});

export const removeCamera = id => ({
  type: REMOVE_VIEW_CAMERA,
  id
});

export const setLayoutColumns = (layoutColumns = "") => ({
  type: SET_CUSTOM_LAYOUT_COLUMNS,
  layoutColumns
});

export const reorderLayout = order => ({
  type: REORDER_CUSTOM_LAYOUT,
  order
});

export const updateView = view => {
  return (dispatch, getState) => {
    // Update existing.
    let { id } = view;
    const {
      auth: { username },
      customViews: { customViewCounter }
    } = getState();
    const viewRecord = getViewRecord(view);
    if (id && id !== VIEW_ID_NEW) {
      try {
        dispatch({ type: SAVING_VIEW, id });
        dispatch({ type: UPDATE_VIEW, id, view: viewRecord, username });
        return dispatchAfter(dispatch, [
          { type: SET_VIEW_NOT_MODIFIED },
          { type: UPDATE_VIEW_SUCCESS, id, view: viewRecord },
          toggleModal()
        ]);
      } catch {
        return dispatchAfter(dispatch, [
          { type: UPDATE_VIEW_ERROR, view: viewRecord }
        ]);
      }
    }

    // Create new.
    id = `custom-view-${customViewCounter}`;
    try {
      dispatch({ type: SAVING_VIEW });
      dispatch({ type: CREATE_VIEW, view: { ...viewRecord, id }, username });
      dispatchAfter(dispatch, [
        { type: SET_VIEW_NOT_MODIFIED },
        push({
          pathname: `/views/${id}`
        }),
        {
          type: CREATE_VIEW_SUCCESS,
          view: { ...viewRecord, id }
        },
        toggleModal()
      ]);
    } catch {
      dispatchAfter(dispatch, [{ type: CREATE_VIEW_ERROR, view: viewRecord }]);
    }
  };
};

export const deleteView = view => {
  return (dispatch, getState) => {
    const { id } = view;
    const {
      auth: { username }
    } = getState();
    try {
      dispatch({ type: SAVING_VIEW, id });
      dispatch({ type: DELETE_VIEW, id, view, username });
      dispatchAfter(dispatch, [push({ pathname: "/views" }), toggleModal()]);
      dispatchAfter(dispatch, [{ type: DELETE_VIEW_SUCCESS, id, view }], 200);
    } catch {
      dispatchAfter(dispatch, [{ type: DELETE_VIEW_ERROR, view }]);
    }
  };
};
