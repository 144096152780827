import { defineMessages } from "react-intl";

export default defineMessages({
  failedToLoad: {
    id: "components.ErrorMessage.failedToLoad",
    defaultMessage: "Failed to load"
  },
  noContent: {
    id: "components.ErrorMessage.noContent",
    defaultMessage: "No content available"
  },
  errorCode: {
    id: "components.ErrorMessage.errorCode",
    defaultMessage: "(Error Code: {code})"
  },
  lostConnection: {
    id: "components.ErrorMessage.lostConnection",
    defaultMessage: "Lost Connection to Camera"
  }
});
