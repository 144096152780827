import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "components.ViewsPage.title",
    defaultMessage: "Views"
  },
  newButton: {
    id: "components.ViewsPage.newButton",
    defaultMessage: "Add Cameras to New View"
  },
  addCamerasPanel: {
    id: "components.ViewsPage.addCamerasPanel",
    defaultMessage: "Add Cameras"
  },
  listPanel: {
    id: "components.ViewsPage.listPanel",
    defaultMessage: "List of Views"
  },
  helpTextAddCameras: {
    id: "components.ViewsPage.helpTextAddCameras",
    defaultMessage:
      "Use Add Cameras to add or search for cameras for your custom view."
  },
  helpTextListViews: {
    id: "components.ViewsPage.helpTextListViews",
    defaultMessage:
      'Use "Add Cameras" to add or search for cameras for your custom view. Use "List of Views" to access and manage your existing views. Add or remove cameras, delete, and save or rename your view with the controls that appear on the right.'
  },
  newViewButton: {
    id: "components.ViewsPage.newViewButton",
    defaultMessage: "Create New View"
  },
  viewLinkButton: {
    id: "components.ViewsPage.viewLinkButton",
    defaultMessage: "View"
  },
  lastModified: {
    id: "components.ViewsPage.lastModified",
    defaultMessage: "Last Modified: {time}"
  }
});
