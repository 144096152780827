import React from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import { LazyLoadContainer } from "../../../utils/outOfViewportCheck";

class CameraList extends LazyLoadContainer {
  render() {
    const {
      cameras = [],
      renderItem,
      parentId,
      className,
      bordered,
      grid,
      loading,
      size,
      split,
      locale
    } = this.props;

    return (
      <div id={parentId}>
        <List
          className={`camera-list ${className || ""}`}
          dataSource={cameras}
          renderItem={item => (
            <List.Item className="camera-list__item" key={item.id}>
              {renderItem(item)}
            </List.Item>
          )}
          {...{ bordered, grid, loading, renderItem, size, split, locale }}
        />
      </div>
    );
  }
}

CameraList.propTypes = {
  parentId: PropTypes.string.isRequired,
  cameras: PropTypes.arrayOf(PropTypes.object),

  // copied from List prop types
  bordered: PropTypes.bool,
  className: PropTypes.string,
  grid: PropTypes.object,
  loading: PropTypes.bool,
  renderItem: PropTypes.func,
  size: PropTypes.string,
  split: PropTypes.bool,
  locale: PropTypes.object
};

CameraList.defaultProps = {
  bordered: false
};

export default CameraList;
