import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Layout } from "antd";
import * as viewActions from "../../../actions/viewActions";
import { getCameraOrViewNotFound, getView } from "../../../selectors";
import withData from "../../DataWrapper";
import PageHeader from "../../Common/PageHeader";
import messages from "./messages";
import { LazyLoadContainer } from "../../../utils/outOfViewportCheck";
import CameraGrid from "../../Cameras/CameraGrid";
import CameraFeedThumbnail from "../CameraFeedThumbnail";
import CameraGridColumns from "../CameraGridColumns";
import Spinner from "../../Common/Spinner";
import { VIEW_ID_NEW } from "../../Views/constants";
import Button from "../../Common/Button";
import NotFoundPage from "../../NotFoundPage";

class CameraViewPage extends LazyLoadContainer {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      sorting: false
    };
  }

  componentDidMount() {
    super.componentDidMount();
    const {
      actions: { fetchView },
      viewId
    } = this.props;
    if (viewId) {
      fetchView(viewId);
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (prevProps.layoutColumns !== this.props.layoutColumns) {
      this.execFrameChecks({ target: this.scroll_parent });
    }
  }

  getAllCameraIds = (props = this.props) => {
    (props.cameras || []).filter(c => !!c).map(c => c.id);
  };

  toggleSorting = enabled => {
    const { camerasOrder, folderId } = this.props;
    this.setState(
      enabled
        ? {
            order:
              (camerasOrder && folderId && camerasOrder[folderId]) ||
              this.getAllCameraIds(),
            sorting: true
          }
        : {
            order: null,
            sorting: false
          }
    );
  };

  reorderLayout = order => this.setState({ order });

  saveOrder = () => {
    const { actions, folderId } = this.props;
    actions.reorderLayout(folderId, this.state.order);
    this.toggleSorting(false);
  };

  renderPageHeader = () => {
    const {
      name,
      layoutColumns = 4,
      actions: { setLayoutColumns }
    } = this.props;
    const { sorting } = this.state;

    const breadcrumbs = [{ path: "/cameras" }, { text: name }];

    return (
      <PageHeader
        title={<FormattedMessage {...messages.title} />}
        breadcrumbs={breadcrumbs}
      >
        <CameraGridColumns onSelect={setLayoutColumns} active={layoutColumns} />
        <div className="camera-view-page__header__sorting">
          {sorting && (
            <Button
              className="ant-btn-outline"
              onClick={() => this.saveOrder()}
            >
              <FormattedMessage {...messages.saveArrangement} />
            </Button>
          )}
          <Button
            className="ant-btn-outline"
            onClick={() => this.toggleSorting(!sorting)}
          >
            <FormattedMessage
              {...messages[sorting ? "cancel" : "rearrangeCameras"]}
            />
          </Button>
        </div>
      </PageHeader>
    );
  };

  render() {
    const { Content } = Layout;

    const {
      cameras,
      loading,
      layoutColumns,
      camerasOrder,
      folderId,
      id,
      viewId,
      notFound
    } = this.props;
    const { sorting } = this.state;

    if (notFound) {
      return <NotFoundPage />;
    }

    if (
      loading ||
      viewId !== id ||
      (viewId !== VIEW_ID_NEW && (!cameras.length || !viewId))
    ) {
      return (
        <Content className="container camera-view-page">
          <Spinner size="large" />
        </Content>
      );
    }
    let order = sorting ? this.state.order : null;

    // sort cameras based on order
    if (folderId in camerasOrder && !sorting) {
      order = camerasOrder[folderId];
    }
    if (order) {
      cameras.sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id);
      });
    }

    return (
      <Content className="container camera-view-page" data-sorting={sorting}>
        <Helmet title="Cameras" />
        {this.renderPageHeader()}
        <Layout>
          <Content>
            <CameraGrid
              cameras={cameras}
              layoutColumns={layoutColumns}
              renderItem={camera => (
                <CameraFeedThumbnail
                  size={layoutColumns}
                  camera={camera}
                  showSpinner={true}
                  link={!sorting}
                />
              )}
              reorderLayout={this.reorderLayout}
              enableSorting={sorting}
            />
          </Content>
        </Layout>
      </Content>
    );
  }
}

CameraViewPage.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  actions: PropTypes.object,
  layoutColumns: PropTypes.number,
  url: PropTypes.string,
  notFound: PropTypes.bool
};

CameraViewPage.defaultProps = {
  id: null,
  name: "",
  layoutColumns: 4,
  cameras: []
};

const mapStateToProps = state => {
  return { ...getView(state), notFound: getCameraOrViewNotFound(state) };
};

export default withData(CameraViewPage, mapStateToProps, { ...viewActions });
