import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as utilityActions from "../../actions/utilityActions";

export default function(
  ComposedComponent,
  componentMapStatetoProps,
  actionCreators
) {
  class AuthWrapper extends React.Component {
    constructor(props) {
      super(props);
      const { dispatch } = props;
      this.boundActionCreators = bindActionCreators(
        { ...actionCreators, ...utilityActions },
        dispatch
      );
    }

    componentDidMount() {
      const { auth } = this.props;
      const { connected, authenticated } = auth;
      if (connected && authenticated) {
        if (super.componentDidMount) {
          super.componentDidMount();
        }
      }
    }

    render() {
      const { auth, location } = this.props;
      if (location) {
        const { connected, authenticated } = auth;
        if (!connected || !authenticated) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  referrer: this.props.location
                }
              }}
            />
          );
        }
      }

      return (
        <ComposedComponent {...this.props} actions={this.boundActionCreators} />
      );
    }
  }

  AuthWrapper.propTypes = {
    dispatch: PropTypes.func
  };

  AuthWrapper.defaultProps = {
    dispatch: () => {}
  };

  const mapStateToProps = state => {
    const componentState = state ? componentMapStatetoProps(state) : {};
    return { auth: state.auth, ...componentState };
  };

  return connect(mapStateToProps)(AuthWrapper);
}
