import React from "react";
import PropTypes from "prop-types";
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from "react-sortable-hoc";
import { LazyLoadContainer } from "../../../utils/outOfViewportCheck";

const SortableItem = SortableElement(({ children }) => (
  <div className="camera-draggable-grid-item">{children}</div>
));

class SortableListBase extends LazyLoadContainer {
  render() {
    const { items, renderItem, parentId, getItemId } = this.props;
    return (
      <div className="camera-draggable-grid-items" id={parentId}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${getItemId ? getItemId(value) : index}`}
            index={index}
          >
            {renderItem(value)}
          </SortableItem>
        ))}
      </div>
    );
  }
}

const SortableList = SortableContainer(SortableListBase);

class CameraGrid extends React.Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { cameras = [], reorderLayout } = this.props;
    const camerasOrder = arrayMove(cameras.slice(), oldIndex, newIndex).map(
      c => c.id
    );

    reorderLayout(camerasOrder);
  };

  getItemId = camera => camera.id;

  render() {
    const {
      cameras = [],
      layoutColumns,
      className,
      renderItem,
      enableSorting
    } = this.props;

    return (
      <div
        className={`camera-grid camera-draggable-grid camera-draggable-grid--columns-${layoutColumns} ${className}`}
        data-enabled={enableSorting}
      >
        <SortableList
          items={cameras}
          axis={"xy"}
          onSortEnd={this.onSortEnd}
          helperClass="dragging"
          distance={5}
          parentId="camera-draggable-grid-items"
          renderItem={renderItem}
          shouldCancelStart={() => !enableSorting}
          getItemId={this.getItemId}
        />
      </div>
    );
  }
}

CameraGrid.propTypes = {
  cameras: PropTypes.array.isRequired,
  layoutColumns: PropTypes.number.isRequired,
  actions: PropTypes.object,
  className: PropTypes.string,
  renderItem: PropTypes.func.isRequired,
  reorderLayout: PropTypes.func.isRequired,
  enableSorting: PropTypes.bool
};

CameraGrid.defaultProps = {
  enableSorting: true
};

export default CameraGrid;
