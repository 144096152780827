import {
  FETCH_INVESTIGATION,
  FETCH_INVESTIGATION_RESPONSE,
  CREATE_NEW_INVESTIGATION,
  ADD_INVESTIGATION_CAMERA,
  REMOVE_INVESTIGATION_CAMERA,
  SORT_INVESTIGATION_CAMERAS,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  CREATE_INVESTIGATION_SUCCESS
} from "../constants/actionTypes";

import { INVESTIGATION_STATE_ACTIVE } from "../components/Investigations/constants";

const initialState = {
  id: null,
  loading: false,
  error: null,
  items: [],
  state: INVESTIGATION_STATE_ACTIVE,
  status: null,
  startTime: Date.now(),
  endTime: Date.now(),
  cameras: []
};

const investigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVESTIGATION:
      return { ...state, loading: true };

    case CREATE_NEW_INVESTIGATION:
      return { ...initialState };

    case FETCH_INVESTIGATION_RESPONSE: {
      if (action.investigation) {
        return {
          ...action.investigation,
          loading: false,
          error: null,
          modified: false
        };
      }

      return initialState;
    }

    case SORT_INVESTIGATION_CAMERAS:
      return {
        ...state,
        items: action.items,
        modified: true
      };

    case ADD_INVESTIGATION_CAMERA: {
      return {
        ...state,
        items: state.items.concat([action.camera.id]),
        //cameras: state.cameras.concat([action.camera]),
        modified: true
      };
    }

    case REMOVE_INVESTIGATION_CAMERA:
      return {
        ...state,
        items: [...state.items.filter(item => action.id !== item)],
        //cameras: [...state.cameras.filter(item => action.id !== item.id)],
        modified: true
      };

    case CREATE_INVESTIGATION_SUCCESS:
      return {
        ...action.investigation,
        loading: true
      };

    case LOGIN_SUCCESS:
      return { ...state, loading: true };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default investigationReducer;
