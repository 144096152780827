import { defineMessages } from "react-intl";

export default defineMessages({
  cancel: {
    id: "components.SaveModal.cancel",
    defaultMessage: "Cancel"
  },
  saveModalTitle: {
    id: "components.EntityToolbar.saveModalTitle",
    defaultMessage: "Save {type}"
  },
  renameModalTitle: {
    id: "components.EntityToolbar.renameModalTitle",
    defaultMessage: "Rename {type}"
  },
  saveAsModalTitle: {
    id: "components.EntityToolbar.saveAsModalTitle",
    defaultMessage: "Save {type} As"
  },
  saveModalInputLabel: {
    id: "components.EntityToolbar.saveModalInputLabel",
    defaultMessage: "{type} Name"
  },
  titleNew: {
    id: "components.EntityToolbar.titleNew",
    defaultMessage: "Untitled {type}"
  },
  saveOption: {
    id: "components.SaveModal.saveOption",
    defaultMessage: "Save"
  },
  saveAsOption: {
    id: "components.SaveModal.saveAsOption",
    defaultMessage: "Save As"
  },
  cancelBtn: {
    id: "components.SaveModal.cancelBtn",
    defaultMessage: "Cancel"
  },
  submitBtn: {
    id: "components.SaveModal.submitBtn",
    defaultMessage: "Submit"
  }
});
