import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";

const ConfirmModal = ({
  entity,
  title,
  children,
  visible,
  btnText,
  toggle,
  handleOk,
  btnType = "link",
  disabled = false,
  ...rest
}) => {
  const { loading } = entity;

  const modal = (
    <Modal
      title={title}
      visible={visible}
      onOk={() => handleOk(entity)}
      onCancel={toggle}
      centered={true}
      closable={false}
      className="confirm-modal"
      confirmLoading={loading}
      {...rest}
    >
      <div className="modal-text">{children}</div>
    </Modal>
  );

  return (
    <div className="modal-wrapper menu-link">
      <Button
        type={btnType}
        className="btn-trigger-modal"
        onClick={toggle}
        disabled={disabled || !entity}
      >
        {btnText}
      </Button>
      {entity ? modal : null}
    </div>
  );
};

ConfirmModal.propTypes = {
  entity: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  visible: PropTypes.bool,
  btnText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toggle: PropTypes.func,
  handleOk: PropTypes.func,
  btnType: PropTypes.string,
  disabled: PropTypes.bool
};

ConfirmModal.defaultProps = {
  entity: {
    loading: false
  }
};

export default ConfirmModal;
