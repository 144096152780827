import {
  TOGGLE_SEARCH,
  SEARCH,
  SEARCH_CAMERAS,
  GET_SEARCH_RESULTS
} from "../constants/actionTypes";
import { push } from "connected-react-router";
import { getQuery, setSearch } from "../utils/queryString";

export const toggleSearchForm = (show = false) => ({
  type: TOGGLE_SEARCH,
  show
});

export const search = (searchTerm = "", setLocation = true) => dispatch => {
  dispatch({
    type: SEARCH,
    searchTerm
  });
  if (setLocation) {
    dispatch(
      push({
        pathname: "/search",
        search: setSearch(searchTerm, getQuery())
      })
    );
  }
};

export const cameraSearch = (searchTerm = "") => ({
  type: SEARCH_CAMERAS,
  searchTerm
});

export const getResults = (results = []) => ({
  type: GET_SEARCH_RESULTS,
  results
});
