import { defineMessages } from "react-intl";

export default defineMessages({
  inputLabel: {
    id: "components.CameraSearch.inputLabel",
    defaultMessage: "Search..."
  },
  submit: {
    id: "components.CameraSearch.submit",
    defaultMessage: "Submit"
  },
  addCamera: {
    id: "components.CameraSearch.addCamera",
    defaultMessage: "Add"
  },
  removeCamera: {
    id: "components.CameraSearch.removeCamera",
    defaultMessage: "Remove"
  },
  selectedCamera: {
    id: "components.CameraSearch.selectedCamera",
    defaultMessage: "Added"
  },
  empty: {
    id: "components.CameraSearch.empty",
    defaultMessage: "No cameras are available."
  },
  emptySearch: {
    id: "components.CameraSearch.emptySearch",
    defaultMessage: "No matching cameras were found."
  }
});
