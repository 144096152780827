import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { List } from "antd";
import { NavLink, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import Icon from "../../Common/Icon";
import messages from "../messages";
import { VIEW_ID_NEW } from "../constants";

const ViewList = ({ loading = true, views = [], active }) => {
  const { Item } = List;
  const { Meta } = Item;

  return (
    <section className="list-panel">
      <div className="header list-panel-header">
        <Link
          to={`/views/${VIEW_ID_NEW}`}
          className="ant-btn ant-btn-primary ant-btn-sm ant-btn-has-icon"
        >
          <Icon type="plus-circle" />
          <FormattedMessage {...messages.newButton} />
        </Link>
      </div>
      <List
        loading={loading}
        dataSource={views}
        renderItem={item => (
          <Item
            key={item.id}
            className={classnames("ant-list-item--block", {
              "ant-list-item--inactive": active !== item.id,
              "ant-list-item--active": active === item.id
            })}
          >
            <NavLink to={`/views/${item.id}`} className="ant-list-item-link">
              <Meta
                title={item.name}
                description={
                  <div className="item-last-modified">
                    <FormattedMessage
                      values={{
                        time: moment(item.updatedAt).format(
                          "M/DD/YYYY, h:mm:ss A"
                        )
                      }}
                      {...messages.lastModified}
                    />
                  </div>
                }
              />
            </NavLink>
            <div className="ant-list-item-actions">
              <Link
                className="ant-btn ant-btn-sm ant-btn-light-ghost"
                to={`/views/${item.id}`}
              >
                <FormattedMessage {...messages.viewLinkButton} />
              </Link>
            </div>
          </Item>
        )}
      />
    </section>
  );
};

ViewList.propTypes = {
  active: PropTypes.string,
  loading: PropTypes.bool,
  views: PropTypes.array,
  sort: PropTypes.string,
  handleSortList: PropTypes.func,
  handleSetState: PropTypes.func,
  handlesetActiveView: PropTypes.func
};

export default ViewList;
