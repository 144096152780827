import {
  CONNECT,
  CONNECT_ERROR,
  CONNECT_SUCCESS,
  CONNECT_REQUEST_SUCCESS,
  CONNECT_CHANGE,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  TOGGLE_WEB_SOCKET,
  TOGGLE_MODAL,
  TOGGLE_DIRECT_STREAMING
} from "../constants/actionTypes";
import { toggleModal } from "./utilityActions";
import { push } from "connected-react-router";
import Queue from "../utils/thumbnailFetchQueue";
import { clearAllFrames } from "../utils/outOfViewportCheck";
import { XPMobileSDKSettings, ENABLE_CHAP_SUPPORT } from "../utils/api";

export const connect = () => {
  return (dispatch, getState, XPMobileSDK) => {
    const { connected } = getState();
    if (connected) {
      return Promise.resolve();
    }

    ENABLE_CHAP_SUPPORT && XPMobileSDK.library.CHAP.initialize();
    XPMobileSDK.library.Connection.initialize(XPMobileSDK.localStorage);
    XPMobileSDK.addObserver({
      connectionStateChanged: () => dispatch(connectionStateChanged()),
      connectionDidConnect: parameters => dispatch(connectSuccess(parameters)),
      connectionFailedToConnect: error => dispatch(connectError(error)),
      connectionFailedToConnectWithId: error => dispatch(connectError(error)),
      connectionLostConnection: () => dispatch(connectError()),
      connectionProcessingDisconnect: () => dispatch(logout()),
      connectionDidDisconnect: () => dispatch(logoutSuccess()),
      connectionRequestSucceeded: (request, response) =>
        dispatch(connectRequestSuccess(request, response)),
      connectionFailedToLogIn: error => dispatch(loginError(error)),
      connectionDidLogIn: () => dispatch(loginSuccess())
    });

    // update state for enabling websocket
    const socketEnabled =
      XPMobileSDK.library.Connection.webSocketBrowser !== null
        ? XPMobileSDK.library.Connection.webSocketBrowser
        : true;
    dispatch({
      type: TOGGLE_WEB_SOCKET,
      enabled: socketEnabled
    });

    // update state for enabling direct streaming
    const directEnabled =
      XPMobileSDK.library.Connection.directStreamingClient !== null
        ? XPMobileSDK.library.Connection.directStreamingClient
        : true;
    dispatch({
      type: TOGGLE_DIRECT_STREAMING,
      enabled: directEnabled
    });

    return Promise.resolve(dispatch({ type: CONNECT })).then(() => {
      return XPMobileSDK.connect(process.env.API_URL);
    });
  };
};

export const connectionStateChanged = () => ({
  type: CONNECT_CHANGE
});

export const connectError = (error = null) => dispatch => {
  localStorage.removeItem("connection");
  dispatch({
    type: CONNECT_ERROR,
    error
  });
  dispatch(toggleModal("connectionError"));
};

export const connectSuccess = response => (dispatch, _, XPMobileSDK) => {
  localStorage.setItem("connection", JSON.stringify(response));
  XPMobileSDKSettings.supportsCHAP = ENABLE_CHAP_SUPPORT;
  XPMobileSDK.library.Connection.CHAPSupported = ENABLE_CHAP_SUPPORT
    ? "Yes"
    : "No";

  return dispatch({
    type: CONNECT_SUCCESS,
    response
  });
};

export const connectRequestSuccess = (request, response) => {
  return {
    type: CONNECT_REQUEST_SUCCESS,
    request,
    response
  };
};

export const login = (username, password) => {
  return (dispatch, getState, XPMobileSDK) => {
    dispatch({ type: LOGIN, username });
    XPMobileSDK.login(username, password);
  };
};

export const loginError = error => (dispatch, _, XPMobileSDK) => {
  dispatch({
    type: LOGIN_ERROR,
    error
  });
  if (error.code === 15) {
    setTimeout(() => XPMobileSDK.connect(process.env.API_URL), 50);
  }
};

export const loginSuccess = () => {
  return (dispatch, getState, XPMobileSDK) => {
    XPMobileSDK.getOutputsAndEvents();
    return dispatch({
      type: LOGIN_SUCCESS,
      username: getState().auth.username
    });
  };
};

export const logout = () => {
  return (dispatch, getState, XPMobileSDK) => {
    const isLoggingOut = getState().auth.loggingOut;

    if (!isLoggingOut) {
      return Promise.resolve(dispatch({ type: LOGOUT })).then(() => {
        XPMobileSDK.disconnect();
      });
    }
  };
};

export const logoutSuccess = () => {
  return dispatch => {
    return Promise.resolve(dispatch({ type: TOGGLE_MODAL, modal: "" })).then(
      () => {
        clearAllFrames();
        dispatch(push({ pathname: "/" }));
        dispatch({ type: LOGOUT_SUCCESS });
        Queue.reset();
      }
    );
  };
};
