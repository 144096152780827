import { defineMessages } from "react-intl";

export default defineMessages({
  buttonText: {
    id: "components.SettingsPopover.buttonText",
    defaultMessage: "Settings"
  },
  websocketsHelpText: {
    id: "components.SettingsPopover.websocketsHelpText",
    defaultMessage:
      "WebSockets improve the performance of the system, and should in most cases be enabled.\n If you are having trouble loading video, try toggling this option."
  },
  websocketsSwitchLabel: {
    id: "components.SettingsPopover.websocketsSwitchLabel",
    defaultMessage: "WebSockets connection to the server:"
  },
  directStreamingHelpText: {
    id: "components.SettingsPopover.directStreamingHelpText",
    defaultMessage:
      "In most cases, to improve the video quality, direct streaming must be enabled."
  },
  directStreamingSwitchLabel: {
    id: "components.SettingsPopover.directStreamingSwitchLabel",
    defaultMessage: "Direct streaming:"
  }
});
