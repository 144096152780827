import {
  LOGOUT_SUCCESS,
  MOVE_CAMERA,
  SET_ACTIVE_CAMERA
} from "../constants/actionTypes";

const initialState = {
  id: null
};

const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_CAMERA:
      return { ...state, id: action.id };

    case MOVE_CAMERA:
      return { ...state, atPreset: false };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default playerReducer;
