export default class Template {
  constructor() {
    this.template = document.createElement("template");
    this.template.innerHTML = `
        <video
            style="height: 100%; width: 100%; object-fit: fill"
            class="player camera-canvas"
            part="video-player"
        ></video>`;
  }

  get() {
    return this.template;
  }
}
