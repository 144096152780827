import { Button, Modal } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";

class HelpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  openModal = () => this.setState({ open: true });
  closeModal = () => this.setState({ open: false });

  render() {
    const { open } = this.state;
    const { btnText } = this.props;
    return (
      <div className="modal-wrapper menu-link">
        <Button
          className="btn-trigger-modal"
          type="link"
          onClick={this.openModal}
        >
          {btnText}
        </Button>
        <Modal
          visible={open}
          closable={true}
          centered={true}
          className="help-modal"
          onCancel={this.closeModal}
          footer={<></>}
        >
          <iframe
            src={`${process.env.HELP_BASE_PATH || ""}/help/index.html`}
            frameBorder="0"
          />
        </Modal>
      </div>
    );
  }
}

HelpModal.propTypes = {
  btnText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default HelpModal;
