import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Layout, List } from "antd";
import withData from "../../DataWrapper";
import { getViews } from "../../../selectors";
import Spinner from "../../Common/Spinner";
import PageHeader from "../../Common/PageHeader";
import CameraCard from "../../Cameras/CameraCard";
import messages from "./messages";

class CamerasPage extends PureComponent {
  componentDidUpdate(prevProps) {
    const {
      actions: { getThumbnails }
    } = this.props;
    if (this.props.views.length && !prevProps.views.length) {
      this.props.views.map(view => getThumbnails(view.items.slice(0, 3)));
    }
  }

  render() {
    const { Content } = Layout;
    const { views, loading } = this.props;
    const content = (
      <List
        className="ant-list-cards"
        grid={{ gutter: 16, column: 3 }}
        dataSource={views}
        renderItem={item => {
          return (
            <List.Item key={item.id}>
              <CameraCard {...item} />
            </List.Item>
          );
        }}
      />
    );
    return (
      <Content className="container">
        <Helmet title="Cameras" />
        <PageHeader
          title={<FormattedMessage {...messages.title} />}
          breadcrumbs={[{ text: "Home" }]}
        />
        <Layout>
          <Content>
            {loading ? (
              <Spinner size="large" />
            ) : views.length ? (
              content
            ) : (
              "No cameras available"
            )}
          </Content>
        </Layout>
      </Content>
    );
  }
}

CamerasPage.propTypes = {
  actions: PropTypes.object,
  match: PropTypes.object,
  views: PropTypes.array,
  cameras: PropTypes.array,
  thumbnails: PropTypes.object,
  loading: PropTypes.bool
};

CamerasPage.defaultProps = {
  views: []
};

const mapStateToProps = state => {
  return { views: getViews(state), loading: state.views.loading };
};

export default withData(CamerasPage, mapStateToProps);
