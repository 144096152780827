import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { Button, Spin } from "antd";
import CameraHeader from "../CameraHeader";
import ErrorMessage from "../../Common/ErrorMessage";
import { LazyLoadFrame } from "../../../utils/outOfViewportCheck";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions/thumbnailActions";
import { THUMBNAIL_REQUEST_MAX_ATTEMPTS } from "../../../utils/thumbnailFetchQueue";
import messages from "../../Common/ErrorMessage/messages";
import { getEnvVar } from "../../../utils/env";
import Icon from "../../Common/Icon";
import { PLAYER_MODE_LIVE } from "../constants";

document.exitFullscreen =
  document.exitFullscreen ||
  document.mozCancelFullScreen ||
  document.webkitExitFullscreen ||
  document.msExitFullscreen;
const THUMBNAIL_MAX_AGE = getEnvVar(
  "THUMBNAIL_MAX_AGE",
  1000 * 60 * 5 // ms
);

class CameraThumbnail extends LazyLoadFrame {
  shouldComponentUpdate(nextProps, nextState) {
    const {
      // eslint-disable-next-line no-unused-vars
      actions: _,
      children: c_children,
      video: c_video,
      setAspectRatio: c_aspect,
      ...curProps
    } = this.props;
    const {
      // eslint-disable-next-line no-unused-vars
      actions: __,
      children: n_children,
      video: n_video,
      setAspectRatio: n_aspect,
      ...nxtProps
    } = nextProps;
    return (
      c_children !== n_children ||
      c_aspect !== n_aspect ||
      c_video !== n_video ||
      JSON.stringify(nextState) !== JSON.stringify(this.state) ||
      JSON.stringify(nxtProps) !== JSON.stringify(curProps)
    );
  }

  needToFetchContent = () =>
    this.props.auth.authenticated &&
    this.state.frameInitialized &&
    !this.state.outOfView &&
    this.props.thumbnail &&
    (this.props.thumbnail.image
      ? this.props.thumbnail.timestamp - Date.now() > THUMBNAIL_MAX_AGE
      : this.props.thumbnail.attempts == null ||
        this.props.thumbnail.attempts < THUMBNAIL_REQUEST_MAX_ATTEMPTS);

  shouldLazyFetch = () =>
    this.props.auth.authenticated &&
    this.state.frameInitialized &&
    this.state.outOfView &&
    this.props.thumbnail &&
    (this.props.thumbnail.image
      ? this.props.thumbnail.timestamp - Date.now() > THUMBNAIL_MAX_AGE
      : this.props.thumbnail.attempts == null ||
        this.props.thumbnail.attempts < THUMBNAIL_REQUEST_MAX_ATTEMPTS);

  isFetching = (props = this.props) =>
    props.thumbnail && (props.thumbnail.queued || props.thumbnail.pending);

  fetchContent = (lazy = false) => {
    this.props.camera &&
      this.props.camera.id &&
      this.props.thumbnail &&
      this.props.thumbnail.id &&
      this.props.actions.fetchThumbnail(this.props.thumbnail.id, lazy);
  };

  cancelFetchContent = (enqueueLazy = true) => {
    this.props.camera &&
      this.props.camera.id &&
      this.props.thumbnail &&
      this.props.thumbnail.id &&
      this.props.actions.cancelFetchThumbnail(
        this.props.thumbnail.id,
        enqueueLazy
      );
  };

  fetchFinished = prevProps =>
    this.fetching && this.isFetching(prevProps) && !this.isFetching();

  getFrameId = () =>
    this.props.thumbnail && !this.props.disableLazyLoad
      ? `thumbnail/${this.props.thumbnail.id}`
      : undefined;

  getThumbnail = () => {
    const {
      camera,
      showSpinner,
      width,
      height,
      thumbnail,
      has_content,
      mode,
      aspectRatio
    } = this.props;

    if (!camera) {
      return null;
    }

    const { name = "Camera Thumbnail" } = camera;
    const image = thumbnail.image || camera.image;
    const error = thumbnail.error || camera.error;
    const loading = !error && !image;
    const aspectRatio_ =
      aspectRatio ||
      (width > 0 && height > 0 ? Math.round((height / width) * 100, 2) : null);
    const style = aspectRatio_
      ? { height: 0, paddingBottom: `${aspectRatio}%` }
      : {};

    return (
      <>
        <div className="camera-image" style={style}>
          {image ? <img src={image} draggable={false} alt={name} /> : null}
          {showSpinner && loading ? <Spin /> : null}
        </div>
        {error &&
        !loading &&
        !image &&
        !(error.code === 32 && has_content && mode === PLAYER_MODE_LIVE) ? (
          <div className="camera-thumbnail-error">
            <ErrorMessage
              error={error}
              text={
                error.code === 32 ? messages.noContent : messages.failedToLoad
              }
            />
          </div>
        ) : null}
      </>
    );
  };

  render() {
    const {
      id,
      viewId,
      active,
      children,
      link,
      camera,
      theme,
      showHeader,
      mode,
      showFullscreen,
      isPlaying,
      video
    } = this.props;

    const thumbnail = this.getThumbnail();
    const classNames = classnames(
      "camera",
      `camera-theme-${theme}`,
      { "camera--active": active },
      { "camera--no-image": !thumbnail },
      { "camera-fullscreen": showFullscreen },
      { "camera-has-video": video },
      { "camera-is-playing": isPlaying }
    );

    const content = (
      <div className="camera-thumbnail">
        <div className="camera-thumbnail-content">
          {showHeader && camera && camera.name ? (
            <CameraHeader
              name={camera.name}
              mode={mode}
              showFullscreen={showFullscreen}
            />
          ) : null}
          {thumbnail}
          {video}
        </div>
        {children ? <div className="camera-content">{children}</div> : null}
        {showFullscreen && (
          <Button
            className="btn-exit-fullscreen"
            type="link"
            onClick={() => document.exitFullscreen()}
          >
            <Icon type="exit" title={messages.exit} />
          </Button>
        )}
      </div>
    );

    const cameraId = !id && camera ? camera.id : id;
    const cameraViewId = !viewId && camera ? camera.viewId : viewId;
    return (
      <article
        ref={this.frame}
        id={cameraId}
        className={classnames(classNames)}
      >
        {link && cameraId && cameraViewId ? (
          <NavLink
            className="camera-link"
            to={`/cameras/${cameraViewId}/${cameraId}`}
            isActive={() => active}
          >
            {content}
          </NavLink>
        ) : (
          content
        )}
      </article>
    );
  }
}

CameraThumbnail.propTypes = {
  id: PropTypes.string,
  viewId: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
  link: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showHeader: PropTypes.bool,
  theme: PropTypes.string,
  camera: PropTypes.object,
  showSpinner: PropTypes.bool,
  mode: PropTypes.number,
  showFullscreen: PropTypes.bool,
  video: PropTypes.node,
  isPlaying: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  actions: PropTypes.object.isRequired,
  auth: PropTypes.object,
  thumbnail: PropTypes.object,
  disableLazyLoad: PropTypes.bool,
  setAspectRatio: PropTypes.func,
  has_content: PropTypes.bool,
  aspectRatio: PropTypes.number
};

CameraThumbnail.defaultProps = {
  id: "",
  viewId: "",
  active: false,
  children: null,
  link: false,
  error: null,
  showHeader: true,
  theme: "light",
  camera: null,
  showSpinner: false,
  mode: null,
  showFullscreen: false,
  video: null,
  width: 0,
  height: 0,
  has_content: false
};

const mapStateToProps = (state, props) => ({
  auth: state.auth,
  thumbnail:
    props.camera && props.camera.id
      ? state.thumbnails.items.find(t => t.id === props.camera.id) || {
          id: props.camera.id,
          image: null
        }
      : undefined
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraThumbnail);
