import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "components.InvestigationsPage.title",
    defaultMessage: "Investigations"
  },
  titleNew: {
    id: "components.InvestigationsPage.titleNew",
    defaultMessage: "Untitled Investigation"
  },
  newButton: {
    id: "components.InvestigationsPage.newButton",
    defaultMessage: "Add Cameras to New Investigation"
  },
  addCamerasPanel: {
    id: "components.InvestigationsPage.addCamerasPanel",
    defaultMessage: "Add Cameras"
  },
  listPanel: {
    id: "components.InvestigationsPage.listPanel",
    defaultMessage: "List of Investigations"
  },
  helpTextAddCameras: {
    id: "components.InvestigationsPage.helpTextAddCameras",
    defaultMessage:
      'Use "Add Cameras" to add or search for cameras for your investigation.'
  },
  helpTextListViews: {
    id: "components.InvestigationsPage.helpTextListViews",
    defaultMessage:
      'Use "List of Investigations" to access and manage your existing investigations. Add or ' +
      "remove cameras, download, delete, and save or rename your investigation with the " +
      'controls that appear on the right. "Download" will allow you to select specific cameras to ' +
      "export."
  },
  removeCamerasButton: {
    id: "components.InvestigationsPage.removeCamerasButton",
    defaultMessage: "Remove Cameras"
  },
  downloadButton: {
    id: "components.InvestigationsPage.downloadButton",
    defaultMessage: "Download"
  },
  deleteButton: {
    id: "components.InvestigationsPage.deleteButton",
    defaultMessage: "Delete"
  },
  cancel: {
    id: "components.InvestigationsPage.cancel",
    defaultMessage: "Cancel"
  },
  saveOption: {
    id: "components.InvestigationsPage.saveOption",
    defaultMessage: "Save"
  },
  saveAsOption: {
    id: "components.InvestigationsPage.saveAsOption",
    defaultMessage: "Save As"
  },
  deleteModalTitle: {
    id: "components.InvestigationsPage.deleteModalTitle",
    defaultMessage: "Delete Investigation"
  },
  deleteModalText: {
    id: "components.InvestigationsPage.deleteModalText",
    defaultMessage: "Are you sure you want to delete this investigation?"
  },
  saveModalTitle: {
    id: "components.InvestigationsPage.saveModalTitle",
    defaultMessage: "Save Investigation"
  },
  saveAsModalTitle: {
    id: "components.InvestigationsPage.saveAsModalTitle",
    defaultMessage: "Save Investigation As"
  },
  saveModalInputLabel: {
    id: "components.InvestigationsPage.saveModalInputLabel",
    defaultMessage: "Investigation Name"
  }
});
