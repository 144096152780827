import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "common.title",
    defaultMessage: "Video Watch Web Client"
  },
  companyName: {
    id: "common.companyName",
    defaultMessage: "Datawatch Systems"
  },
  companyTagline: {
    id: "common.companyTagline",
    defaultMessage: "Leading the Way in Access Control"
  },
  appLogoAltText: {
    id: "common.appLogoAltText",
    defaultMessage: "Videowatch"
  },
  videowatchLogoAltText: {
    id: "common.videowatchLogoAltText",
    defaultMessage: "Videowatch Hosted"
  },
  copyrightNotice: {
    id: "common.copyrightNotice",
    defaultMessage: "© {year} Datawatch Systems, Inc.",
    values: {
      year: new Date().getFullYear()
    }
  },
  camerasMenuLink: {
    id: "components.MainMenu.CamerasPage",
    defaultMessage: "Cameras"
  },
  investigationsMenuLink: {
    id: "components.MainMenu.InvestigationsPage",
    defaultMessage: "Investigations"
  },
  viewsMenuLink: {
    id: "components.MainMenu.ViewsPage",
    defaultMessage: "Views"
  },
  learnMenuLink: {
    id: "components.MainMenu.Learn",
    defaultMessage: "Learn"
  },
  searchMenuLink: {
    id: "components.MainMenu.Search",
    defaultMessage: "Search"
  },
  help: {
    id: "components.MainMenu.Help",
    defaultMessage: "Help"
  },
  logoutModalTriggerBtn: {
    id: "components.ConfirmModal.logoutModalTriggerBtn",
    defaultMessage: "Log out{username}"
  },
  logoutModalOkayBtn: {
    id: "components.ConfirmModal.logoutModalOkayBtn",
    defaultMessage: "Log out"
  },
  logoutModalTitle: {
    id: "components.ConfirmModal.modalTitle",
    defaultMessage: "Log out"
  },
  logoutModalText: {
    id: "components.ConfirmModal.logoutModalText",
    defaultMessage: "Are you sure you want to log out?"
  }
});
