import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Layout, Button } from "antd";
import XPMobileSDK from "../../../utils/api";
import PageHeader from "../../Common/PageHeader";
import EntityToolbar from "../../EntityAdmin/EntityToolbar";
import CameraThumbnail from "../../Cameras/CameraThumbnail";
import Icon from "../../Common/Icon";
import messages from "./messages";
import MultiCameraPlayer from "../../Cameras/MultiCameraPlayer";
import { bindActionCreators } from "redux";
import * as thumbnailActions from "../../../actions/thumbnailActions";
import * as exportActions from "../../../actions/exportActions";
import {
  fetchInvestigation,
  sortCameras
} from "../../../actions/investigationActions";
import { connect } from "react-redux";
import InvestigationExport from "../InvestigationExport";
import { getExportsByCamera } from "../../../selectors";
import {
  INVESTIGATION_STATUS_SAVING,
  INVESTIGATION_MODE_DOWNLOAD,
  INVESTIGATION_MODE_EDIT,
  INVESTIGATION_STATE_OLD,
  INVESTIGATION_STATUS_SAVED
} from "../constants";
import CameraGrid from "../../Cameras/CameraGrid";
import Spinner from "../../Common/Spinner";
import { replaceLocation } from "../../../actions/utilityActions";

const REFRESH_DATA_INTERVAL = 3000; //ms

class InvestigationDetailPage extends PureComponent {
  constructor(props) {
    super(props);

    const now = Date.now();

    this.state =
      props.investigation && props.investigation.id
        ? {
            startTime: props.investigation.startTime || now - 60 * 60 * 1000,
            endTime: props.investigation.endTime || now
          }
        : {
            startTime: now - 60 * 60 * 1000,
            endTime: now
          };
    this.dbStart = {};
  }
  refreshDataTimer = null;
  dbStart = {};

  componentDidUpdate(prev) {
    const { investigation, showExports } = this.props;
    if (
      prev.investigation.startTime !== investigation.startTime ||
      prev.investigation.endTime !== investigation.endTime
    ) {
      this.loadInvestigation();
    }

    if (!prev.showExports && this.props.showExports) {
      this.props.actions.fetchAllExports(true);
    }

    if (investigation && investigation.status === INVESTIGATION_STATUS_SAVING) {
      if (showExports) {
        this.props.actions.replaceLocation(
          `/investigations/${investigation.id}`
        );
      }
      if (!this.refreshDataTimer) {
        this.refreshDataTimer = this.refreshData();
      }
    }

    if (
      investigation &&
      prev.investigation &&
      prev.investigation.status === INVESTIGATION_STATUS_SAVING &&
      investigation.status === INVESTIGATION_STATUS_SAVED &&
      this.refreshDataTimer
    ) {
      clearTimeout(this.refreshDataTimer);
      this.refreshDataTimer = null;
    }
    this.getDbStart();
  }

  componentDidMount() {
    this.mounted = true;
    const { investigation, showExports, actions } = this.props;
    if (investigation && investigation.status === INVESTIGATION_STATUS_SAVING) {
      this.refreshDataTimer = this.refreshData();
      if (showExports) {
        actions.replaceLocation(`/investigations/${investigation.id}`);
      }
    }
    this.getDbStart();
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.refreshDataTimer) {
      clearTimeout(this.refreshDataTimer);
    }
  }

  getDbStart = () => {
    const { investigation } = this.props;
    if (investigation && investigation.cameras) {
      investigation.cameras.forEach(cam => {
        if (!cam) {
          return;
        }
        if (!Object.keys(this.dbStart).includes(cam.id)) {
          this.dbStart[cam.id] = Infinity;
          XPMobileSDK.getDBStartTime(
            cam.id,
            time => {
              this.dbStart[cam.id] = time;
              this.setState({
                db_start: Math.min(...Object.values(this.dbStart))
              });
            },
            () => {
              delete this.dbStart[cam.id];
            }
          );
        }
      });
    }
  };

  refreshData = () =>
    setTimeout(() => {
      this.refreshDataTimer = null;
      this.mounted &&
        this.props.investigation &&
        this.props.investigation.status === INVESTIGATION_STATUS_SAVING &&
        this.props.actions.fetchInvestigation(
          this.props.investigation.id,
          true
        );
    }, REFRESH_DATA_INTERVAL);

  loadInvestigation = () => {
    const { investigation } = this.props;
    const now = Date.now();
    this.setState(
      investigation && investigation.id
        ? {
            startTime: investigation.startTime || now - 60 * 60 * 1000,
            endTime: investigation.endTime || now
          }
        : {
            startTime: now - 60 * 60 * 1000,
            endTime: now
          }
    );
  };

  handleSetTimeBounds = (key, timestamp) =>
    key && timestamp && this.setState({ [key]: timestamp });

  handleSaveInvestigation = props =>
    this.props.handleSave({
      ...this.props.investigation,
      ...props,
      startTime: this.state.startTime,
      endTime: this.state.endTime
    });

  handleDeleteInvestigation = investigation =>
    this.props.handleDelete(investigation);

  getRemoveButton = camera => (
    <CameraThumbnail key={camera.id} id={camera.id} camera={camera}>
      <Button
        type="link"
        className="btn-remove-camera"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          this.props.handleRemoveCamera(camera.id);
        }}
      >
        <div className="btn-text">
          <Icon type="close" />
          <FormattedMessage {...messages.removeCamera} />
        </div>
      </Button>
    </CameraThumbnail>
  );

  getCameras = () => {
    const cameras = this.props.investigation.cameras.slice();
    const playerCameras = this.props.removeCameras
      ? cameras.map(camera => this.getRemoveButton(camera))
      : cameras;
    playerCameras.length = 4;
    return playerCameras.fill(
      <div className="placeholder" />,
      this.props.investigation.cameras.length,
      4
    );
  };

  renderPlayer = () => {
    const {
      cameras,
      investigationId,
      actions,
      camerasLoading,
      directStreamingEnabled,
      websocketsEnabled
    } = this.props;
    const { startTime, endTime, db_start } = this.state;
    return cameras.filter(c => !!c).length ? (
      <MultiCameraPlayer
        id={investigationId}
        cameras={cameras}
        cameraItems={this.getCameras()}
        startTime={startTime}
        endTime={endTime}
        disableCapabilities={["ptz", "live"]}
        setTimeBounds={this.handleSetTimeBounds}
        fetchThumbnailResponse={actions.fetchThumbnailResponse}
        dbStart={db_start}
        directStreamingEnabled={directStreamingEnabled}
        websocketsEnabled={websocketsEnabled}
      />
    ) : camerasLoading ? (
      <Layout.Content className="container">
        <Spinner size="large" />
      </Layout.Content>
    ) : null;
  };

  renderExports = () => {
    const { cameras, showExports, exports, investigationId } = this.props;
    const { startTime, endTime } = this.state;
    return showExports ? (
      <div
        className={`camera-player camera-player-${
          cameras.length > 1 ? "multiple" : "single"
        }`}
        data-exports
      >
        <div className="camera-player-content">
          <div className="camera-player-frames">
            {cameras.map((camera, i) => {
              if (typeof camera === "object" && camera.id) {
                return (
                  <InvestigationExport
                    key={i}
                    camera={camera}
                    investigationId={investigationId}
                    startTime={startTime}
                    endTime={endTime}
                    exports={exports[camera.id]}
                  />
                );
              }
              return (
                <div key={i} className="camera-frame camera-frame-empty">
                  {camera}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    ) : null;
  };

  renderThumbnailContent = item => (
    <Button
      type="link"
      className="btn-remove-camera btn-remove-camera--view"
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        this.props.handleRemoveCamera(item.id);
      }}
    >
      <div className="btn-text">
        <Icon type="close" />
        <FormattedMessage {...messages.removeCamera} />
      </div>
    </Button>
  );

  renderSorting = () => {
    const {
      cameras,
      removeCameras,
      investigationId,
      investigation,
      loading
    } = this.props;
    return !removeCameras ? null : (
      <CameraGrid
        cameras={cameras || []}
        loading={
          loading || !investigation || !investigationId !== investigation.id
        }
        layoutColumns={2}
        renderItem={camera => (
          <CameraThumbnail
            camera={camera}
            active={this.props.cameraId === camera.id}
            link={false}
            theme="dark"
            showSpinner={true}
          >
            {this.renderThumbnailContent(camera)}
          </CameraThumbnail>
        )}
        reorderLayout={this.props.actions.sortCameras}
        enableSorting={removeCameras}
      />
    );
  };

  render() {
    const {
      investigationId,
      investigation,
      pathname,
      showModal,
      toggleModal,
      saving
    } = this.props;
    const path = `/investigations/${investigationId}`;
    const { Content } = Layout;
    const {
      name,
      state,
      modified,
      startTime,
      endTime,
      cameras,
      loading
    } = investigation;
    const { startTime: start, endTime: end } = this.state;

    if (loading) {
      return (
        <Content className="container full-height">
          <Spinner size="large" />
        </Content>
      );
    }

    return (
      <Content
        className={`full-height`}
        data-modified={modified || start !== startTime || end !== endTime}
      >
        <PageHeader
          title={
            name ? (
              state === INVESTIGATION_STATE_OLD ? (
                `[OLD EXPORT] ${name}`
              ) : (
                name
              )
            ) : (
              <FormattedMessage {...messages.titleNew} />
            )
          }
          showTitle
        >
          <EntityToolbar
            entity={investigation}
            pathBase={path}
            currentPath={pathname}
            editPath={`${path}/${INVESTIGATION_MODE_EDIT}`}
            downloadPath={`${path}/${INVESTIGATION_MODE_DOWNLOAD}`}
            showModal={showModal}
            toggleModal={toggleModal}
            handleDelete={this.handleDeleteInvestigation}
            handleSave={this.handleSaveInvestigation}
            saving={saving}
            canSave={cameras && cameras.length > 0}
          />
        </PageHeader>
        {this.renderSorting()}
        {this.renderExports()}
        {this.renderPlayer()}
      </Content>
    );
  }
}

InvestigationDetailPage.propTypes = {
  investigationId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  new: PropTypes.bool,
  cameras: PropTypes.array,
  exports: PropTypes.object,
  showExports: PropTypes.bool,
  sortCameras: PropTypes.func,
  camerasLoading: PropTypes.bool,
  directStreamingEnabled: PropTypes.bool,
  websocketsEnabled: PropTypes.bool
};

InvestigationDetailPage.defaultProps = {
  investigationId: null,
  loading: false,
  error: false,
  investigation: {
    name: "",
    type: "Investigation",
    items: [],
    state: null,
    startTime: Date.now(),
    endTime: Date.now(),
    cameras: []
  }
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      ...bindActionCreators(thumbnailActions, dispatch),
      ...bindActionCreators(exportActions, dispatch),
      ...bindActionCreators({ sortCameras }, dispatch),
      ...bindActionCreators({ fetchInvestigation }, dispatch),
      ...bindActionCreators({ replaceLocation }, dispatch)
    }
  };
};

export default connect(
  state => ({
    exports: getExportsByCamera(state),
    camerasLoading: state.views.loading,
    directStreamingEnabled: state.utility.enableDirectStreaming,
    websocketsEnabled: state.utility.enableWebsocket
  }),
  mapDispatchToProps
)(InvestigationDetailPage);
