import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { Layout, List, Card } from "antd";
import LoginForm from "../Common/LoginForm";
import Logo from "../Common/Logo";
import * as actions from "../../actions/authActions";
import * as utilActions from "../../actions/utilityActions";
import messages from "./messages";
import globalMessages from "../../constants/messages";
import Spinner from "../Common/Spinner";

const TILE_PADDING = 16; //px

class HomePage extends Component {
  componentDidMount() {
    this.props.actions.connect();
    this.props.actions.getPromotions();
  }

  componentDidUpdate(prevProps) {
    const { content } = this.props;
    if (
      prevProps.content &&
      prevProps.content.loading &&
      content &&
      !content.loading &&
      content.promotions &&
      content.promotions.length > 0
    ) {
      content.promotions.forEach(p => {
        if (typeof p === "string") {
          this.props.actions.getPromotionsItem(p);
        }
      });
    }
  }

  renderItem = (item, allStyle) => {
    if (typeof item === "string") {
      const {
        content: { promotionsItems }
      } = this.props;
      const promotionContent =
        !promotionsItems[item] || promotionsItems[item].loading ? (
          <div className="promotion__item promotion__item--loading">
            <Spinner size="large" spinning={true} />
          </div>
        ) : promotionsItems[item].error ? (
          <div className="promotion__item promotion__item--error">
            Something went wrong while loading this content.
          </div>
        ) : (
          <div
            className="promotion__item"
            dangerouslySetInnerHTML={{ __html: promotionsItems[item].content }}
          />
        );
      const centered =
        !promotionsItems[item] ||
        promotionsItems[item].loading ||
        promotionsItems[item].error;

      return (
        <List.Item>
          <Card
            className={`ant-card-dark promotion promotion-custom${
              centered ? " promotion--centered" : ""
            }`}
            bordered={false}
            style={allStyle}
          >
            <Card.Meta description={promotionContent} />
          </Card>
        </List.Item>
      );
    }

    const { title = "", description = "", link = "", background = "" } = item;
    const style = {
      ...allStyle
    };
    if (background) {
      style.background = `url(images/${background})`;
      style.backgroundSize = "cover";
    }
    const cardContent = (
      <Card className="ant-card-dark promotion" bordered={false} style={style}>
        <Card.Meta
          title={<h2 className="promotion-title">{title}</h2>}
          description={<p>{description}</p>}
        />
        {link ? (
          <div className="read-more">
            <FormattedMessage {...messages.readMoreLink} />
          </div>
        ) : (
          ""
        )}
      </Card>
    );
    return (
      <List.Item>
        {link ? (
          <a
            className="ant-card-link"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {cardContent}
          </a>
        ) : (
          cardContent
        )}
      </List.Item>
    );
  };

  render() {
    const { Content, Sider } = Layout;
    const {
      authenticated,
      connected,
      loading,
      error,
      loginError,
      location: { state = null },
      content = { loading: true }
    } = this.props;

    const { promotions, promotionsLayout } = content;
    const allStyle = {};
    const containerStyle = {};
    if (promotionsLayout) {
      for (const dim of ["width", "height"]) {
        const p = promotionsLayout[dim];
        if (!p) {
          continue;
        }
        if (typeof p === "number") {
          allStyle[dim] = `${p + TILE_PADDING}px`;
          dim === "width" && (containerStyle[dim] = `${2 * p}px`);
        } else if (!isNaN(parseInt(p))) {
          allStyle[dim] = `${parseInt(p)}px`;
          dim === "width" && (containerStyle[dim] = `${2 * parseInt(p)}px`);
        } else if (typeof p === "string") {
          const num = parseInt(p.replace("px", ""));
          if (!isNaN(num)) {
            allStyle[dim] = p;
            dim === "width" && (containerStyle[dim] = `${2 * num}px`);
          }
        }
      }
    }

    if (authenticated) {
      return (
        <Redirect to={state && state.referrer ? state.referrer : "/cameras"} />
      );
    }

    return (
      <Layout>
        <Content>
          <main className="main-content">
            <Logo type="company" title={globalMessages.companyName} />
            {promotions && (
              <div style={containerStyle}>
                <List
                  className={`promotions${
                    allStyle.width ? " promotions--set-width" : ""
                  }`}
                  dataSource={promotions}
                  grid={{ column: 2 }}
                  loading={content.loading}
                  renderItem={item => this.renderItem(item, allStyle)}
                />
              </div>
            )}
            {content.error && (
              <div className="login-page__promotions__error">
                Oops! Something went wrong!
              </div>
            )}
            {content.loading && (
              <Spinner size="large" spinning={content.loading} />
            )}
          </main>
        </Content>
        <Sider className="login-sider" width="auto">
          <div className="sider-main">
            <div className="branding">
              <Logo title={globalMessages.videowatchLogoAltText} size="lg" />
              <h2 className="title">
                <Logo
                  type="app-name"
                  title={globalMessages.videowatchLogoAltText}
                />
              </h2>
            </div>
            <LoginForm
              authenticate={this.props.actions.login}
              authenticated={authenticated}
              connected={connected}
              loading={loading}
              error={error}
              loginError={loginError}
            />
          </div>
          <footer className="sider-footer">
            <p className="copyright">
              <FormattedMessage {...globalMessages.copyrightNotice} />
            </p>
          </footer>
        </Sider>
      </Layout>
    );
  }
}

HomePage.propTypes = {
  actions: PropTypes.object,
  match: PropTypes.object,
  list: PropTypes.array,
  content: PropTypes.object
};

const mapStateToProps = ({ auth, utility }) => {
  return { ...auth, content: utility.content };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      ...bindActionCreators(utilActions, dispatch)
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
