import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Spin } from "antd";

const Spinner = ({ theme, className, ...props }) => (
  <Spin
    className={classnames(className, `ant-spin-theme-${theme}`)}
    {...props}
  />
);

Spinner.propTypes = {
  theme: PropTypes.string,
  className: PropTypes.string
};

Spinner.defaultProps = {
  theme: "dark",
  className: ""
};

export default Spinner;
