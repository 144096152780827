import {
  FETCH_ALL_VIEWS,
  FETCH_ALL_VIEWS_SUCCESS,
  FETCH_ALL_VIEWS_ERROR,
  LOGOUT_SUCCESS
} from "../constants/actionTypes";

const initialState = {
  loading: true,
  error: null,
  views: [],
  cameras: []
};

const viewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_VIEWS:
      return { ...state, loading: true, error: null };

    case FETCH_ALL_VIEWS_SUCCESS:
      return {
        ...state,
        views: action.views,
        cameras: action.cameras,
        loading: false,
        error: null
      };

    case FETCH_ALL_VIEWS_ERROR:
      return { ...state, loading: false, error: action.error };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default viewsReducer;
